import { doc, onSnapshot } from 'firebase/firestore';
import { firestoreDB, functions } from '../ultils/firebase';
import { httpsCallable } from 'firebase/functions';
import { IResponse } from '@/model/Responses';
import { IBase, IOrder } from '@/model/Models';

const useOrderService = () => {
  const getOrdersCompact = async (
    userId: string,
  ): Promise<IResponse<IBase[]>> => {
    const method = httpsCallable<{ userId: string }, IResponse<IBase[]>>(
      functions,
      'Order_getOrdersCompact',
    );
    const res = await method({ userId: userId });
    return res.data;
  };

  const updateOrderTransaction = async (
    orderId: string,
    transactionId: string,
  ): Promise<IResponse<null>> => {
    const method = httpsCallable<
      { orderId: string; transactionId: string },
      IResponse<null>
    >(functions, 'Order_updateOrderTransaction');
    const res = await method({
      orderId: orderId,
      transactionId: transactionId,
    });
    return res.data;
  };

  const excuteOrder = async (
    orderId: string
  ): Promise<IResponse<string>> => {
    const method = httpsCallable<
      { orderId: string},
      IResponse<IOrder>
    >(functions, 'Order_excuteOrder');
    const res = await method({ orderId: orderId});
    if(res.data.status) {
      if(!res.data.data.excuteType){
        const jobDocRef = doc(firestoreDB, 'jobs', res.data.data.jobId);
        const jobCompletionPromise = new Promise<IResponse<string>>(
          (resolve, reject) => {
            const unsubscribe = onSnapshot(jobDocRef, (docSnapshot) => {
              const jobData = docSnapshot.data();
              if (jobData && jobData.status === 'completed') {
                unsubscribe();
                resolve({ status: true, data: orderId });
              } else if (jobData && jobData.status === 'failed') {
                unsubscribe();
                reject({ status: false, error: '' });
              }
            });
          },
        );
        return jobCompletionPromise;
      } else {
        return {
          status: true,
          data: orderId
        };
      }
    } else {
      return res.data
    }
  };

  return {
    getOrdersCompact,
    updateOrderTransaction,
    excuteOrder
  };
};

export default useOrderService;
