import Button from '@/atom/controls/Button';
import Error from '@/atom/controls/Error';
import { useState } from 'react';
import { IResponse } from '@/model/Responses';
import { useAuthContext } from '../authcheck/AuthCheck';

const SubmitTicketResponse = ({
  ticketSquenceId,
  addResponse,
}: {
  ticketSquenceId: number;
  addResponse: (
    response: string,
    isClose: boolean,
  ) => Promise<IResponse<boolean>>;
}) => {
  const { user } = useAuthContext();
  const [repsonse, setResponse] = useState<string>();
  const [error, setError] = useState<string | null>();

  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    setResponse(value);
  };

  const submit = async (isClose: boolean) => {
    if (!repsonse || repsonse === '') {
      setError('Please enter reponse');
      return;
    } else {
      setError(null);
      const res = await addResponse(repsonse, isClose);
      if (res.status) {
        setResponse('');
      }
    }
  };

  return (
    <div className='my-4'>
      <p>
        <span className='font-bold'>Response:</span>
      </p>
      <textarea
        className='mt-4 p-2 border w-full h-40'
        value={repsonse}
        onChange={handleInputChange}
      ></textarea>
      {error && <Error message={'Please enter a comment'} />}
      <div className='flex justify-end mt-4 gap-4'>
        {(user?.role === 'Admin' || user?.role === 'SuperAdmin') && (
          <Button className='btn submit' onClick={() => submit(true)}>
            Send and Close
          </Button>
        )}
        <Button className='btn submit' onClick={() => submit(false)}>
          Send
        </Button>
      </div>
    </div>
  );
};

export default SubmitTicketResponse;
