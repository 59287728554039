import React, { FC } from 'react';
import { Routes, Route } from 'react-router-dom';
import Upload from '../upload/Upload';
import Header from '../../component/header/Header';
import Construction from '../construction/Construction';
import Account from '../account/Account';
import Organization from '../organization/Organization';
import OrderDetails from '../order/OrderDetails';
import OrderList from '../order/OrderList';
import CloseAccount from '../account/CloseAccount';
import GuardAdmin from '../admin/Guard';
import ListAccount from '../admin/ListAccount';
import ListOrder from '../admin/ListOrder';
import ListGrant from '../admin/ListGrant';
import SubmitTicket from '../ticket/SubmitTicket';
import TicketDetail from '../ticket/TicketDetail';
import TicketHistory from '../ticket/TicketHistory';
import Faq from '../faq/Faq';
import Checkout from '../checkout/Checkout';
import PaymentMethod from '../payment/PaymentMethod';
import Price from '../price/Price';
import Help from '../help/Help';
import How from '../help/How';

const Content: React.FC = () => {
  return (
    <div className='flex flex-col flex-auto min-h-screen min-w-0 relative w-full bg-white dark:bg-gray-800 border-l border-gray-200 dark:border-gray-700'>
      <Header />
      <Routes>
        <Route path='/' element={<Upload />} />
        <Route path='/upload' element={<Upload />} />
        <Route path='/history' element={<OrderList />} />
        <Route path='/chat' element={<Construction />} />
        <Route path='/organization' element={<Organization />} />
        <Route path='/account' element={<Account />} />
        <Route path='/orders' element={<OrderList />} />
        <Route path='/orderdetails/:id' element={<OrderDetails />} />
        <Route path='/closeaccount' element={<CloseAccount />} />
        <Route path='/submitticket/:orderSquenceId?' element={<SubmitTicket />} />
        <Route path='/ticketdetail/:ticketId' element={<TicketDetail />} />
        <Route path='/tickethistory' element={<TicketHistory />} />
        <Route path='/faq' element={<Faq />} />
        <Route path='/how' element={<How />} />
        <Route path='/help' element={<Help />} />
        <Route path='/checkout/:id' element={<Checkout />} />
        <Route path='/payment/:id?' element={<PaymentMethod />} />
        <Route path='/admin/*' element={<GuardAdmin />}>
          <Route path='listaccount' element={<ListAccount />} /> 
          <Route path='listorders' element={<ListOrder />} />
          <Route path='listgrants' element={<ListGrant />} />
          <Route path='price' element={<Price />} />
        </Route>
      </Routes>
    </div>
  );
};

export default Content;
