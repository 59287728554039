import ss from '../upload/Upload.module.scss';
const OrderError = ({ error }) => {
  return (
    <p className={ss['error-msg']}>
      Try later, system is not available right now. <br />
      Please check log
    </p>
  );
};
export default OrderError;
