// LoadingFrame.tsx
import React from 'react';
import { useSelector } from 'react-redux';
import cx from 'classnames';
import ss from './Loading.module.scss';
import { ReactComponent as IconLoading } from '@/assets/icon-loading.svg';
import { AppState } from '@/redux/Store';

export const LoadingFrame: React.FC = () => {
  const loadingState = useSelector((state: AppState) => state.loading);
  const { isLoading, loadingText } = loadingState;

  return (
    <div
      className={cx(ss['loading-frame'])}
      style={{ display: isLoading ? 'flex' : 'none' }}
    >
      <div className='loading-spinner' style={{ color: '#fff' }}>
        <p>{loadingText}...</p>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '20px',
          }}
        >
          <IconLoading />
        </div>
      </div>
    </div>
  );
};
