import React, { useEffect, useState, useContext, useRef } from 'react';
import { AuthContext } from '@/component/authcheck/AuthCheck';
import useStoreService from '@/hook/useStoreService';
import useLoading from '@/hook/useLoading';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { ChartBarIcon, PlusIcon } from '@heroicons/react/24/solid';
import Pagination from './Pagination';
import { IOrder } from '@/model/Models';
const OrderList = () => {
  const { user } = useContext(AuthContext);
  const { getOrders, getOrdersTotalRecord } = useStoreService();
  const { startLoading, stopLoading } = useLoading();
  const [currentPage, setCurrentPage] = useState<number>(-1);
  const [pageSize, setPageSize] = useState<number>(20);
  const [totalRecord, setTotalRecord] = useState<number>();
  const [list, setList] = useState<any[]>([]);
  const preventReload = useRef(false);

  const fetchOrders = async () => {
    console.log('fetch getListImported');
    if (!user) return;
    startLoading();
    const dt = await getOrders({
      userId: user.id,
      currentPage: currentPage,
      pageSize: pageSize,
    });
    console.log('dt', dt);
    stopLoading();
    setTotalRecord(dt.totalRecord);
    setList(dt.result);
  };

  useEffect(() => {
    if (!preventReload.current) {
      preventReload.current = true;
      setCurrentPage(1);
    }
  }, []);

  useEffect(() => {
    if (currentPage === -1) return;
    fetchOrders();
  }, [currentPage]);

  const handleChangePage = (type: string) => {
    const p = type === 'prev' ? currentPage - 1 : currentPage + 1;
    setCurrentPage(p);
  };

  return (
    <div className='container'>
      <p className='text-lg flex font-bold'>All executed digital orders</p>
      <table className='min-w-full md:min-w-[700px] mt-2'>
        <thead>
          <tr className='border-b-2'>
            <th className='text-left pr-4 py-3'>Account Id</th>
            <th className='text-left pr-4 py-3'>Order Id</th>
            <th className='text-left pr-4 py-3'>File names</th>
            <th className='text-center px-4 py-3'>Status</th>
            <th className='text-center px-4 py-3'>Created date</th>
            <th className='text-center px-4 py-3'>Created by</th>
            <th className='text-center px-4 py-3'>Execution type</th>
            <th className='text-center px-4 py-3'>Category</th>
            <th className='pl-4 py-3'>Result</th>
            <th className='pl-4 py-3'></th>
          </tr>
        </thead>
        <tbody>
          {list &&
            list.map((item: IOrder, index) => {
              return (
                <tr className='border-b' key={index}>
                  <td className='text-left pr-4 py-3'>
                    {item.userSquenceId
                      ? item.userSquenceId.toString().padStart(6, '0')
                      : ''}
                  </td>
                  <td className='text-left pr-4 py-3'>
                    {item.squenceId.toString().padStart(6, '0')}
                  </td>
                  <td className='text-left pr-4 py-3'>
                    {item.fileNames.join(',')}
                  </td>
                  <td className='text-center px-4 py-3 capitalize'>
                    {item.status}
                  </td>
                  <td className='text-center px-4 py-3'>
                    {item.createdAt &&
                      moment(
                        new Date(
                          (item.createdAt as any)._seconds * 1000 +
                            (item.createdAt as any)._nanoseconds / 1000000,
                        ),
                      ).format('DD-MMM-YYYY')}
                  </td>
                  <td className='text-center px-4 py-3'>{item.userName}</td>
                  <td className='text-center px-4 py-3 capitalize'>
                    {item.excuteType ? 'scheduled' : 'immediate'}
                  </td>
                  <td className='text-center px-4 py-3 capitalize'>
                    {item.orderType}
                  </td>
                  <td className='text-right pl-4 py-3'>
                    <div className='flex justify-center underline'>
                      {item.finishedAt && (
                        <Link
                          to={`/orderdetails/${item.id}`}
                          className='flex gap-1'
                        >
                          <ChartBarIcon width={16} /> <span>Details</span>
                        </Link>
                      )}
                    </div>
                    {item.paymentTransactionId === null && (
                      <>
                        <span></span>
                        <Link
                          to={`/checkout/${item.id}`}
                          className='flex gap-1 text-center underline'
                        >
                          <span>
                            Order is not Placed yet. <br /> Place order here
                          </span>
                        </Link>
                      </>
                    )}
                  </td>
                  <td className='text-right pl-4 py-3'>
                    <div className='flex justify-center underline'>
                      {(item.finishedAt ||
                        item.paymentTransactionId !== null) && (
                        <Link
                          to={`/submitticket/${item.squenceId}`}
                          className='flex gap-1'
                        >
                          <PlusIcon width={16} /> <span>Request</span>
                        </Link>
                      )}
                    </div>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
      <div className='w-[700px] mt-4'>
        {totalRecord === 0 && <p className='text-center'>No order exists!</p>}
        {totalRecord !== undefined &&
          totalRecord !== null &&
          totalRecord !== 0 && (
            <Pagination
              page={currentPage}
              size={pageSize}
              total={totalRecord}
              handleChangePage={handleChangePage}
            />
          )}
      </div>
    </div>
  );
};

export default OrderList;
