import React from 'react';
import './App.scss';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { DarkModeProvider } from './context/DarkModeContext';
import { AuthProvider } from './component/authcheck/AuthCheck';
import { LoadingFrame } from './component/loading/Loading';
import Authenticated from './component/authenticated/Authenticated';
import SignIn from './component/signin/SignIn';
import SignUp from './component/signup/Signup';
import SignOut from './component/signout/SignOut';
import ForgotPassword from './component/forgotpassword/ForgotPassword';
import Privacy from './component/privacy/Privacy';
import Term from './component/privacy/Term';
import DeletedRules from './component/privacy/DeletedRules';
import Modal from 'react-modal';

Modal.setAppElement('#root');

const App = () => {
  return (
    <DarkModeProvider>
      <AuthProvider>
        <Router>
          <Routes>
            <Route path='/login' element={<SignIn />} />
            <Route path='/signup' element={<SignUp />} />
            <Route path='/signout' element={<SignOut />} />
            <Route path='/forgotpassword' element={<ForgotPassword />} />
            <Route path='/privacy' element={<Privacy />} />
            <Route path='/term' element={<Term />} />
            <Route path='/deleterules' element={<DeletedRules />} />
            <Route path='/*' element={<Authenticated />} />
          </Routes>
          <LoadingFrame />
        </Router>
      </AuthProvider>
    </DarkModeProvider>
  );
};

export default App;
