import react from 'react';

const Term = () => {
  return (
    <div className='p-10'>
      <div>
        <h3 className='font-bold text-center text-lg'>Customer Radar</h3>
      </div>
      <div>
        <p># Terms of Service</p>

        <p>Last updated: 23-10-2023</p>

        <p>## 1. Agreement to Terms</p>

        <p>
          1.1 These Terms of Service (“Terms”) constitute a legally binding
          agreement made between you, whether personally or on behalf of an
          entity (“you”) and Customer Radar (“we,” “us” or “our”), concerning
          your access to and use of the Customer Radar app (the "Service").
        </p>

        <p>
          1.2 You agree that by accessing the Service, you have read,
          understood, and agree to be bound by all of these Terms. If you do not
          agree with all of these Terms, then you are expressly prohibited from
          using the Service and you must discontinue use immediately.
        </p>
        <p>## 2. Intellectual Property Rights</p>
        <p>
          2.1 The Service and its original content, features, and functionality
          are owned by [Your Company Name] and are protected by international
          copyright, trademarks, patents, trade secrets, and other intellectual
          property or proprietary rights laws.
        </p>
        <p>
          2.2 These Terms permit you to use the Service for your personal,
          non-commercial use only.
        </p>
        <p>## 3. User Representations</p>
        <p>
          3.1 By using the Service, you represent and warrant that: (1) you have
          the legal capacity and you agree to comply with these Terms; (2) you
          are not under the age of 13; (3) you will not use the Service for any
          illegal or unauthorized purpose; and (4) your use of the Service will
          not violate any applicable law or regulation.
        </p>
        <p>## 4. Prohibited Activities</p>
        <p>
          4.1 You may not access or use the Service for any purpose other than
          that for which we make the Service available. The Service may not be
          used in connection with any commercial endeavors except those that are
          specifically endorsed or approved by us.
        </p>
        <p>## 5. User Generated Contributions</p>
        <p>
          5.1 The Service may invite you to chat, contribute to, or participate
          in blogs, message boards, online forums, and other functionality, and
          may provide you with the opportunity to create, submit, post, display,
          transmit, perform, publish, distribute, or broadcast content and
          materials to us or on the Service, including but not limited to text,
          writings, video, audio, photographs, graphics, comments, suggestions,
          or personal information or other material.
        </p>
        <p>
          5.2 Any contributions you transmit to us will be treated as
          non-confidential and non-proprietary.
        </p>
        <p>## 6. Site Management</p>
        <p>
          6.1 We reserve the right, but not the obligation, to: (1) monitor the
          Service for violations of these Terms; (2) take appropriate legal
          action against anyone who, in our sole discretion, violates the law or
          these Terms; (3) refuse, restrict access to, limit the availability
          of, or disable (to the extent technologically feasible) any of your
          Contributions; and (4) otherwise manage the Service in a manner
          designed to protect our rights and property and to facilitate the
          proper functioning of the Service.
        </p>
        <p>## 7. Modifications and Interruptions</p>
        <p>
          7.1 We reserve the right to change, modify, or remove the contents of
          the Service at any time or for any reason at our sole discretion
          without notice. We also reserve the right to modify or discontinue all
          or part of the Service without notice at any time.
        </p>
        <p>## 8. Governing Law</p>
        <p>
          8.1 These Terms and your use of the Service are governed by and
          construed in accordance with the laws of the country/state where [Your
          Company Name] is located, without regard to its conflict of law
          principles.
        </p>
        <p>## 9. Contact Information</p>
        <p>
          To resolve a complaint regarding the Service or to receive further
          information regarding use of the Service, please contact us at:
        </p>
        <p>customerradar.app@gmail.com</p>
      </div>
    </div>
  );
};

export default Term;
