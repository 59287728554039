import react, { useState } from 'react';
import cx from 'classnames';
import IconAvatar from '@/assets/icon-avatar.svg';
import useUser from '@/hook/useUser';
import { useNavigate, NavLink } from 'react-router-dom';
import { useAuthContext } from '@/component/authcheck/AuthCheck';
const Account = () => {
  const nav = useNavigate();
  const [show, setShow] = useState<boolean>(false);
  const { logout } = useUser();
  const { user } = useAuthContext();
  const toogle = () => {
    setShow(!show);
  };

  return (
    <div className='dropdown'>
      <div className='dropdown-toggle' onClick={toogle}>
        <div className={cx('header-action-item', 'flex items-center gap-2')}>
          <span className={cx('avatar', 'avatar-circle')}>
            <img
              className={cx('avatar-img', 'avatar-circle')}
              src={user && user.photoUrl ? user.photoUrl : IconAvatar}
              loading='lazy'
              alt='avatar'
            />
          </span>
          <div className='hidden md:block'>
            {user && (
              <>
                <div className='flex justify-between'>
                <div className='text-xs capitalize'>{user?.role || ''}</div>
              
                </div>
                <div className='font-bold'>
                  {user.firstName} {user.lastName}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <ul
        className={cx('dropdown-menu bottom-end p-0', {
          'flex flex-col': show,
          hidden: !show,
        })}
      >
        <NavLink
          className='menu-item-header cursor-pointer w-full'
          to='/submitticket'
        >
          <div className='px-4 py-2 flex items-center justify-between'>
            <span>Contact Us</span>
          </div>
        </NavLink>
        <NavLink className='menu-item-header cursor-pointer w-full' to='/faq'>
          <div className='px-4 py-2 flex items-center justify-between'>
            <span>FAQ's</span>
          </div>
        </NavLink>
        <NavLink className='menu-item-header cursor-pointer w-full' to='/privacy' target='_blank'>
          <div className='px-4 py-2 flex items-center justify-between'>
            <span>Privacy Policy</span>
          </div>
        </NavLink>
        <NavLink
          className='menu-item-header cursor-pointer w-full'
          to='/signout'
        >
          <div className='px-4 py-2 flex items-center justify-between'>
            <span>Sign out</span>
          </div>
        </NavLink>
      </ul>
    </div>
  );
};

export default Account;
