import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { XMarkIcon } from '@heroicons/react/24/solid';
import Label from '@/atom/controls/Label';
import Button from '@/atom/controls/Button';
import Input from '@/atom/controls/Input';
import Select from '@/atom/controls/Select';
import Error from '@/atom/controls/Error';
import { IAccountRole } from '@/model/Types';
import useValidate from '@/hook/useValidate';
import useLoading from '@/hook/useLoading';
import useUser from '@/hook/useUser';
import { useAuthContext } from '../authcheck/AuthCheck';
import { IUser } from '@/model/Models';
const customStyles = {
  overlay: {
    zIndex: 9999,
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

type AddMemberProps = {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  member: IUser | null;
  updateMemberList: (member: IUser, isNew: boolean) => void;
};

interface IFormFields {
  firstName: string | null;
  lastName: string | null;
  email?: string | null;
  role?: IAccountRole | string | null;
}
const defaultFormFields: IFormFields = {
  firstName: '',
  lastName: '',
  email: '',
  role: 'Manager',
};

const AddMember = ({
  isOpen,
  setIsOpen,
  member,
  updateMemberList,
}: AddMemberProps) => {
  const [isNew, setIsNew] = useState(true);
  const { validateRequired, validateEmail } = useValidate();
  const { startLoading, stopLoading } = useLoading();
  const { createMemberUser, updateUser } = useUser();
  const { user } = useAuthContext();
  const [formState, setFormState] = useState<IFormFields>({
    ...defaultFormFields,
  });
  const [errors, setErrors] = useState<IFormFields>({ ...defaultFormFields });
  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    const { name, value } = event.target;
    setFormState({
      ...formState,
      [name]: value,
    });
  };
  useEffect(() => {
    if (member) {
      formState.firstName = member.firstName;
      formState.lastName = member.lastName;
      formState.role = member.role;
      formState.email = member.email;
      setFormState((prev) => {
        return { ...formState };
      });
      setIsNew(false);
    } else {
      setIsNew(true);
    }
  }, [member]);

  useEffect(() => {}, [isNew]);

  const validateForm = () => {
    const errFirstName = validateRequired(formState.firstName);
    const errLastName = validateRequired(formState.lastName);
    const errEmail = validateEmail(formState.email);
    const errRole = validateRequired(formState.role);
    setErrors({
      firstName: errFirstName,
      lastName: errLastName,
      email: errEmail,
      role: errRole,
    });
    return !errFirstName && !errLastName && !errRole && !errEmail;
  };

  const generatePassword = () => {
    const password = Math.floor(100000 + Math.random() * 900000);
    return password.toString();
  };

  const submitClick = async (event) => {
    event.preventDefault();
    const valid = validateForm();
    if (valid) {
      startLoading('Processing...');
      if (isNew) {
        const params = {
          firstName: formState.firstName,
          lastName: formState.lastName,
          type: 'Company',
          email: formState.email,
          role: formState.role,
          companyId: user?.role === 'Manager' ? user.companyId : user?.id,
          companyName: user?.companyName,
          password: generatePassword(),
        } as IUser;
        const res = await createMemberUser(params);
        stopLoading();
        console.log('res new', res);
        if (res.status) {
          close();
          updateMemberList(res.data, isNew);
        } else {
          alert(res.message);
        }
      } else {
        const params = {
          ...member,
          firstName: formState.firstName,
          lastName: formState.lastName,
          email: formState.email,
          role: formState.role,
        } as IUser;
        const hasUpdateEmail = member?.email !== formState.email;
        const res = await updateUser(params, hasUpdateEmail);
        stopLoading();
        console.log('res edit', res);
        if (res.status) {
          close();
          updateMemberList(res.data, isNew);
        } else {
          alert(res.message);
        }
      }
    }
  };

  const close = () => {
    setIsOpen(false);
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => setIsOpen(false)}
      shouldCloseOnOverlayClick={false}
      style={customStyles}
    >
      <div className='flex justify-between pb-1 border-b'>
        <span className='text-xl font-bold'>
          {isNew && 'Add member'}
          {!isNew && 'Update the Member Information'}
        </span>
        <XMarkIcon width={24} onClick={close} className='cursor-pointer' />
      </div>
      <form className='mt-3'>
        <div className='mb-3'>
          <Label>First name</Label>
          <Input
            name='firstName'
            placeholder={'First name'}
            value={formState.firstName || ''}
            required={true}
            onChange={handleInputChange}
          ></Input>
          {errors.firstName && <Error message={errors.firstName} />}
        </div>
        <div className='mb-3'>
          <Label>Last name</Label>
          <Input
            name='lastName'
            placeholder={'Last name'}
            value={formState.lastName || ''}
            required={true}
            onChange={handleInputChange}
          ></Input>
          {errors.lastName && <Error message={errors.lastName} />}
        </div>
        <div className='mb-3'>
          <Label>Email</Label>
          <Input
            name='email'
            placeholder={'Email'}
            value={formState.email || ''}
            required={true}
            onChange={handleInputChange}
          ></Input>
          {errors.email && <Error message={errors.email} />}
        </div>
        <div className='mb-3'>
          <Label>Role</Label>
          <Select
            name='role'
            value={formState.role || ''}
            onChange={handleInputChange}
          >
            <option value='Manager'>Manager</option>
            <option value='Analyst'>Analyst</option>
          </Select>
        </div>
        <div className='mt-6 text-right'>
          <Button className='submit' onClick={submitClick}>
            Save
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default AddMember;
