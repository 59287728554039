import { auth, functions } from '@/ultils/firebase';
import {
  createUserWithEmailAndPassword,
  fetchSignInMethodsForEmail,
  getAuth,
  GoogleAuthProvider,
  FacebookAuthProvider,
  TwitterAuthProvider,
  OAuthProvider,
  signInWithPopup,
  signInWithEmailAndPassword,
  signOut as firebaseSignOut,
  User,
} from 'firebase/auth';
import { httpsCallable } from 'firebase/functions';
import { useContext } from 'react';
import { AuthContext } from '@/component/authcheck/AuthCheck';
import { ISearchType, IPagationResult } from '@/model/Types';
import { IUser } from '@/model/Models';
import { IResponse, IResponseError } from '@/model/Responses';

const useUser = () => {
  const signInWithPassword = async (
    email,
    password,
  ): Promise<IResponse<User>> => {
    if (email && password) {
      try {
        const res = await signInWithEmailAndPassword(auth, email, password);
        if (!res.user.emailVerified) {
          getAuth().signOut();
          return {
            status: false,
            error: 'Please verify your email address before login.',
          };
        }
        return {
          status: true,
          data: res.user,
        };
      } catch (error: any) {
        const err = handleError(error);
        return err;
      }
    } else {
      return {
        status: false,
        error: 'Missing username or password',
      };
    }
  };

  const signUpWithEmailAndPassword = async (
    user: IUser,
  ): Promise<IResponse<IUser>> => {
    try {
      const provider = await fetchSignInMethodsForEmail(
        auth,
        user.email as string,
      );
      if (provider.length === 0) {
        const res = await createUserWithEmailAndPassword(
          auth,
          user.email as string,
          user.password as string,
        );
        user.id = res.user.uid;
        const newUser = await getOrCreate(user);
        await firebaseSignOut(auth);
        if (newUser.status) {
          await sendEmailVerify(newUser.data);
          return newUser;
        } else {
          return newUser;
        }
      } else {
        if (provider.indexOf('password') !== -1) {
          return {
            status: false,
            error: `The email address already in use. Please verify your email or use forgot password.`,
            message: `The email address already in use. Please verify your email or use forgot password.`,
          };
        } else {
          return {
            status: false,
            error: `The email address already in use with sign in method ${provider.join(
              '.',
            )}`,
            message: `The email address already in use with sign in method ${provider.join(
              '.',
            )}`,
          };
        }
      }
    } catch (error: any) {
      return handleError(error);
    }
  };

  const handleError = (error): IResponseError<string> => {
    let response: IResponseError<string> = { status: false, error: '' };
    switch (error.code) {
      case 'auth/invalid-email':
        response.message = 'The email address is not valid.';
        break;
      case 'auth/user-disabled':
        response.message =
          'The user corresponding to the given email has been disabled.';
        break;
      case 'auth/user-not-found':
        response.message = 'There is no user corresponding to the given email.';
        break;
      case 'auth/wrong-password':
        response.message = 'The password is invalid for the given email.';
        break;
      case 'auth/email-already-in-use':
        response.message = 'The email address already in use.';
        break;
      case 'auth/email-not-found':
        response.message =
          'There is no user record corresponding to the provided email.';
        break;
      case 'auth/invalid-login-credentials':
        response.message = 'The password is invalid for the given email.';
        break;
      case 'auth/internal-error':
        response.message =
          'The email address is not existed with email provider.';
        break;
      case 'auth/account-exists-with-different-credential':
        response.message =
          'The account existed with diffirent credential. You already use other provider before.';
        break;
      case 'auth/email-already-exists':
        response.message =
          'The email address is already in use by another account.';
        break;
      default:
        response.message = 'An unknown error occurred';
    }
    response.code = error.code;
    response.stackTrace = error;
    return response;
  };

  const getOrCreate = async (user: IUser): Promise<IResponse<IUser>> => {
    const method = httpsCallable<IUser, IResponse<IUser>>(
      functions,
      'User_getOrCreateUser',
    );
    const res = await method(user);
    return res.data;
  };

  const sendEmailVerify = async (user: IUser): Promise<IResponse<boolean>> => {
    const method = httpsCallable<IUser, IResponse<boolean>>(
      functions,
      'User_sendEmailVerify',
    );
    const res = await method(user);
    return res.data;
  };

  const resetPassword = async (email): Promise<IResponse<boolean>> => {
    try {
      const method = httpsCallable(functions, 'User_sendEmailReset');
      const res: any = await method({ email: email });
      console.log('res', res);
      if (!res.data.status) {
        return handleError(res.data);
      } else {
        return {
          status: true,
          data: true,
        };
      }
    } catch (error) {
      return handleError(error);
    }
  };

  const logout = async (): Promise<IResponse<boolean>> => {
    try {
      await firebaseSignOut(auth);
      return {
        status: true,
        data: true,
      };
    } catch (error) {
      return {
        status: false,
        error: error,
      };
    }
  };

  const signInWithGoogle = async (): Promise<IResponse<User>> => {
    try {
      const provider = new GoogleAuthProvider();
      const res = await signInWithPopup(auth, provider);
      return {
        status: true,
        data: res.user,
      };
    } catch (error) {
      return handleError(error);
    }
  };

  const signInWithFacebook = async (): Promise<IResponse<User>> => {
    try {
      const provider = new FacebookAuthProvider();
      const res = await signInWithPopup(auth, provider);
      return {
        status: true,
        data: res.user,
      };
    } catch (error) {
      return handleError(error);
    }
  };

  const signInWithMicrosoft = async (): Promise<IResponse<User>> => {
    try {
      const provider = new OAuthProvider('microsoft.com');
      const res = await signInWithPopup(auth, provider);
      return {
        status: true,
        data: res.user,
      };
    } catch (error: any) {
      return handleError(error);
    }
  };

  const signInWithTwitter = async (): Promise<IResponse<User>> => {
    try {
      const provider = new TwitterAuthProvider();
      const res = await signInWithPopup(auth, provider);
      return {
        status: true,
        data: res.user,
      };
    } catch (error: any) {
      return handleError(error);
    }
  };

  const signInWithApple = async (): Promise<IResponse<User>> => {
    try {
      const provider = new OAuthProvider('apple.com');
      const res = await signInWithPopup(auth, provider);
      return {
        status: true,
        data: res.user,
      };
    } catch (error: any) {
      return handleError(error);
    }
  };

  const createMemberUser = async (user: IUser): Promise<IResponse<IUser>> => {
    try {
      const method = httpsCallable<IUser, IResponse<IUser>>(
        functions,
        'User_createMemberUser',
      );
      const res = await method(user);
      if (!res.data.status) {
        return handleError(res.data);
      } else {
        return res.data;
      }
    } catch (error: any) {
      return handleError(error);
    }
  };

  const grantAccessUser = async (user: IUser): Promise<IResponse<IUser>> => {
    try {
      const method = httpsCallable<IUser, IResponse<IUser>>(
        functions,
        'User_grantAccessUser',
      );
      const res: any = await method(user);
      if (!res.data.status) {
        return handleError(res.data);
      } else {
        return res.data;
      }
    } catch (error: any) {
      return handleError(error);
    }
  };

  const removeAccessUser = async (user: IUser): Promise<IResponse<IUser>> => {
    try {
      const method = httpsCallable<IUser, IResponse<IUser>>(
        functions,
        'User_removeAccessUser',
      );
      const res: any = await method(user);
      if (!res.data.status) {
        return handleError(res.data);
      } else {
        return res.data;
      }
    } catch (error: any) {
      return handleError(error);
    }
  };

  const updateUser = async (
    user: IUser,
    hasUpdateEmail: boolean,
  ): Promise<IResponse<IUser>> => {
    try {
      const method = httpsCallable<
        { user: IUser; hasUpdateEmail: boolean },
        IResponse<IUser>
      >(functions, 'User_updateUser');
      const res = await method({
        user: user,
        hasUpdateEmail: hasUpdateEmail,
      });
      if (!res.data.status) {
        return handleError(res.data);
      } else {
        return res.data;
      }
    } catch (error: any) {
      return handleError(error);
    }
  };

  const getCompanyMembers = async (
    companyId: string,
  ): Promise<IResponse<IUser[]>> => {
    try {
      const method = httpsCallable<string, IResponse<IUser[]>>(
        functions,
        'User_getCompanyMembers',
      );
      const res = await method(companyId);
      return res.data;
    } catch (error: any) {
      return handleError(error);
    }
  };

  const deleteUser = async (userUID: string): Promise<IResponse<string>> => {
    try {
      const method = httpsCallable<string, IResponse<string>>(
        functions,
        'User_deleteUser',
      );
      const res = await method(userUID);
      return res.data;
    } catch (error: any) {
      return handleError(error);
    }
  };

  const closeAccount = async (params: any): Promise<IResponse<string>> => {
    try {
      const method = httpsCallable<any, IResponse<string>>(
        functions,
        'User_closeAccount',
      );
      const res = await method(params);
      return res.data;
    } catch (error: any) {
      return handleError(error);
    }
  };

  const getUsersTotalRecord = async (): Promise<IResponse<number>> => {
    const method = httpsCallable<null, IResponse<number>>(
      functions,
      'User_getUsersTotalRecord',
    );
    const res = await method();
    return res.data;
  };

  const getUsers = async ({
    search,
    type,
    pageSize,
    currentPage,
    role = '',
  }: {
    search: string | null;
    type: ISearchType;
    pageSize: number;
    currentPage: number;
    role?: string;
  }): Promise<IPagationResult<IUser>> => {
    const method = httpsCallable<any, IPagationResult<IUser>>(
      functions,
      'User_getUsers',
    );
    const res = await method({
      search: search,
      type: type,
      pageSize: pageSize,
      currentPage: currentPage,
      role: role,
    });
    return res.data;
  };

  return {
    signInWithGoogle,
    signInWithFacebook,
    signInWithMicrosoft,
    signInWithTwitter,
    signInWithApple,
    signInWithPassword,
    signUpWithEmailAndPassword,
    sendEmailVerify,
    resetPassword,
    getOrCreate,
    logout,
    createMemberUser,
    updateUser,
    getCompanyMembers,
    deleteUser,
    closeAccount,
    getUsersTotalRecord,
    getUsers,
    grantAccessUser,
    removeAccessUser,
  };
};

export default useUser;
