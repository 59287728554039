import React, { useEffect, useRef, useState } from 'react';
import Button from '@/atom/controls/Button';
import {
  PlusIcon,
  TrashIcon,
  PencilIcon,
  ChevronRightIcon,
} from '@heroicons/react/24/solid';
import AddMember from './AddMember';
import useUser from '@/hook/useUser';
import { useAuthContext } from '../authcheck/AuthCheck';
import useLoading from '@/hook/useLoading';
import { Link } from 'react-router-dom';
import { IUser } from '@/model/Models';
const Organization = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { getCompanyMembers, deleteUser } = useUser();
  const { startLoading, stopLoading } = useLoading();
  const { user } = useAuthContext();
  const preventReload = useRef(false);
  const [list, setList] = useState<IUser[]>([]);
  const [member, setMember] = useState<IUser | null>(null);

  const addMemberClick = () => {
    setMember(null);
    setTimeout(() => {
      setIsModalOpen(true);
    }, 100);
  };

  const fetchMembers = async (companyId: string) => {
    startLoading();
    const res = await getCompanyMembers(companyId);
    stopLoading();
    if (res.status) {
      setList(res.data);
    }
  };

  useEffect(() => {
    if (user && !preventReload.current) {
      preventReload.current = true;
      const companyId: any = user.role === 'Manager' ? user.companyId : user.id;
      fetchMembers(companyId);
    }
  }, []);

  const editMember = (item: IUser) => {
    setMember(item);
    setTimeout(() => {
      setIsModalOpen(true);
    }, 100);
  };

  const deleteUserClick = async (data: IUser) => {
    if (data.id === user?.id) {
      alert('You can not delete yourself');
      return;
    }
    if (data.role === 'Owner') {
      alert('You can not delete company owner');
      return;
    }
    if (window.confirm('Are you sure you want to delete this user?')) {
      startLoading();
      const res = await deleteUser(data.id);
      stopLoading();
      if (res.status) {
        const l = list.filter((item) => item.id !== data.id);
        setList((prev) => l);
      }
    }
  };

  const updateMemberList = (updatedMember: IUser, isNew: boolean) => {
    if (updatedMember.role === 'Owner') {
      alert('You can not update company owner');
      return;
    }
    if (isNew) {
      setList((prev) => [...prev, updatedMember]);
    } else {
      setList((prev) =>
        prev.map((member) =>
          member.id === updatedMember.id ? updatedMember : member,
        ),
      );
    }
  };
  return (
    <div className='container'>
      <p className='text-lg flex'>
        <Link className='font-bold' to='/account'>
          Your Account
        </Link>{' '}
        <ChevronRightIcon width={20} /> Your Users
      </p>
      <div className='mt-4'>
        <button
          className='rounded flex px-3 py-1 border justify-between'
          onClick={addMemberClick}
        >
          <PlusIcon width={16} />
          <span className='ml-2'>Add</span>
        </button>
      </div>
      <div>
        <table className='min-w-full md:min-w-[700px] mt-2'>
          <thead>
            <tr className='border-b-2'>
              <th className='text-left pr-4 py-3'>Name</th>
              <th className='text-center px-4 py-3'>Email</th>
              <th className='text-center px-4 py-3'>Role</th>
              <th className='pl-4 py-3'></th>
            </tr>
          </thead>
          <tbody>
            {list &&
              list.map((item, index) => {
                return (
                  <tr className='border-b' key={index}>
                    <td className='text-left pr-4 py-3'>
                      {item.firstName} {item.lastName}
                    </td>
                    <td className='text-center px-4 py-3'>{item.email}</td>
                    <td className='text-center px-4 py-3'>{item.role}</td>
                    <td className='text-right pl-4 py-3'>
                      <div className='flex justify-end'>
                        <button
                          className='underline  mr-4 flex justify-between'
                          onClick={() => deleteUserClick(item)}
                        >
                          <TrashIcon width={16} />
                          <span className='ml-1'>Remove</span>
                        </button>
                        <button
                          className='underline flex justify-between'
                          onClick={() => editMember(item)}
                        >
                          <PencilIcon width={16} />{' '}
                          <span className='ml-1'>Edit</span>
                        </button>
                      </div>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
      {isModalOpen && (
        <AddMember
          isOpen={isModalOpen}
          setIsOpen={setIsModalOpen}
          member={member}
          updateMemberList={updateMemberList}
        />
      )}
    </div>
  );
};

export default Organization;
