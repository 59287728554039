import React, { useState, useEffect, useContext } from 'react';
import cx from 'classnames';
import ss from './Upload.module.scss';
import FileUpload from '../../atom/fileupload/FileUpload';
import { IFileData } from '../../model/Types';
import useStoreService from '../../hook/useStoreService';
import useDataProcess from '../../hook/useDataProcess';
import useLoading from '@/hook/useLoading';
import OrderResult from '../order/OrderResult';
import UploadTip from './UploadTip';
import Label from '@/atom/controls/Label';
import Select from '@/atom/controls/Select';
import { StakeHolders } from '../../model/Types';
import { IResponse } from '@/model/Responses';
import { useNavigate } from 'react-router-dom';
import useCheckout from '@/hook/useCheckout';
import { useAuthContext } from '../authcheck/AuthCheck';

const Upload: React.FC = () => {
  const nav = useNavigate();
  const { user } = useAuthContext();
  const [list, setList] = useState<IFileData[]>([]);
  const [totalRows, setTotalRows] = useState<number>();
  const [totalTokens, setTotalTokens] = useState<number>();
  const [totalMinutes, setTotalMinutes] = useState<number>();
  const [totalSeconds, setTotalSeconds] = useState<number>();
  const [cost, setCost] = useState<number>();
  const [response, setResponse] = useState<IResponse>();
  const [disableButton, setDisableButton] = useState<boolean>(false);
  const [orderType, setOrderType] = useState<string>('');
  const { calculateTime } = useDataProcess();
  const { createJob } = useStoreService();
  const { startLoading, stopLoading } = useLoading();
  const { getCost } = useCheckout();

  const fetchCost = async (tokens: number) => {
    const res = await getCost(tokens);
    setCost(res);
  };

  useEffect(() => {
    const total = calculateTime(list);
    setTotalRows(total.totalRows);
    setTotalTokens(total.totalTokens);
    setTotalMinutes(total.totalMinutes);
    setTotalSeconds(total.totalSeconds);
    fetchCost(total.totalTokens);
  }, [list]);

  const handleInputChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = event.target;
    setOrderType(value);
  };

  const addFileToList = (file: IFileData) => {
    setList([...list, file]);
  };

  const removeFileFromList = (file: IFileData) => {
    setList(list.filter((f) => f.id !== file.id));
  };

  const submitCommand = async (excuteType: boolean) => {
    if (!orderType) {
      alert('please select stakeholder category');
      return;
    }
    const message = `The process could require approximately ${totalMinutes} minutes ${totalSeconds} seconds. Would you like to ${
      excuteType
        ? 'set a schedule for this order? You will receive an email notification once the order is finished.'
        : 'continue placing your order?'
    }?`;
    // eslint-disable-next-line no-restricted-globals
    if (confirm(message) === true) {
      let f = list
        .filter((item) => item.isValid)
        .map((item) => {
          const { id, ...res } = item;
          return res;
        });
      startLoading('Analyzing');
      setDisableButton(true);
      const res = await createJob(f, excuteType, orderType);
      stopLoading();
      setDisableButton(false);
      if (res.status) {
        setList([]);
        if (user && user.paymentMethodId) {
          nav(`/checkout/${res.data.orderId}`);
        } else {
          nav(`/payment/${res.data.orderId}`);
        }
      }
    }
  };

  return (
    <div style={{ padding: '30px' }}>
      <p className={ss['title']}>Upload excel files:</p>
      <div className='flex gap-5 my-4'>
        <div className='flex flex-col gap-3'>
          <FileUpload
            fileList={list}
            addFileToList={addFileToList}
            removeFileFromList={removeFileFromList}
          />
          <FileUpload
            fileList={list}
            addFileToList={addFileToList}
            removeFileFromList={removeFileFromList}
          />
          <FileUpload
            fileList={list}
            addFileToList={addFileToList}
            removeFileFromList={removeFileFromList}
          />
          <div className='flex items-center gap-5'>
            <Label>Stakeholder Category</Label>
            <Select
              className='flex-1'
              value={orderType}
              onChange={handleInputChange}
            >
              <option value={''} disabled hidden>
                Select a stake holder
              </option>
              {StakeHolders.map((item) => (
                <option key={item.value} value={item.value}>
                  {item.name}
                </option>
              ))}
            </Select>
          </div>
        </div>
        <div className='border rounded px-5 py-3'>
          <UploadTip />
        </div>
      </div>
      {totalRows !== 0 && (
        <div className={ss['summary']}>
          <p>Total: {list.length} files</p>
          <p>Total: {totalRows} unique rows</p>
          <p>Total: {totalTokens} tokens.</p>
          <p>Cost before tax: $ {cost}</p>
          <p>
            Process may take {totalMinutes} minutes {totalSeconds} seconds.
          </p>
        </div>
      )}

      <div className='flex gap-5'>
        <button
          className='btn submit'
          onClick={() => submitCommand(false)}
          disabled={disableButton || totalRows === 0}
        >
          Upload file to place your order now
        </button>
        <button
          className='btn submit'
          onClick={() => submitCommand(true)}
          disabled={disableButton || totalRows === 0}
        >
          Upload file to place your order to the Queue
        </button>
      </div>
      {response && response.status && <OrderResult orderId={response.data} />}
    </div>
  );
};

export default Upload;
