import { SET_LOADING, SET_LOADING_TEXT } from './ActionTypes';

// Define the type for the loading state
export interface SetLoadingAction {
  type: typeof SET_LOADING;
  payload: boolean;
}

// Create the action creator
export const setLoading = (isLoading: boolean): SetLoadingAction => ({
  type: SET_LOADING,
  payload: isLoading,
});

export interface SetLoadingTextAction {
  type: typeof SET_LOADING_TEXT;
  payload: string;
}

export const setLoadingText = (text: string): SetLoadingTextAction => ({
  type: SET_LOADING_TEXT,
  payload: text,
});
