const useExport = () => {
  const transformToArray = (data) => {
    let rows: any[] = [];
    let name = data.displayName;
    let firstRow = [name];
    let dump = new Array(data.deep).fill(null);
    firstRow = firstRow.concat(dump);
    rows.push(firstRow);
    generateRow(data.value, 0, rows);
    return rows;
  };

  const transformToTable = (cols) => {
    const table = document.createElement('table');
    let maxRow = 0;
    cols.forEach((items: any[]) => {
      maxRow = Math.max(maxRow, items.length);
    });
    for (let i = 0; i < maxRow; i++) {
      const row = document.createElement('tr');
      cols.forEach((items: any[]) => {
        if (i === 1) {
          const cell = document.createElement('td');
          cell.textContent = items[0][0];
          cell.colSpan = items[0].length;
          row.appendChild(cell);
        }
      });
      table.appendChild(row);
    }
    return table;
  };

  const generateRow = (
    obj,
    currentDepth,
    rows,
    parentKey: string | null = null,
  ) => {
    Object.entries(obj).forEach(([key, value], index) => {
      if (typeof value === 'object' && value !== null) {
        generateRow(value, currentDepth + 1, rows, key);
      } else {
        let row: any[] = [];
        if (parentKey && index === 0) {
          row.push(parentKey);
        } else {
          if (currentDepth !== 0) {
            row.push(null);
          }
        }
        row.push(key, value);
        rows.push(row);
      }
    });
  };

  return {
    transformToArray,
    transformToTable,
  };
};
export default useExport;
