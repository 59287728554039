import {
  createContext,
  useContext,
  useEffect,
  useState,
  ReactNode,
} from 'react';

type DarkModeContextType = {
  darkMode: boolean;
  setDarkMode: (darkMode: boolean) => void;
};

const DarkModeContext = createContext<DarkModeContextType>({
  darkMode: false,
  setDarkMode: () => { },
});

export const useDarkMode = (): DarkModeContextType => {
  return useContext(DarkModeContext);
};

interface DarkModeProviderProps {
  children: ReactNode;
}

export const DarkModeProvider = ({ children }: DarkModeProviderProps) => {
  const isSystemDarkMode =
    window.matchMedia &&
    window.matchMedia('(prefers-color-scheme: dark)').matches;
  const [darkMode, setDarkMode] = useState(true);

  useEffect(() => {
    const storedDarkMode = localStorage.getItem('darkMode');
    if (storedDarkMode !== null) {
      setDarkMode(storedDarkMode === 'true');
    } else {
      setDarkMode(isSystemDarkMode);
    }
  }, [isSystemDarkMode]);

  useEffect(() => {
    localStorage.setItem('darkMode', darkMode.toString());
    if (darkMode) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }, [darkMode]);

  const attributes = {
    dir: 'ltr',
  };

  for (let [key, value] of Object.entries(attributes)) {
    document.documentElement.setAttribute(key, value);
  }

  return (
    <DarkModeContext.Provider value={{ darkMode, setDarkMode }}>
      {children}
    </DarkModeContext.Provider>
  );
};
