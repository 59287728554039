import React from 'react';
import { Chart, BarElement, Title, CategoryScale, LinearScale } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import cx from 'classnames';
import ss from './PieChart.module.css';
import FilterButton from './FilterButton';

Chart.register(BarElement, CategoryScale, LinearScale, Title);
type ColumnChartProps = {
  data: any;
  title: string;
  width?: string;
  maxWidth?: string;
  height?: string;
  showFilters?: Record<string, { isShow: boolean; variable: string }>;
  setShowFilters?: React.Dispatch<
    React.SetStateAction<Record<string, { isShow: boolean; variable: string }>>
  >;
};
const ColumnChartNegative = ({
  data,
  title,
  width,
  maxWidth,
  height,
  showFilters,
  setShowFilters,
}: ColumnChartProps) => {
  let labels: string[] = [];
  let postiveValues: number[] = [];
  let negativeValues: number[] = [];
  let dataValue = data.value;
  Object.keys(dataValue).forEach((key) => {
    labels.push(key);
    postiveValues.push(parseInt(dataValue[key]['positive']));
    negativeValues.push(-parseInt(dataValue[key]['negative']));
  });
  const dt = {
    labels: labels,
    datasets: [
      {
        label: 'Positive',
        data: postiveValues,
        backgroundColor: 'rgba(0, 123, 255, 0.5)',
      },
      {
        label: 'Negative',
        data: negativeValues,
        backgroundColor: 'rgba(255, 0, 0, 0.5)',
      },
    ],
  };

  const options = {
    scales: {
      x: {
        beginAtZero: true,
        stacked: true,
        ticks: {
          maxRotation: 90, // Set to 90 degrees
          minRotation: 90, // Set to 90 degrees
        },
      },
      y: {
        beginAtZero: true,
        stacked: true,
      },
    },
    maintainAspectRatio: false,
  };

  return (
    <div style={{ paddingBottom: '50px' }}>
      <div style={{ width: width ? width : '900px', height: '400px', maxWidth: maxWidth ? maxWidth: '' }}>
        <div className={ss['chartTitleWrapper']}>
          <p className={ss['chartTitle']}>{title}</p>
          {showFilters && (
            <FilterButton
              data={data}
              showFilters={showFilters}
              setShowFilters={setShowFilters}
            />
          )}
        </div>
        <Bar data={dt} options={options} />
      </div>
    </div>
  );
};

export default ColumnChartNegative;
