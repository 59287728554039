import React, { useEffect, useState, useContext, useRef } from 'react';
import useLoading from '@/hook/useLoading';
import useStoreService from '@/hook/useStoreService';
import moment from 'moment';
import Pagination from '../order/Pagination';
import Select from '@/atom/controls/Select';
import { ISearchType } from '@/model/Types';
import Input from '@/atom/controls/Input';
import Label from '@/atom/controls/Label';
import Button from '@/atom/controls/Button';

interface IFormFields {
  searchText: string | null;
  searchType: ISearchType;
}
const defaultFormFields: IFormFields = {
  searchText: '',
  searchType: '',
};

const ListOrder = () => {
  const { getOrdersByAdmin } = useStoreService();
  const { startLoading, stopLoading } = useLoading();
  const [currentPage, setCurrentPage] = useState<number>(-1);
  const [pageSize, setPageSize] = useState<number>(20);
  const [totalRecord, setTotalRecord] = useState<number>();
  const [list, setList] = useState<any[]>([]);
  const preventReload = useRef(false);
  const [formState, setFormState] = useState<IFormFields>({
    ...defaultFormFields,
  });

  const fetchOrders = async () => {
    console.log('fetch getListImported');
    startLoading();
    const dt = await getOrdersByAdmin({
      search: formState.searchText,
      type: formState.searchType,
      currentPage: currentPage,
      pageSize: pageSize,
    });
    console.log('dt', dt);
    stopLoading();
    setTotalRecord(dt.totalRecord);
    setList(dt.result);
  };

  useEffect(() => {
    if (!preventReload.current) {
      preventReload.current = true;
      setCurrentPage(1);
    }
  }, []);

  useEffect(() => {
    if (currentPage === -1) return;
    fetchOrders();
  }, [currentPage]);

  const handleChangePage = (type: string) => {
    const p = type === 'prev' ? currentPage - 1 : currentPage + 1;
    setCurrentPage(p);
  };

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    const { name, value } = event.target;
    setFormState({
      ...formState,
      [name]: value,
    });
  };



  return (
    <div className='container '>
      <p className='text-lg flex font-bold'>All executed digital orders</p>
      <div className='flex gap-2 my-3 !max-w-3xl'>
        <Select
          name='searchType'
          value={formState.searchType}
          onChange={handleInputChange}
        >
          <option value='' hidden>Select search type</option>
          <option value='userId'>Account id</option>
          <option value='orderId'>Order Id</option>
          <option value='firstName'>first name</option>
          <option value='lastName'>last name</option>
          <option value='email'>email</option>
          <option value='company'>company</option>
        </Select>
        <Input
          name='searchText'
          placeholder={'Search'}
          value={formState.searchText || ''}
          required={true}
          onChange={handleInputChange}
        ></Input>
        <Button className='submit' onClick={fetchOrders}>Search</Button>
      </div>
      <table className='min-w-full mt-2'>
        <thead>
          <tr className='border-b-2'>
          <th className='text-left pr-4 py-3'>Account ID</th>
            <th className='text-left pr-4 py-3'>Order ID</th>
            <th className='text-center px-4 py-3'>Status</th>
            <th className='text-center px-4 py-3'>Created Date</th>
            <th className='text-center px-4 py-3'>Created By</th>
            <th className='text-center px-4 py-3'>Execution Type</th>
            <th className='text-center px-4 py-3'>Company name</th>
            <th className='text-center px-4 py-3'>Contact Email</th>
            <th className='text-center px-4 py-3'>First Name</th>
            <th className='text-center px-4 py-3'>Last Name</th>
          </tr>
        </thead>
        <tbody>
          {list &&
            list.map((item: any, index) => {
              return (
                <tr className='border-b' key={index}>
                  <td className='text-left pr-4 py-3'>
                    {item.userSquenceId ? item.userSquenceId.toString().padStart(6, '0') : ''}
                  </td>
                  <td className='text-left pr-4 py-3'>
                    {item.squenceId.toString().padStart(6, '0')}
                  </td>
                  <td className='text-left px-4 py-3'>
                    {item.status}
                  </td>
                  <td className='text-left px-4 py-3'>
                    {moment(new Date(item.createdAt._seconds * 1000 + item.createdAt._nanoseconds / 1000000)).format('DD-MMM-YYYY')}
                  </td>
                  <td className='text-left px-4 py-3'>
                    {item.userName}
                  </td>
                  <td className='text-left px-4 py-3'>
                    {item.excuteType ? 'scheduled' : 'immediate'}
                  </td>
                  <td className='text-left px-4 py-3'>
                    {item.contactCompany}
                  </td>
                  <td className='text-left px-4 py-3'>
                    {item.contactEmail}
                  </td>
                  <td className='text-left px-4 py-3'>
                    {item.contactFirstName}
                  </td>
                  <td className='text-left px-4 py-3'>
                    {item.contactLastName}
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
      <div className='mt-4'>
        {totalRecord === 0 && <p className='text-center'>No order exists!</p>}
        {totalRecord !== undefined &&
          totalRecord !== null &&
          totalRecord !== 0 && (
            <Pagination
              page={currentPage}
              size={pageSize}
              total={totalRecord}
              handleChangePage={handleChangePage}
            />
          )}
      </div>
    </div>
  );
};

export default ListOrder;
