export interface TItem {
  name: string;
  value: string | number;
}

export const SupportTypeList: TItem[] = [
  {
    name: 'Billing related',
    value: 'billing',
  },
  {
    name: 'New Feature Request',
    value: 'feature',
  },
  {
    name: 'Feedback',
    value: 'feedback',
  },
  {
    name: 'Other',
    value: 'other',
  },
];

export const SupportDateList: TItem[] = [
  {
    name: `All today's request`,
    value: 'today',
  },
  {
    name: 'All Request in last 7 days',
    value: 'last7days',
  },
  {
    name: 'All request in last Month',
    value: 'lastmonth',
  },
  {
    name: 'All Request current Year',
    value: 'currentyear',
  },
  {
    name: 'All Request',
    value: 'all',
  },
];
