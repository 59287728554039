import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import ss from '../upload/Upload.module.scss';
import ColumnChart from '@/atom/ColumnChart';
import PieChart from '@/atom/PieChart';
import TreeMapChart from '@/atom/TreeMapChart';
import ColumnChartNegative from '@/atom/ColumnChartNegative';
import { ISegment } from '@/model/Types';
import useDataProcess from '@/hook/useDataProcess';
import BubbleChart from '@/atom/BubbleChart';
import BarChart from '@/atom/BarChart';

const OrderCharts = ({ segments, showFilters, setShowFilters }) => {
  const { sumCategories, getSegment } = useDataProcess();
  const [buttonChart, setButtonChart] = useState<
    | 'categories'
    | 'sentiments'
    | 'behaviors'
    | 'appreciate'
    | 'dissatisfaction'
    | 'improvements'
    | 'experience'
  >('categories');

  return (
    <div className={ss['wrapper-charts']}>
      <div className={ss['wrapper-charts-buttons']}>
        <div
          className={cx(ss['button'], ss['sub'], {
            [ss['active']]: buttonChart === 'categories',
          })}
          onClick={setButtonChart.bind(this, 'categories')}
        >
          <span>Topics & Categories</span>
        </div>
        <div
          className={cx(ss['button'], ss['sub'], {
            [ss['active']]: buttonChart === 'sentiments',
          })}
          onClick={setButtonChart.bind(this, 'sentiments')}
        >
          <span>Sentiments</span>
        </div>
        <div
          className={cx(ss['button'], ss['sub'], {
            [ss['active']]: buttonChart === 'behaviors',
          })}
          onClick={setButtonChart.bind(this, 'behaviors')}
        >
          <span>Behavior</span>
        </div>
        <div
          className={cx(ss['button'], ss['sub'], {
            [ss['active']]: buttonChart === 'appreciate',
          })}
          onClick={setButtonChart.bind(this, 'appreciate')}
        >
          <span>Appreciate</span>
        </div>
        <div
          className={cx(ss['button'], ss['sub'], {
            [ss['active']]: buttonChart === 'dissatisfaction',
          })}
          onClick={setButtonChart.bind(this, 'dissatisfaction')}
        >
          <span>Dissatisfaction</span>
        </div>
        <div
          className={cx(ss['button'], ss['sub'], {
            [ss['active']]: buttonChart === 'improvements',
          })}
          onClick={setButtonChart.bind(this, 'improvements')}
        >
          <span>Improvements</span>
        </div>
        <div
          className={cx(ss['button'], ss['sub'], {
            [ss['active']]: buttonChart === 'experience',
          })}
          onClick={setButtonChart.bind(this, 'experience')}
        >
          <span>Experience</span>
        </div>
      </div>
      <div className={ss['wrapper-charts-content']}>
        {buttonChart === 'categories' && (
          <div className={cx(ss['wrapper-charts-content-child'])}>
            <TreeMapChart
              data={getSegment(segments, 'categories')}
              title={'Sensitivity by Topics'}
              showFilters={showFilters}
              setShowFilters={setShowFilters}
            />
            <BarChart
              data={getSegment(segments, 'topics')}
              title={'Feedback by Topics'}
              showFilters={showFilters}
              setShowFilters={setShowFilters}
            />
          </div>
        )}
        {buttonChart === 'sentiments' && (
          <div className={cx(ss['wrapper-charts-content-child'])}>
            <ColumnChartNegative
              data={getSegment(segments, 'categories')}
              title={'Sentiments By Topics'}
              showFilters={showFilters}
              setShowFilters={setShowFilters}
            />
          </div>
        )}
        {buttonChart === 'behaviors' && (
          <div className={cx(ss['wrapper-charts-content-child'])}>
            <ColumnChart
              data={getSegment(segments, 'customer behaviors')}
              title={'Behavior And Preferences Analysis'}
              showFilters={showFilters}
              setShowFilters={setShowFilters}
            />
          </div>
        )}
        {buttonChart === 'appreciate' && (
          <div className={cx(ss['wrapper-charts-content-child'])}>
            <BubbleChart
              data={getSegment(segments, 'customer appreciate')}
              title={'Appreciate The Most'}
              showFilters={showFilters}
              setShowFilters={setShowFilters}
            />
          </div>
        )}
        {buttonChart === 'dissatisfaction' && (
          <div className={cx(ss['wrapper-charts-content-child'])}>
            <BubbleChart
              data={getSegment(segments, 'customer dissatisfaction')}
              title={'Common Reasons For Dissatisfaction'}
              showFilters={showFilters}
              setShowFilters={setShowFilters}
            />
          </div>
        )}
        {buttonChart === 'improvements' && (
          <div className={cx(ss['wrapper-charts-content-child'])}>
            <ColumnChart
              data={getSegment(segments, 'customer improvements')}
              title={'Improvements'}
              showFilters={showFilters}
              setShowFilters={setShowFilters}
            />
          </div>
        )}
        {buttonChart === 'experience' && (
          <div className={cx(ss['wrapper-charts-content-child'])}>
             <BarChart
                 data={getSegment(segments, 'customer expectations')}
                 title={'Experience'}
                 showFilters={showFilters}
                 setShowFilters={setShowFilters}
            />
          </div>
        )}
      </div>
    </div>
  );
};
export default OrderCharts;
