import cx from 'classnames';
import './Header.scss';
import { ReactComponent as IconNotify } from '@/assets/icon-notify.svg';
import { useEffect, useRef, useState } from 'react';
import useStoreService from '@/hook/useStoreService';
import { useAuthContext } from '../authcheck/AuthCheck';
import { collection, onSnapshot, query, where } from 'firebase/firestore';
import { firestoreDB } from '@/ultils/firebase';
import { Link } from 'react-router-dom';
import { INotification } from '@/model/Models';

const Notification = () => {
  const { user } = useAuthContext();
  const { getNotifications, updateNotificationsStatus } = useStoreService();
  const [show, setShow] = useState<boolean>(false);
  const [list, setList] = useState<INotification[]>([]);
  const [totalNews, setTotalNews] = useState<number>(0);
  const isLoad = useRef<boolean>(false);

  const toogleNotification = () => {
    setShow(!show);
  };

  useEffect(() => {
    if (user?.id) {
      const fetch = async () => {
        const res = await getNotifications({
          userId: user?.id as string,
          currentPage: 1,
          pageSize: 20,
        });
        if (res.length !== 0) {
          setList(res);
        }
      };
      if (!isLoad.current) {
        isLoad.current = true;
        fetch();
      }
    }
  }, [user?.id]);

  useEffect(() => {
    if (user?.id) {
      const startTime = new Date();
      const q = query(
        collection(firestoreDB, 'notification'),
        where('status', '==', 'created'),
        where('userId', '==', user?.id),
        where('createdAt', '>', startTime),
      );
      const unsubscribe = onSnapshot(q, (snapshot) => {
        const newNotifications = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        })) as INotification[];
        setList((prev) => [...newNotifications, ...prev]);
      });

      return () => unsubscribe();
    }
  }, [user?.id]);

  useEffect(() => {
    const news = list.filter((item) => item.status === 'created');
    setTotalNews(news.length);
  }, [list]);

  useEffect(() => {
    if (show) {
      updateNotificationStatus();
    }
  }, [show]);

  const updateNotificationStatus = async () => {
    const news = list.filter((item) => item.status === 'created');
    if (news.length !== 0) {
      const notificationIds = news.map((item) => item.id);
      const status = 'seen';
      await updateNotificationsStatus({
        notificationIds: notificationIds,
        status: status,
      });
      setList((prev) =>
        prev.map((item) =>
          notificationIds.includes(item.id)
            ? { ...item, status: status }
            : item,
        ),
      );
    }
  };

  return (
    <div className='dropdown'>
      <div className='dropdown-toggle' onClick={toogleNotification}>
        <div
          className={cx(
            'header-action-item',
            'header-action-item-hoverable',
            'text-2xl',
          )}
        >
          <span className='badge-wrapper'>
            {totalNews !== 0 && (
              <span
                className='badge-dot badge-inner'
                style={{ top: '3px', right: '6px' }}
              ></span>
            )}
            <IconNotify />
          </span>
        </div>
      </div>
      <ul
        className='dropdown-menu bottom-end p-0 max-h-[600px] !min-w-[280px] !md:min-w-[340px]'
        style={{ display: show ? 'block' : 'none' }}
      >
        <li className='menu-item-header'>
          <div className='border-b border-gray-200 dark:border-gray-600 px-4 py-2 flex items-center justify-between'>
            <h6>Notifications</h6>
          </div>
        </li>
        <div className='overflow-y-auto'></div>
        {list &&
          list.map((item) => (
            <li
              key={item.id}
              className='px-4 py-2 cursor-pointer hover:bg-[rgba(0,0,0,0.4)] hover:text-white'
            >
              <Link
                to={`/${item.source}/${item.reference}`}
                onClick={() => {
                  setShow(false);
                }}
              >
                {item.message}
              </Link>
            </li>
          ))}
      </ul>
    </div>
  );
};
export default Notification;
