import { httpsCallable } from 'firebase/functions';
import { functions } from '../ultils/firebase';
import { IResponse } from '@/model/Responses';
import { ITicket, ITicketResponse } from '@/model/Models';
import {
  ICreateTicketParams,
  ICreateTicketResponseParams,
  IGetTicketsParams,
} from '@/model/Params';
import { IPagationResult } from '@/model/Types';

const useTicketService = () => {
  const createTicket = async (
    params: ICreateTicketParams,
  ): Promise<IResponse<ITicket>> => {
    const method = httpsCallable<ICreateTicketParams, IResponse<ITicket>>(
      functions,
      'Ticket_createTicket',
    );
    const res = await method(params);
    return res.data;
  };

  const createTicketResponse = async (
    params: ICreateTicketResponseParams,
  ): Promise<IResponse<ITicketResponse>> => {
    const method = httpsCallable<
      ICreateTicketResponseParams,
      IResponse<ITicketResponse>
    >(functions, 'Ticket_createTicketResponse');
    const res = await method(params);
    return res.data;
  };

  const updateTicket = async (params: Partial<ITicket>): Promise<IResponse> => {
    const method = httpsCallable<Partial<ITicket>, IResponse>(
      functions,
      'Ticket_updateTicket',
    );
    const res = await method(params);
    return res.data;
  };

  const getTicket = async (ticketId: string): Promise<IResponse<ITicket>> => {
    const method = httpsCallable<{ ticketId: string }, IResponse>(
      functions,
      'Ticket_getTicket',
    );
    const res = await method({ ticketId: ticketId });
    return res.data;
  };

  const getTicketBySquenceId = async (
    ticketSquenceId: number,
  ): Promise<IResponse<ITicket>> => {
    const method = httpsCallable<{ ticketSquenceId: number }, IResponse>(
      functions,
      'Ticket_getTicketBySquenceId',
    );
    const res = await method({ ticketSquenceId: ticketSquenceId });
    return res.data;
  };

  const getTicketResponseByTicketSquenceId = async (
    ticketSquenceId: number,
  ): Promise<IResponse<ITicketResponse[]>> => {
    const method = httpsCallable<
      { ticketSquenceId: number },
      IResponse<ITicketResponse[]>
    >(functions, 'Ticket_getTicketResponseByTicketSquenceId');
    const res = await method({ ticketSquenceId: ticketSquenceId });
    return res.data;
  };

  const getTickets = async (
    params: IGetTicketsParams,
  ): Promise<IPagationResult<ITicket>> => {
    const method = httpsCallable<IGetTicketsParams, IPagationResult<ITicket>>(
      functions,
      'Ticket_getTickets',
    );
    const res = await method(params);
    return res.data;
  };

  return {
    createTicket,
    createTicketResponse,
    updateTicket,
    getTicket,
    getTicketBySquenceId,
    getTicketResponseByTicketSquenceId,
    getTickets,
  };
};

export default useTicketService;
