import useLoading from '@/hook/useLoading';
import {
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { AuthContext } from '@/component/authcheck/AuthCheck';
import { useContext } from 'react';
import useCheckout from '@/hook/useCheckout';
import { useNavigate } from 'react-router-dom';

const PaymentMethodForm = ({ orderId }: { orderId?: string }) => {
  const stripe = useStripe();
  const elements = useElements();
  const nav = useNavigate();
  const { updatePaymentMethod } = useCheckout();
  const { user, setUser } = useContext(AuthContext);
  const { startLoading, stopLoading } = useLoading();

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!stripe || !elements) {
      return;
    }
    startLoading();
    const result = await stripe.confirmSetup({
      elements,
      confirmParams: {},
      redirect: 'if_required',
    });

    if (result.error) {
      console.log(result.error.message);
      stopLoading();
    } else {
      const setupIntent = result.setupIntent;
      const paymentMethodId = setupIntent.payment_method;
      if (user) {
        const res = await updatePaymentMethod(
          user.id,
          paymentMethodId as string,
        );
        if (res.status) {
          user.paymentMethodId = paymentMethodId as string;
          setUser(user);
          setTimeout(() => {
            if (orderId) {
              nav(`/checkout/${orderId}`);
            } else {
              nav('/account');
            }
          }, 200);
        }
      }
      stopLoading();
      alert('Payment method saved successfully!');
    }
  };

  return (
    <form onSubmit={handleSubmit} className='w-96'>
      <PaymentElement />
      <div className='flex'>
        <button
          className='btn submit mt-4 ml-auto'
          type='submit'
          disabled={!stripe || !elements}
        >
          Add
        </button>
      </div>
    </form>
  );
};

export default PaymentMethodForm;
