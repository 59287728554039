import React, { FC, useState, useEffect, useRef } from 'react';
import cx from 'classnames';
import Logo from '@/assets/logo.png';
import SocialSignIn from '../socialsignin/SocialSignIn';
import { Link, useNavigate } from 'react-router-dom';
import HR from '@/atom/hr/HR';
import useUser from '@/hook/useUser';
import useValidate from '@/hook/useValidate';
import useLoading from '@/hook/useLoading';
import { useAuthContext } from '../authcheck/AuthCheck';
interface IFormFields {
  email: string | null;
  password: string | null;
}
const defaultFormFields: IFormFields = {
  email: '',
  password: '',
};

const SignIn: React.FC = () => {
  const { startLoading, stopLoading } = useLoading();
  const { signInWithPassword } = useUser();
  const { validateEmail, validateRequired } = useValidate();
  const { authState } = useAuthContext();
  const nav = useNavigate();
  const [formState, setFormState] = useState<IFormFields>(defaultFormFields);
  const [errors, setErrors] = useState<IFormFields>(defaultFormFields);
  const clickedSubmit = useRef(false);
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormState({
      ...formState,
      [name]: value,
    });
  };
  useEffect(() => {
    if (clickedSubmit.current) {
      validateForm();
    }
  }, [formState]);

  useEffect(() => {
    if (authState === 'authenticated') {
      nav('/');
    }
  }, [authState]);

  const validateForm = () => {
    const errEmail = validateEmail(formState.email);
    const errPassword = validateRequired(formState.password);
    setErrors({
      email: errEmail,
      password: errPassword,
    });
    return !errEmail && !errPassword;
  };

  const signInWithEmailPasswordAction = (evt) => {
    evt.preventDefault();
    clickedSubmit.current = true;
    const valid = validateForm();
    console.log('valid', valid);
    if (valid) {
      startLoading();
      signInWithPassword(formState.email, formState.password).then((res) => {
        console.log('res', res);
        if (!res.status) {
          alert(res.error);
          stopLoading();
        } else {
          clickedSubmit.current = false;
          setFormState(defaultFormFields);
        }
      });
    }
  };

  return (
    <div className='use-default flex items-center justify-center h-screen bg-[#83afe2] text-[#444]'>
      <div className='p-8 shadow rounded-md w-full sm:w-[450px] bg-[#003059] text-white'>
        <div className='logo m-auto mb-4'>
          <img src={Logo} alt='logo' className='h-28 m-auto' />
        </div>
        <p className='font-bold text-xl my-1'>
          Welcome back to INSIGHT PULSE
        </p>
        <p className='text-sm'>
          Don't have an account?{' '}
          <Link to='/signup' className='font-semibold'>
            Create one
          </Link>
        </p>
        <div className='my-3'>
          <div className='flex flex-col mb-4'>
            <label className='mb-2'>Email</label>
            <input
              placeholder='Enter your email'
              className='input'
              id='email'
              name='email'
              type='text'
              required={true}
              value={formState.email || ''}
              onChange={handleInputChange}
              autoComplete={'off'}
            ></input>
            {errors.email && (
              <p className='text-red-500 text-xs mt-[5px]'>{errors.email}</p>
            )}
          </div>
          <div className='flex flex-col mb-6'>
            <div className='flex justify-between mb-2'>
              <label>Password</label>
              <Link to='/forgotpassword' className='text-sm'>
                Forgot your password?
              </Link>
            </div>
            <input
              placeholder='Enter your password'
              className='input'
              id='password'
              name='password'
              type='password'
              required={true}
              value={formState.password || ''}
              onChange={handleInputChange}
              autoComplete={'new-password'}
            ></input>
            {errors.password && (
              <p className='text-red-500 text-xs mt-[5px]'>{errors.password}</p>
            )}
          </div>
          <div className='flex'>
            <button
              className='w-full btn submit'
              onClick={signInWithEmailPasswordAction}
            >
              Sign In
            </button>
          </div>
        </div>
        <HR text={'Or'} classname={'my-6'} />
        <SocialSignIn />
      </div>
    </div>
  );
};
export default SignIn;
