import react from 'react';
import cx from 'classnames';

type HRProps = {
  text: string;
  classname: string;
};

const HR = ({ text, classname = '' }: HRProps) => {
  return (
    <div className={cx('relative', classname)}>
      <div className='absolute inset-0 flex items-center' aria-hidden='true'>
        <div className='w-full border-t border-gray-300'></div>
      </div>
      <div className='relative flex justify-center'>
        <span className='px-2 bg-white text-sm text-gray-500'>{text}</span>
      </div>
    </div>
  );
};

export default HR;
