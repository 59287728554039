import React from 'react';
import Logo from '@/assets/logo.png';
import LogoWhite from '@/assets/logo-white.png';
import { useDarkMode } from '@/context/DarkModeContext';
import { Link } from 'react-router-dom';

const Privacy = () => {
  const { darkMode } = useDarkMode();

  return (
    <div className='p-10'>
      <div>
        <Link className='pb-4' to={'/'}>
          <img
            src={darkMode ? Logo : Logo}
            alt='logo'
            className='h-16 w-auto m-auto'
          />
          <p className='text-center font-bold text-lg'>INSIGHT PULSE</p>
        </Link>
        <h3 className='font-bold text-center text-2xl'>
          Capability Management Inc
        </h3>
      </div>
      <div>
        <h4 className='text-xl font-semibold mb-4'>Privacy Policy</h4>
        <p className='mb-4'>Last updated: October 18, 2024<br/></p>
        <p>
          Capability Management, Inc. is committed to protecting the privacy of
          the personally identifiable information that is collected from the use
          of its software application (the “<Link to='/'>InsightPulse</Link>”). The scope of
          Capability Management’s commitment is detailed in this Privacy Policy.
        </p>

        <h5 className='text-lg font-semibold mt-6 mb-2'>
          Information Collection
        </h5>
        <p>
          Capability Management collects personally identifiable information
          (“Personal Info”) when users register on <Link to='/'>InsightPulse</Link>. This may
          include contact information (name, email addresses, and company name)
          and geographic location.
        </p>

        <h5 className='text-lg font-semibold mt-6 mb-2'>
          Use of Personal Information
        </h5>
        <p>
          Capability Management uses your Personal Info to distribute software
          information, fulfill requests, and review inquiries. Contact will be
          limited to email communication.
        </p>
        <p>
          Capability Management will not share, rent, sell, or disclose your
          Personal Info to any third parties without express permission, except
          as provided below.
        </p>

        <h5 className='text-lg font-semibold mt-6 mb-2'>Payment Information</h5>
        <p>
          Capability Management does not retain payment card details. We use
          Stripe.com to process orders securely, and they store your payment
          information.
        </p>

        <h5 className='text-lg font-semibold mt-6 mb-2'>
          Disclosure of Personal Information
        </h5>
        <p>
          We will not disclose your Personal Info to third parties except under
          the following conditions:
        </p>
        <ul className='list-disc ml-6'>
          <li>Required by law or regulations.</li>
          <li>To third parties who deliver services on our behalf.</li>
          <li>With your consent.</li>
          <li>To resolve claims of copyright or trademark infringement.</li>
        </ul>

        <h5 className='text-lg font-semibold mt-6 mb-2'>Third-Party Sharing</h5>
        <p>We may share your information with service providers, including:</p>
        <ul className='list-disc ml-6'>
          <li>Cloud computing services</li>
          <li>Data storage and analytics providers</li>
          <li>Conference organizers</li>
        </ul>
        <p>
          We may also share your personal information in business transfers such
          as mergers or sales.
        </p>

        <h5 className='text-lg font-semibold mt-6 mb-2'>
          Links to Third-Party Sites
        </h5>
        <p>
          Capability Management may provide links to third-party websites, but
          we are not responsible for the privacy practices of those sites.
        </p>

        <h5 className='text-lg font-semibold mt-6 mb-2'>
          Accessing and Deleting Information
        </h5>
        <p>
          You may access, update, or delete your Personal Info by contacting us
          at{' '}
          <a
            href='mailto:info@insightpulse.ai'
            className='text-blue-500 underline'
          >
            info@insightpulse.ai
          </a>
          .
        </p>

        <h5 className='text-lg font-semibold mt-6 mb-2'>Security</h5>
        <p>
          We use industry-standard measures to protect your Personal Info.
          However, we cannot guarantee complete security.
        </p>

        <h5 className='text-lg font-semibold mt-6 mb-2'>Children's Privacy</h5>
        <p>
          InsightPulse is not designed for children under 18, and we do not
          intentionally collect information from minors.
        </p>

        <h5 className='text-lg font-semibold mt-6 mb-2'>
          Transborder Hosting and Transfers
        </h5>
        <p>
          Personal Info may be transferred and stored outside of the European
          Economic Area (EEA). By using InsightPulse, you consent to such
          transfers.
        </p>

        <h5 className='text-lg font-semibold mt-6 mb-2'>Amendments</h5>
        <p>
          We may amend this policy at any time. Changes will be notified via
          updates on this page or by email.
        </p>

        <h5 className='text-lg font-semibold mt-6 mb-2'>Consent</h5>
        <p>
          By using InsightPulse, you consent to the collection and use of your
          information as described in this Privacy Policy.
        </p>

        <h5 className='text-lg font-semibold mt-6 mb-2'>Contact Us</h5>
        <p>
          If you have questions about this Privacy Policy, please contact us at{' '}
          <a
            href='mailto:info@insightpulse.ai'
            className='text-blue-500 underline'
          >
            info@insightpulse.ai
          </a>
          .
        </p>
        <h5 className='text-lg font-semibold mt-6 mb-2'>Visit</h5>
        <a href='https://insightpulse.ai'>insightpulse.ai</a>
      </div>
    </div>
  );
};

export default Privacy;
