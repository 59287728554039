import React, { useEffect, useState, useRef } from 'react';
import cx from 'classnames';
import ss from '../upload/Upload.module.scss';
import OrderError from './OrderError';
import OrderLog from './OrderLog';
import OrderTable from './OrderTable';
import OrderCharts from './OrderCharts';
import OrderDashboard from './OrderDashboard';
import useStoreService from '@/hook/useStoreService';
import useDataProcess from '@/hook/useDataProcess';
import useLoading from '@/hook/useLoading';
import { ISegment, ISegmentResponse } from '@/model/Types';
import { Link } from 'react-router-dom';
import { ChevronRightIcon } from '@heroicons/react/24/solid';
import OrderDownload from './OrderDownload';
import { useAuthContext } from '../authcheck/AuthCheck';
const OrderResult = ({ orderId }) => {
  console.log('orderId', orderId);
  const { user } = useAuthContext();
  const { getOrder } = useStoreService();
  const { analysis } = useDataProcess();
  const { startLoading, stopLoading } = useLoading();
  const [data, setData] = useState<ISegmentResponse>();
  const [segments, setSegments] = useState<ISegment[]>();
  const [buttonActive, setButtonActive] = useState<
    'result' | 'log' | 'chart' | 'noActive' | 'dashboard' | 'download'
  >('result');
  const preventReload = useRef(false);
  const [showFilters, setShowFilters] = useState<
    Record<string, { isShow: boolean; variable: string }>
  >({});
  const [order, setOrder] = useState<any>();
  const [title, setTitle] = useState<string>();
  const [fileNames, setfileNames] = useState<string[]>();

  const setButton = (status) => {
    setButtonActive(status);
  };

  const fetchOrder = async (orderId: string) => {
    startLoading();
    const res = await getOrder(orderId);
    if (res.status) {
      setTitle(res.data.fileNames.join(','));
      setfileNames(res.data.fileNames);
      setOrder(res.data);
    }
    stopLoading();
    if (res && res.status && res.data.status === 'completed') {
      const dt = analysis(res.data.data);
      if (!dt.status) return;
      console.log('dt', dt);
      if (dt.data.segments) {
        const initValues: Record<
          string,
          { isShow: boolean; variable: string }
        > = {};
        dt.data.segments.forEach((val) => {
          initValues[val.name] = { isShow: false, variable: '' };
        });
        setShowFilters(initValues);
        setSegments(dt.data.segments);
      }
      setData(dt.data);
    }
  };

  useEffect(() => {
    if (!preventReload.current) {
      preventReload.current = true;
      fetchOrder(orderId);
    }
    return () => {
      preventReload.current = false;
    };
  }, [orderId]);

  useEffect(() => {
    if (data && data.segments) {
      let segs: ISegment[] = [];
      Object.keys(showFilters).forEach((key) => {
        const filter = showFilters[key];
        const find = data.segments.filter((v) => v.name === key);
        const seg = {
          ...find[0],
        };
        if (filter.variable === '') {
          segs.push(seg);
        } else {
          segs.push(getFlatSeg(seg, parseInt(filter.variable)));
        }
      });
      setSegments(segs);
    }
  }, [showFilters]);

  const getFlatSeg = (val, top) => {
    let seg: ISegment = {
      ...val,
    };
    const segVal = seg.value;
    let topSeg: object = {};
    let values: { key: string; total: number }[] = [];
    Object.keys(segVal).forEach((key) => {
      if (typeof segVal[key] === 'number' || typeof segVal[key] === 'string') {
        values.push({ key: key, total: segVal[key] });
      } else {
        let kTotal = 0;
        Object.keys(segVal[key]).forEach((kkey) => {
          kTotal += segVal[key][kkey];
        });
        values.push({ key: key, total: kTotal });
      }
    });
    values = values
      .sort((a, b) => {
        return b.total - a.total;
      })
      .slice(0, top);
    values.forEach((item) => {
      topSeg[item.key] = segVal[item.key];
    });
    seg.value = topSeg;
    return seg;
  };

  return (
    <div className='container'>
      <p className='text-lg flex'>
        <Link className='font-bold' to='/orders'>
          Order
        </Link>{' '}
        <ChevronRightIcon width={20} /> {title}
      </p>
      <p className='my-4'>
        <span className='font-bold'>Stakeholder Category:</span>{' '}
        {order && <span className='capitalize'>{order.orderType}</span>}
      </p>
      <div className={ss['wrapper-result']}>
        <div className={ss['wrapper-btns']}>
          <div
            className={cx(ss['button'], {
              [ss['active']]: buttonActive === 'result',
            })}
            onClick={setButton.bind(this, 'result')}
          >
            Result
          </div>
          <div
            className={cx(ss['button'], {
              [ss['active']]: buttonActive === 'chart',
            })}
            onClick={setButton.bind(this, 'chart')}
          >
            Chart
          </div>
          <div
            className={cx(ss['button'], {
              [ss['active']]: buttonActive === 'dashboard',
            })}
            onClick={setButton.bind(this, 'dashboard')}
          >
            Dashboard
          </div>
          <div
            className={cx(ss['button'], {
              [ss['active']]: buttonActive === 'download',
            })}
            onClick={setButton.bind(this, 'download')}
          >
            Download
          </div>
          {user && user.role === 'SuperAdmin' && (
            <div
              className={cx(ss['button'], {
                [ss['active']]: buttonActive === 'log',
              })}
              onClick={setButton.bind(this, 'log')}
            >
              Log
            </div>
          )}
        </div>
        <div className={ss['wrapper-body']}>
          {data && data.error.length !== 0 && <OrderError error={data.error} />}
          {data && data.error.length === 0 && (
            <>
              {buttonActive === 'result' && segments && (
                <OrderTable
                  segments={segments}
                  showFilters={showFilters}
                  setShowFilters={setShowFilters}
                />
              )}
              {buttonActive === 'log' && data && <OrderLog log={data.raw} />}
              {buttonActive === 'chart' && segments && (
                <OrderCharts
                  segments={segments}
                  showFilters={showFilters}
                  setShowFilters={setShowFilters}
                />
              )}
              {buttonActive === 'dashboard' && segments && (
                <OrderDashboard
                  segments={segments}
                  showFilters={showFilters}
                  setShowFilters={setShowFilters}
                />
              )}
              {buttonActive === 'download' && segments && (
                <OrderDownload segments={segments} fileNames={fileNames} />
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};
export default OrderResult;
