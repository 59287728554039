import useCheckout from '@/hook/useCheckout';
import useLoading from '@/hook/useLoading';
import { ICheckout } from '@/model/Models';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import CheckoutForm from './CheckoutForm';
import { useAuthContext } from '../authcheck/AuthCheck';
import moment from 'moment';

console.log(
  'process.env.REACT_APP_STRIPE_PUBLIC_KEY',
  process.env.REACT_APP_STRIPE_PUBLIC_KEY,
);
const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLIC_KEY as string,
);

const Checkout = () => {
  const { id } = useParams();
  const { user } = useAuthContext();
  const { startLoading, stopLoading } = useLoading();
  const { createPaymentIntent } = useCheckout();
  const { getCheckoutOrder } = useCheckout();
  const [order, setOrder] = useState<ICheckout>();
  const [clientSecret, setClientSecret] = useState<string | null>(null);

  const fetchOrder = async (id: string) => {
    startLoading();
    const res = await getCheckoutOrder(id);
    stopLoading();
    console.log(res);
    if (res.status) {
      setOrder(res.data);
      const paymentIntent = await createPaymentIntent(
        user?.paymentMethodId as string,
        user?.stripeCustomerId as string,
        res.data.totalCost,
        'usd',
      );
      if (paymentIntent.status) {
        setClientSecret(paymentIntent.data.clientSecret);
      }
    }
  };

  useEffect(() => {
    if (id) {
      fetchOrder(id);
    }
  }, [id]);

  return (
    <div className='p-4'>
      <h1 className='text-lg font-bold mb-2'>Order Summary</h1>
      <div className='flex gap-8 border-t pt-4'>
        {order && (
          <div className='border p-4 rounded-lg'>
            <div className='flex gap-8 justify-between'>
              <span className='font-bold min-w-[100px]'>Order Id</span>
              <span>{order.squenceId}</span>
            </div>
            <div className='flex gap-8 justify-between'>
              <span className='font-bold min-w-[100px]'>Order date</span>
              <span>
                {order.createdAt &&
                  moment(
                    new Date(
                      (order.createdAt as any)._seconds * 1000 +
                        (order.createdAt as any)._nanoseconds / 1000000,
                    ),
                  ).format('DD-MMM-YYYY hh:mm')}
              </span>
            </div>
            <div className='flex gap-8 justify-between'>
              <span className='font-bold min-w-[100px]'>File name</span>
              <span>{order.fileNames.map((item) => item)}</span>
            </div>
            <div className='flex gap-8 justify-between'>
              <span className='font-bold min-w-[100px]'>Total before tax</span>
              <span>{order.cost} $</span>
            </div>
            <div className='flex gap-8 justify-between'>
              <span className='font-bold min-w-[100px]'>
                Estimated tax to be collected
              </span>
              <span>{Math.round(order.cost * order.tax) / 100} $</span>
            </div>
            <div className='flex gap-8 justify-between'>
              <span className='font-bold min-w-[100px]'>Order total</span>
              <span>{order.totalCost} $</span>
            </div>
            {clientSecret && (
              <Elements stripe={stripePromise} options={{ clientSecret }}>
                <CheckoutForm
                  order={order}
                  clientSecret={clientSecret}
                  paymentMethodId={user?.paymentMethodId as string}
                />
              </Elements>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
export default Checkout;
