import useLoading from '@/hook/useLoading';
import useTicketService from '@/hook/useTicketService';
import { ITicket, ITicketResponse } from '@/model/Models';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import SubmitTicketResponse from './SubmitTicketResponse';
import { IResponse } from '@/model/Responses';
import { useAuthContext } from '../authcheck/AuthCheck';
import TicketReponseDetail from './TicketReponseDetail';
import moment from 'moment';

const TicketDetail = () => {
  const { ticketId } = useParams();
  const { user } = useAuthContext();
  const {
    getTicket,
    createTicketResponse,
    updateTicket,
    getTicketResponseByTicketSquenceId,
  } = useTicketService();
  const { startLoading, stopLoading } = useLoading();
  const [ticket, setTicket] = useState<ITicket>();
  const [ticketReponses, setTicketReponses] = useState<ITicketResponse[]>([]);

  const fetchTicket = async (ticketId: string) => {
    startLoading();
    const res = await getTicket(ticketId);
    console.log('fetchTicket', res);
    if (res.status) {
      setTicket(res.data);
      const resResponse = await getTicketResponseByTicketSquenceId(
        res.data.squenceId,
      );
      if (resResponse.status) {
        setTicketReponses([...resResponse.data]);
      }
    }
    stopLoading();
  };

  useEffect(() => {
    if (ticketId) {
      fetchTicket(ticketId);
    }
  }, [ticketId]);

  const addResponse = async (
    response: string,
    isClose: boolean,
  ): Promise<IResponse<boolean>> => {
    startLoading(
      'InsightPulse is processing your request, thank you for your patience',
    );
    const res = await createTicketResponse({
      ticketSquenceId: ticket!.squenceId,
      userSquenceId: user!.squenceId,
      comment: response,
      role: user!.role || '',
    });
    stopLoading();
    if (!res.status) {
      return {
        status: false,
        error: res.error,
      };
    } else {
      setTicketReponses((prev) => [...prev, res.data]);
    }
    if (isClose) {
      const resUp = await updateTicket({
        id: ticket?.id,
        status: 'Closed',
      });
      if (!resUp.status) {
        return {
          status: false,
          error: resUp.error,
        };
      } else {
        setTicket((prev) => {
          if (prev) {
            return {
              ...prev,
              status: 'Closed',
            };
          } else {
            return prev;
          }
        });
      }
    }

    return {
      status: true,
      data: true,
    };
  };

  return (
    <div className='container'>
      <p className='text-center text-xl font-bold'>Request Details</p>
      <p className='text-lg flex font-bold'>
        Request Number: {ticket?.squenceId}
      </p>
      {ticket && (
        <div>
          <div className='my-2 p-4 border rounded'>
            <div className='flex flex-wrap justify-between'>
              <p>
                <span className='font-bold'>Account:</span>{' '}
                {ticket.userRole === 'SuperAdmin' || ticket.userRole === 'Admin'
                  ? 'InsightPulse Team'
                  : ticket.userFirstName}
              </p>
              <p>
                <span className='font-bold'>OrderId:</span>{' '}
                {ticket.orderSquenceId}
              </p>
              <p>
                <span className='font-bold'>Status:</span> {ticket.status}
              </p>
            </div>
            <p>
              <span className='font-bold'>Date:</span>{' '}
              {ticket.createdAt &&
                moment(
                  new Date(
                    (ticket.createdAt as any)._seconds * 1000 +
                      (ticket.createdAt as any)._nanoseconds / 1000000,
                  ),
                ).format('DD-MMM-YYYY hh:mm')}
            </p>
            <p>
              <span className='font-bold'>Comment:</span> {ticket.comment}
            </p>
          </div>
          {ticketReponses &&
            ticketReponses
              .sort((a, b) => a.squenceId - b.squenceId)
              .map((item) => (
                <TicketReponseDetail key={item.id} ticketReponse={item} />
              ))}
          {ticket.status === 'Process' && (
            <SubmitTicketResponse
              ticketSquenceId={ticket.squenceId}
              addResponse={addResponse}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default TicketDetail;
