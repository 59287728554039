import React from 'react';
import { Chart, BarElement, Title, CategoryScale, LinearScale } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import cx from 'classnames';
import ss from './PieChart.module.css';
import FilterButton from './FilterButton';

const myPlugin = {
  id: 'showAfterBar',
  afterDraw: (chart) => {
    const ctx = chart.ctx;
    chart.data.datasets.forEach((dataset, i) => {
      const meta = chart.getDatasetMeta(i);
      if (!meta.hidden) {
        meta.data.forEach((element, index) => {
          // Draw text at the end of each bar
          ctx.fillStyle = 'black'; // Text color
          const fontSize = 16; // Font size
          const fontStyle = 'normal';
          const fontFamily = 'Helvetica Neue';
          ctx.font = `${fontStyle} ${fontSize}px ${fontFamily}`;
          // Get the data to display as the label
          const dataString = dataset.data[index].toString();
          // Calculate the position to draw the text
          ctx.textAlign = 'center';
          ctx.textBaseline = 'bottom';
          const padding = 5; // Padding from the end of the bar
          const position = element.tooltipPosition();
          ctx.fillText(dataString, position.x + 12, position.y - padding + 12);
        });
      }
    });
  },
};

//Chart.register(BarElement, CategoryScale, LinearScale, Title, myPlugin);
type BarChartProps = {
  data: any;
  title: string;
  width?: string;
  height?: string;
  maxWidth?: string;
  showFilters?: Record<string, { isShow: boolean; variable: string }>;
  setShowFilters?: React.Dispatch<
    React.SetStateAction<Record<string, { isShow: boolean; variable: string }>>
  >;
};
const BarChart = ({
  data,
  title,
  width,
  maxWidth,
  height,
  showFilters,
  setShowFilters,
}: BarChartProps) => {
  let labels: string[] = [];
  let values: number[] = [];
  let dataValue = data.value;
  let arr: { name: string; value: number }[] = [];
  Object.keys(dataValue).forEach((key) => {
    let obj = { name: key, value: 0 };
    if (typeof dataValue[key] === 'object') {
      const child = dataValue[key];
      let sum = 0;
      Object.keys(child).forEach((key) => {
        sum += parseInt(child[key]);
      });
      obj.value = sum;
    } else {
      obj.value = parseInt(dataValue[key]);
    }
    arr.push(obj);
  });
  arr = arr.sort((a, b) => b.value - a.value);
  labels = arr.map((item) => {
    return item.name.charAt(0).toUpperCase() + item.name.slice(1);
  });
  values = arr.map((item) => item.value);
  const max = Math.max(...values) + Math.round(Math.max(...values) * 0.1) + 1;
  const dt = {
    labels: labels,
    datasets: [
      {
        label: title,
        data: values,
        backgroundColor: '#4673c2',
        borderWidth: 1,
        barThickness: 20,
      },
    ],
  };
  let autoHeight = values.length * 26;
  if (autoHeight < 130) autoHeight = 130;
  const options = {
    indexAxis: 'y' as const,
    maintainAspectRatio: false,
    scales: {
      x: {
        beginAtZero: true,
        display: false,
        suggestedMax: max,
      },
      y: {
        beginAtZero: true,
        grid: {
          display: false,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };
  return (
    <div style={{ paddingBottom: '50px' }}>
      <div
        style={{
          width: width ? width : '600px',
          maxWidth: maxWidth ? maxWidth : '',
          height: `${autoHeight}px`,
        }}
      >
        <div className={ss['chartTitleWrapper']}>
          <p className={ss['chartTitle']}>{title}</p>
          {showFilters && (
            <FilterButton
              data={data}
              showFilters={showFilters}
              setShowFilters={setShowFilters}
            />
          )}
        </div>
        <Bar data={dt} options={options} plugins={[myPlugin]} />
      </div>
    </div>
  );
};

export default BarChart;
