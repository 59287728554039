import { QuestionMarkCircleIcon } from '@heroicons/react/24/solid';

const Faq = () => {
  return (
    <div className='container'>
      <p className='text-lg flex font-bold'>Frequently Asked Questions</p>
      <div className='mt-4'>
        <p className='flex font-bold gap-2'>
          <QuestionMarkCircleIcon width={24} color='#003059' />
          <span>What happens to the file after it is uploaded?</span>
        </p>
        <p>
          Once you upload the file, InsightPulse processes the data, counting
          the number of rows and tokens. The file itself is not stored on the
          server; only the data within the file is accessed to generate feedback
          insights.
        </p>
      </div>
      <div className='mt-4'>
        <p className='flex font-bold gap-2'>
          <QuestionMarkCircleIcon width={24} color='#003059' />
          <span>How does Order processing work?</span>
        </p>
        <p>
          After the data from the file has been accessed and you've selected a
          stakeholder category, you are presented with two options for
          processing the order.
        </p>
        <p>
          First, you can choose to place the order immediately. In this
          scenario, the order will begin processing right away, but you’ll need
          to wait until the process is completed before you can proceed further.
          This option is useful if you need the order to be handled urgently and
          are prepared to monitor its progress directly.
        </p>
        <p>
          Alternatively, you can opt to place the order in the queue. By
          selecting this option, the order will be processed in turn, and once
          it is completed, you’ll receive a notification both via email and
          through the notification icon within the platform. This approach
          allows you to continue with other tasks without needing to monitor the
          order's status closely, as you’ll be alerted once it’s finished.
        </p>
        <p>
          Each option provides flexibility depending on your immediate needs and
          the level of urgency for processing the order.
        </p>
      </div>
      <div className='mt-4'>
        <p className='flex font-bold gap-2'>
          <QuestionMarkCircleIcon width={24} color='#003059' />
          <span>How is the cost for an order calculated?</span>
        </p>
        <p>
          Since we charge per token, with tokens being segments of words (1,000
          tokens roughly equating to 750 words), the cost is based on the total
          number of tokens. For example, if there are 8,000 tokens and the
          current rate is $1.00 per 1,000 tokens, the total cost of the order
          would be 8 times $1.00, which equals $8.00.
        </p>
      </div>
      <div className='mt-4'>
        <p className='flex font-bold gap-2'>
          <QuestionMarkCircleIcon width={24} color='#003059' />
          <span>How can I close my account?</span>
        </p>
        <p>
          To close your account, please click on the ‘Account’ link and from the
          account Page, select on ‘Close your account’ option. You can also
          click on Close your account link to directly go to the page.
        </p>
      </div>
      <div className='mt-4'>
        <p className='flex font-bold gap-2'>
          <QuestionMarkCircleIcon width={24} color='#003059' />
          <span>How to download my data?</span>
        </p>
        <p>To download the processed result, follow these steps</p>
        <ul className='pl-5'>
          <li>1. Click on the ‘History’ link in the left menu.</li>
          <li>
            2. Find the order number for the result you want and click on
            ‘Detail’ under the Result column.
          </li>
          <li>3. On the Order page, go to the ‘Download’ tab.</li>
          <li>
            4. Choose the desired file format and click the ‘Download’ button.
          </li>
        </ul>
      </div>
      <div className='mt-4'>
        <p className='flex font-bold gap-2'>
          <QuestionMarkCircleIcon width={24} color='#003059' />
          <span>How to add a new member to my account?</span>
        </p>
        <p>To add a new member, follow these steps:</p>
        <ul className='pl-5'>
          <li>1. Click on the ‘Account’ link in the left menu.</li>
          <li>2. Select ‘Manage your members’.</li>
          <li>3. Click on the ‘+ Add’ link.</li>
          <li>
            4. Enter the member’s information, including Last Name, First Name,
            and email address, then select the role and click save.
          </li>
        </ul>
        <p>
          The system will automatically send an email to the new member with a
          registration link. A member with the Manager role will have access to
          all features, while a member with the Analyst role will not be able to
          perform actions on the Account page.
        </p>
      </div>
      <div className='mt-4'>
        <p className='flex font-bold gap-2'>
          <QuestionMarkCircleIcon width={24} color='#003059' />
          <span>How to remove a member?</span>
        </p>
        <p>To remove a member, follow these steps:</p>
        <ul className='pl-5'>
          <li>1. Click on the ‘Account’ link in the left menu.</li>
          <li>2. Select ‘Manage your members’.</li>
          <li>3. Click on the ‘Remove’ link.</li>
        </ul>
        <p>
          A popup will appear to confirm the deletion. Click ‘OK’ to remove the
          user from the account.
        </p>
      </div>
      <div className='mt-4'>
        <p className='flex font-bold gap-2'>
          <QuestionMarkCircleIcon width={24} color='#003059' />
          <span>What happens after I close my account?</span>
        </p>
        <p>
          Account closure is a permanent action and once your account is closed
          it will no longer be available to you and cannot be restored. If you
          decide later that you would like to use our services again, you will
          need to create a new account.
        </p>
      </div>
      <div className='mt-4'>
        <p className='flex font-bold gap-2'>
          <QuestionMarkCircleIcon width={24} color='#003059' />
          <span>How to get help and interact with the support? </span>
        </p>
        <p>
          The 'Contact Us' feature lets you ask questions and receive support.
          You can also provide feedback on the app and request new features from
          this page.
        </p>
      </div>
    </div>
  );
};
export default Faq;
