import { ITicketResponse } from '@/model/Models';
import moment from 'moment';

const TicketReponseDetail = ({
  ticketReponse,
}: {
  ticketReponse: ITicketResponse;
}) => {
  return (
    <div className='my-2 p-4 border rounded'>
      <div className='flex flex-wrap justify-between'>
        <p>
          <span className='font-bold'>Account:</span>{' '}
          {ticketReponse.userRole === 'SuperAdmin' ||
          ticketReponse.userRole === 'Admin'
            ? 'InsightPulse Team'
            : ticketReponse.userFirstName}
        </p>
      </div>
      <p>
        <span className='font-bold'>Date:</span>{' '}
        {ticketReponse.createdAt &&
          moment(
            new Date(
              (ticketReponse.createdAt as any)._seconds * 1000 +
                (ticketReponse.createdAt as any)._nanoseconds / 1000000,
            ),
          ).format('DD-MMM-YYYY hh:mm')}
      </p>
      <p>
        <span className='font-bold'>Comment:</span> {ticketReponse.comment}
      </p>
    </div>
  );
};

export default TicketReponseDetail;
