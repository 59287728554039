import Button from '@/atom/controls/Button';
import Error from '@/atom/controls/Error';
import Label from '@/atom/controls/Label';
import Select from '@/atom/controls/Select';
import { ChevronRightIcon } from '@heroicons/react/24/solid';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuthContext } from '../authcheck/AuthCheck';
import useValidate from '@/hook/useValidate';
import useLoading from '@/hook/useLoading';
import useUser from '@/hook/useUser';

interface IFormFields {
  reason: string | null;
  feedback?: string | null;
  agree: string | null;
}
const defaultFormFields: IFormFields = {
  reason: '',
  feedback: '',
  agree: '',
};

const CloseAccount = () => {
  const { user } = useAuthContext();
  const { validateRequired } = useValidate();
  const { startLoading, stopLoading } = useLoading();
  const { closeAccount } = useUser();
  const nav = useNavigate();
  const [formState, setFormState] = useState<IFormFields>({
    ...defaultFormFields,
  });
  const [errors, setErrors] = useState<IFormFields>({
    ...defaultFormFields,
  });

  const handleInputChange = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >,
  ) => {
    const { name, value } = event.target;
    setFormState({
      ...formState,
      [name]: value,
    });
  };
  const handleCheckInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { name, checked } = event.target;
    setFormState({
      ...formState,
      [name]: checked,
    });
  };
  const validateForm = () => {
    const reason = validateRequired(formState.reason, 'Please select reason');
    const agree = validateRequired(
      formState.agree,
      'Please checked on checkbox',
    );
    setErrors({
      reason: reason,
      agree: agree,
    });
    return !reason && !agree;
  };

  const deleteClick = async (event) => {
    event.preventDefault();
    const valid = validateForm();
    if (valid) {
      startLoading();
      const params = {
        userId: user?.id as string,
        log: {
          ...user,
          reason: formState.reason,
          feedback: formState.feedback,
        },
      };
      await closeAccount(params);
      stopLoading();
      nav('/signout');
    }
  };

  return (
    <div className='container'>
      <p className='text-lg flex'>
        <Link className='font-bold' to='/account'>
          Your Account
        </Link>{' '}
        <ChevronRightIcon width={20} /> Close your account
      </p>
      <div className='mt-4'>
        <h1 className='text-2xl font-bold mb-2'>Please read this carefully</h1>
        <p className='text-lg mb-2'>
          You are about to submit a request for us to permanently close your
          account and delete your data. Once your account is closed all of the
          services accessed through your account will no longer be available to
          you.
        </p>
        <p className='text-lg mb-2'>
          Please note account closure is a permanent action and once your
          account is closed it will no longer be available to you and cannot be
          restored. If you decide later that you would like to our services
          again , you will need to create a new account.
        </p>
        <div className='mt-4 flex flex-col w-80'>
          <Label className='text-lg'>Choose Reason</Label>
          <Select
            className='mt-2 w-80'
            name='reason'
            value={formState.reason || ''}
            onChange={handleInputChange}
          >
            <option value='' disabled hidden>
              Select a reason
            </option>
            <option value={'My job is done'}>My job is done</option>
            <option value={'Security & Privacy concern'}>
              Security & Privacy concern
            </option>
            <option value={'My issue wad not resolved on time'}>
              My issue was not resolved on time
            </option>
            <option value={'No Reason'}>No reason</option>
          </Select>
          {errors.reason && <Error message={errors.reason} />}
        </div>
        <div className='mt-4 flex flex-col'>
          <Label className='text-lg'>Feedback</Label>
          <textarea
            className='mt-2 w-96 h-36 border resize-none'
            name='feedback'
            value={formState.feedback || ''}
            onChange={handleInputChange}
          ></textarea>
        </div>
        <div className='mt-4 flex flex-col'>
          <Label className='text-lg'>
            <input
              type='checkbox'
              name='agree'
              value={formState.agree || ''}
              onChange={handleCheckInputChange}
            ></input>
            <span className='ml-2'>
              Yes, I want to permanently close my Account and delete my data.
            </span>
          </Label>
          {errors.agree && <Error message={errors.agree} />}
        </div>
        <div className='mt-8'>
          <Button className='submit w-60' onClick={deleteClick}>
            Close My Account
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CloseAccount;
