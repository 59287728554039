import React, { FC } from 'react';
import cx from 'classnames';
import ss from './FilterButton.module.css';
import { ISegment } from '../model/Types';

type FilterButtonProps = {
  data: ISegment;
  showFilters?: Record<string, { isShow: boolean; variable: string }>;
  setShowFilters?: React.Dispatch<
    React.SetStateAction<Record<string, { isShow: boolean; variable: string }>>
  >;
  showFilterText?: boolean;
  showFilterClear?: boolean;
};

const FilterButton: FC<FilterButtonProps> = ({
  data,
  showFilters,
  setShowFilters,
  showFilterText = true,
  showFilterClear = true,
}) => {
  const val = data;
  const toggleFilter = (val) => {
    setShowFilters &&
      setShowFilters((prev) => {
        Object.keys(prev).forEach((key) => {
          if (key !== val.name) {
            prev[key].isShow = false;
          }
        });
        return {
          ...prev,
          [val.name]: {
            ...prev[val.name],
            isShow: !prev[val.name].isShow,
          },
        };
      });
  };

  const selectFilter = (val, top) => {
    setShowFilters &&
      setShowFilters((prev) => ({
        ...prev,
        [val.name]: {
          isShow: false,
          variable: prev[val.name].variable === top ? '' : top,
        },
      }));
  };

  const clearFilter = (val) => {
    setShowFilters &&
      setShowFilters((prev) => ({
        ...prev,
        [val.name]: {
          isShow: false,
          variable: '',
        },
      }));
  };

  if (val.hasFilter && showFilters && showFilters[val.name]) {
    return (
      <div className={cx(ss['filter-btn-wrapper'])}>
        <div
          className={cx(ss['filter-btn'], {
            [ss['active']]:
              showFilters[val.name] && showFilters[val.name].variable !== '',
          })}
          onClick={() => toggleFilter(val)}
        >
          {showFilterText && showFilters[val.name].variable !== '' && (
            <span className={ss['filter-text']}>{`Top ${
              showFilters[val.name].variable
            } ${val.name}`}</span>
          )}
          <i
            className={cx('fa', 'fa-filter', ss['filter-icon'])}
            aria-hidden='true'
          ></i>
          {showFilterClear && showFilters[val.name].variable !== '' && (
            <i
              className={cx('fa fa-times', ss['filter-reset'])}
              aria-hidden='true'
              onClick={(event) => {
                event.stopPropagation();
                clearFilter(val);
              }}
            ></i>
          )}
        </div>
        {showFilters[val.name] && showFilters[val.name].isShow && (
          <ul className={ss['filter-options']}>
            <li
              onClick={() => selectFilter(val, '3')}
              className={cx({
                [ss['active']]: showFilters[val.name].variable === '3',
              })}
            >{`Top 3 ${val.name}`}</li>
            <li
              onClick={() => selectFilter(val, '5')}
              className={cx({
                [ss['active']]: showFilters[val.name].variable === '5',
              })}
            >{`Top 5 ${val.name}`}</li>
            {Object.keys(val.value).length > 10 && (
              <li
                onClick={() => selectFilter(val, '10')}
                className={cx({
                  [ss['active']]: showFilters[val.name].variable === '10',
                })}
              >{`Top 10 ${val.name}`}</li>
            )}
          </ul>
        )}
      </div>
    );
  } else {
    return null;
  }
};
export default FilterButton;
