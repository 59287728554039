import { httpsCallable } from 'firebase/functions';
import { functions } from '../ultils/firebase';
import { IResponse } from '@/model/Responses';
import { IModel, IPriceModel } from '@/model/Models';

const usePrice = () => {
  const getModelByName = async (name: string): Promise<IResponse<IModel>> => {
    const method = httpsCallable<{ name: string }, IResponse<IModel>>(
      functions,
      'Model_getModelByName',
    );
    const res = await method({ name: name });
    return res.data;
  };

  const getModels = async (): Promise<IResponse<IModel[]>> => {
    const method = httpsCallable<{}, IResponse<IModel[]>>(
      functions,
      'Model_getModels',
    );
    const res = await method({});
    return res.data;
  };

  const getPrices = async (
    currentPage: number,
    pageSize: number,
  ): Promise<IPriceModel[]> => {
    const method = httpsCallable<
      { currentPage: number; pageSize: number },
      IPriceModel[]
    >(functions, 'Price_getPrices');
    const res = await method({ currentPage: currentPage, pageSize: pageSize });
    return res.data;
  };

  const activePriceById = async (
    id: string,
  ): Promise<IResponse<IPriceModel>> => {
    const method = httpsCallable<{ id: string }, IResponse<IPriceModel>>(
      functions,
      'Price_activePriceById',
    );
    const res = await method({ id: id });
    return res.data;
  };

  const createAndActive = async (
    price: IPriceModel,
  ): Promise<IResponse<IPriceModel>> => {
    const method = httpsCallable<
      { price: IPriceModel },
      IResponse<IPriceModel>
    >(functions, 'Price_createAndActive');
    const res = await method({ price: price });
    return res.data;
  };

  return {
    getModelByName,
    getModels,
    getPrices,
    activePriceById,
    createAndActive,
  };
};

export default usePrice;
