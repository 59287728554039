import React, { useState, useRef, useEffect } from 'react';
import cx from 'classnames';
import Logo from '@/assets/logo.png';
import SocialSignIn from '../socialsignin/SocialSignIn';
import HR from '@/atom/hr/HR';
import { Link, useNavigate } from 'react-router-dom';
import useValidate from '@/hook/useValidate';
import useUser from '@/hook/useUser';
import useLoading from '@/hook/useLoading';
import { IAccountType } from '@/model/Types';
import Label from '@/atom/controls/Label';
import Input from '@/atom/controls/Input';
import Error from '@/atom/controls/Error';
import Button from '@/atom/controls/Button';
import { IUser } from '@/model/Models';

interface IFormFields {
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  password: string | null;
  confirmPassword: string | null;
  type?: IAccountType | null;
  companyName?: string | null;
}
const defaultFormFields: IFormFields = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  confirmPassword: '',
  type: 'Personal',
  companyName: '',
};

const SignUp: React.FC = () => {
  const nav = useNavigate();
  const { startLoading, stopLoading } = useLoading();
  const {
    validateEmail,
    validatePassword,
    validateRequired,
    validateConfirmPassword,
  } = useValidate();
  const { signUpWithEmailAndPassword } = useUser();
  const [formState, setFormState] = useState<IFormFields>(defaultFormFields);
  const [errors, setErrors] = useState<IFormFields>(defaultFormFields);
  const clickedSubmit = useRef(false);
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormState({
      ...formState,
      [name]: value,
    });
  };
  useEffect(() => {
    if (clickedSubmit.current) {
      validateForm();
    }
  }, [formState]);
  const validateForm = () => {
    const errFirstName = validateRequired(formState.firstName);
    const errLastName = validateRequired(formState.lastName);
    const errEmail = validateEmail(formState.email);
    const errPassword = validatePassword(formState.password);
    const errPasswordConfirm = !validatePassword(formState.confirmPassword)
      ? validateConfirmPassword(formState.password, formState.confirmPassword)
      : validatePassword(formState.confirmPassword);
    const errCompanyName =
      formState.type === 'Company'
        ? validateRequired(formState.companyName)
        : null;
    setErrors({
      firstName: errFirstName,
      lastName: errLastName,
      email: errEmail,
      password: errPassword,
      confirmPassword: errPasswordConfirm,
      companyName: errCompanyName,
    });
    return (
      !errFirstName &&
      !errLastName &&
      !errEmail &&
      !errPassword &&
      !errPasswordConfirm &&
      !errCompanyName
    );
  };
  const signUpWithEmailPasswordAction = (evt) => {
    evt.preventDefault();
    clickedSubmit.current = true;
    const valid = validateForm();
    console.log('valid', valid);
    if (valid) {
      startLoading();
      const user: IUser = {
        id: '',
        squenceId: 0,
        firstName: formState.firstName,
        lastName: formState.lastName,
        email: formState.email as string,
        password: formState.password as string,
        type: formState.type as IAccountType,
        companyName: formState.companyName,
      };
      console.log('user', user);
      signUpWithEmailAndPassword(user).then((res) => {
        stopLoading();
        console.log('res', res);
        if (res.status) {
          nav('/login');
        } else {
          alert(res.message);
        }
      });
    }
  };
  return (
    <div className='use-default flex items-center justify-center h-screen bg-[#83afe2] text-[#444]'>
      <div className='p-4 shadow rounded-md w-full sm:w-[450px] bg-[#003059] text-white'>
        <div className='logo mb-4'>
          <img src={Logo} alt='logo' className='h-28 m-auto' />
        </div>
        <p className='font-bold text-xl my-1'>Create your Account</p>
        <p className='text-sm'>Some thing information</p>
        <div className='my-3'>
          <div className='flex justify-between items-center mb-2 mt-2'>
            <Label>Organization</Label>
            <div className='flex items-center ml-auto'>
              <label className='text-lg'>
                <input
                  type='radio'
                  name='type'
                  value='Personal'
                  checked={formState.type === 'Personal'}
                  onChange={handleInputChange}
                />
                <span className='ml-1'>Personal</span>
              </label>
              <label className='text-lg ml-4'>
                <input
                  type='radio'
                  name='type'
                  value='Company'
                  checked={formState.type === 'Company'}
                  onChange={handleInputChange}
                />
                <span className='ml-1'>Company</span>
              </label>
            </div>
          </div>
          {formState.type === 'Company' && (
            <div className='flex flex-col mb-2'>
              <Label>Company name</Label>
              <Input
                placeholder='Enter your company name'
                id='companyName'
                name='companyName'
                type='text'
                required={true}
                value={formState.companyName || ''}
                onChange={handleInputChange}
              />
              {errors.companyName && <Error message={errors.companyName} />}
            </div>
          )}
          <div className='flex mb-2'>
            <div className='mr-1'>
              <Label>First name</Label>
              <Input
                placeholder='Enter your first name'
                id='firstName'
                name='firstName'
                type='text'
                required={true}
                value={formState.firstName || ''}
                onChange={handleInputChange}
              ></Input>
              <Error message={errors.firstName} />
            </div>
            <div className='ml-1'>
              <Label>Last name</Label>
              <Input
                placeholder='Enter your last name'
                id='lastName'
                name='lastName'
                type='text'
                required={true}
                value={formState.lastName || ''}
                onChange={handleInputChange}
              ></Input>
              <Error message={errors.lastName} />
            </div>
          </div>
          <div className='flex flex-col mb-2'>
            <Label>Email</Label>
            <Input
              placeholder='Enter your email'
              id='email'
              name='email'
              type='text'
              required={true}
              value={formState.email || ''}
              onChange={handleInputChange}
            />
            <Error message={errors.email} />
          </div>
          <div className='flex flex-col mb-2'>
            <Label>Password</Label>
            <Input
              placeholder='Enter your password'
              id='password'
              name='password'
              type='password'
              required={true}
              value={formState.password || ''}
              onChange={handleInputChange}
            />
            <Error message={errors.password} />
          </div>
          <div className='flex flex-col mb-6'>
            <Label>Confirm Password</Label>
            <Input
              placeholder='Enter your confirm password'
              id='confirmPassword'
              name='confirmPassword'
              type='password'
              required={true}
              value={formState.confirmPassword || ''}
              onChange={handleInputChange}
            />
            <Error message={errors.confirmPassword} />
          </div>

          <div className='flex'>
            <Button
              className='w-full btn submit'
              onClick={signUpWithEmailPasswordAction}
            >
              Sign Up
            </Button>
          </div>
          <div className='text-center mt-3'>
            <Link to='/login' className='underline'>
              Back
            </Link>
          </div>
        </div>
        <HR text={'Or'} classname={'my-6'} />
        <SocialSignIn />
      </div>
    </div>
  );
};
export default SignUp;
