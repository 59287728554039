import React, { useEffect, useRef, useState } from 'react';
import BubbleLibChart from '@/library/chart/bubble/BubbleLibChart';
import FilterButton from './FilterButton';
import cx from 'classnames';
import ss from './PieChart.module.css';
import useDataProcess from '@/hook/useDataProcess';

type BubbleChartProps = {
  data: any;
  title: string;
  colors?: string[];
  width?: string;
  height?: string;
  showFilters?: Record<string, { isShow: boolean; variable: string }>;
  setShowFilters?: React.Dispatch<
    React.SetStateAction<Record<string, { isShow: boolean; variable: string }>>
  >;
};

const BubbleChart = ({
  data,
  title,
  colors,
  width,
  height,
  showFilters,
  setShowFilters,
}: BubbleChartProps) => {
  const { ObjectToFlatArray1Level } = useDataProcess();

  return (
    <div style={{ paddingBottom: '50px' }}>
      <div
      // style={{
      //   width: width ? width : '600px',
      //   height: height ? height : '600px',
      // }}
      >
        <div className={ss['chartTitleWrapper']}>
          <p className={ss['chartTitle']}>{title}</p>
          {showFilters && (
            <FilterButton
              data={data}
              showFilters={showFilters}
              setShowFilters={setShowFilters}
            />
          )}
        </div>
        {data && <BubbleLibChart data={ObjectToFlatArray1Level(data.value)} />}
      </div>
    </div>
  );
};

export default BubbleChart;
