import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';
import { BubbleLib } from './BubbleLib';

interface BubbleChartProps {
  data: any;
  width?: number;
  height?: number;
}

const BubbleLibChart = ({ data, width, height }: BubbleChartProps) => {
  const svgRef = useRef<SVGSVGElement | null>(null);
  useEffect(() => {
    if (svgRef.current) {
      while (svgRef.current.firstChild) {
        svgRef.current.removeChild(svgRef.current.firstChild);
      }
      BubbleLib(svgRef.current, data, {
        label: (d: any) => `${d.name.split(' ').join('\n')}\n(${d.value.toLocaleString('en')})`,
        value: (d: any) => d.value,
        group: (d: any) => d.name,
        title: (d: any) => `${d.name}\n${d.value.toLocaleString('en')}`,
        width: width,
      });
    }
  }, [data]);
  return <svg ref={svgRef}></svg>;
};

export default BubbleLibChart;
