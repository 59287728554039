import { useDispatch } from 'react-redux';
import { setLoading, setLoadingText } from '@/redux/Actions';

const useLoading = () => {
  const dispatch = useDispatch();

  const startLoading = (loadingText = 'Loading') => {
    dispatch(setLoading(true));
    dispatch(setLoadingText(loadingText));
  };

  const stopLoading = () => {
    dispatch(setLoading(false));
    dispatch(setLoadingText(''));
  };

  return { startLoading, stopLoading };
};

export default useLoading;
