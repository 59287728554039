import React, { FC, useState, useEffect } from 'react';
import cx from 'classnames';
import './SideNav.scss';
import Logo from '@/assets/logo.png';
import LogoWhite from '@/assets/logo-white.png';
import { ReactComponent as IconDocument } from '@/assets/icon-document.svg';
import { ReactComponent as IconUpload } from '@/assets/icon-upload.svg';
import { ReactComponent as IconFaq } from '@/assets/faq.svg';
import { ReactComponent as IconHelp } from '@/assets/icon-help.svg';
import { ReactComponent as IconChat } from '@/assets/icon-chat.svg';
import { ReactComponent as IconHome } from '@/assets/icon-home.svg';
import { ReactComponent as IconLogout } from '@/assets/icon-logout.svg';
import { ReactComponent as IconAccount } from '@/assets/icon-account.svg';
import { ReactComponent as IconCustomerList } from '@/assets/icon-customer-list.svg';
import { ReactComponent as IconOrders } from '@/assets/icon-orders.svg';
import { ReactComponent as IconRequest } from '@/assets/icon-request.svg';
import { ReactComponent as IconHow } from '@/assets/question-square.svg';
import { NavLink, useLocation } from 'react-router-dom';
import { useNav } from '@/context/NavContext';
import { useDarkMode } from '@/context/DarkModeContext';
import { useAuthContext } from '@/component/authcheck/AuthCheck';
import { CurrencyDollarIcon, KeyIcon } from '@heroicons/react/24/solid';

const SideNav: React.FC = () => {
  const { user } = useAuthContext();
  const { collapse } = useNav();
  const { darkMode } = useDarkMode();
  const location = useLocation();
  const [active, setActive] = useState('');

  useEffect(() => {
    const l = location.pathname.split('/');
    l.length > 1 && setActive(l[1]);
  }, [location.pathname]);

  useEffect(() => {
    console.log('active', active);
  }, [active]);

  return (
    <div
      className={cx('side-nav', {
        ['side-nav-expand']: collapse === false,
        ['side-nav-collapse']: collapse === true,
      })}
    >
      <div
        className={cx('side-nav-header', 'flex', 'flex-col', 'px-4', 'h-32')}
      >
        <div
          className={cx('logo', 'py-2', {
            'px-6': collapse === true,
          })}
        >
          <img
            src={darkMode ? Logo : Logo}
            alt='logo'
            className='h-16 w-auto m-auto'
          />
        </div>
        <p className='text-center font-bold text-lg'>INSIGHT PULSE</p>
      </div>
      <div className={cx('side-nav-content', 'flex', 'flex-col')}>
        <nav className='menu px-4 pb-4'>
          <div className='menu-group'>
            <ul>
              <div
                className={cx(
                  'menu-item',
                  'menu-item-transparent',
                  'menu-item-hoverable',
                  'mb-2',
                  {
                    ['menu-item-active']: active === '',
                  },
                )}
              >
                <NavLink className='flex items-center h-full w-full' to='/'>
                  <span className='text-2xl ltr:mr-2 rtl:ml-2'>
                    <IconHome />
                  </span>
                  {!collapse && <span>Home</span>}
                </NavLink>
              </div>
              <div
                className={cx(
                  'menu-item',
                  'menu-item-transparent',
                  'menu-item-hoverable',
                  'mb-2',
                  {
                    ['menu-item-active']: active === 'history',
                  },
                )}
              >
                <NavLink
                  className='flex items-center h-full w-full'
                  to='/history'
                >
                  <span className='text-2xl ltr:mr-2 rtl:ml-2'>
                    <IconDocument />
                  </span>
                  {!collapse && <span>History</span>}
                </NavLink>
              </div>
              <div
                className={cx(
                  'menu-item',
                  'menu-item-transparent',
                  'menu-item-hoverable',
                  'mb-2',
                  {
                    ['menu-item-active']: active === 'upload',
                  },
                )}
              >
                <NavLink
                  className='flex items-center h-full w-full'
                  to='/upload'
                >
                  <span className='text-2xl ltr:mr-2 rtl:ml-2'>
                    <IconUpload />
                  </span>
                  {!collapse && <span>Upload</span>}
                </NavLink>
              </div>
              <div
                className={cx(
                  'menu-item',
                  'menu-item-transparent',
                  'menu-item-hoverable',
                  'mb-2',
                  {
                    ['menu-item-active']: active === 'submitticket',
                  },
                )}
              >
                <NavLink
                  className='flex items-center h-full w-full'
                  to='/submitticket'
                >
                  <span className='text-2xl ltr:mr-2 rtl:ml-2'>
                    <IconChat />
                  </span>
                  {!collapse && <span>Contact Us</span>}
                </NavLink>
              </div>
              <div
                className={cx(
                  'menu-item',
                  'menu-item-transparent',
                  'menu-item-hoverable',
                  'mb-2',
                  {
                    ['menu-item-active']: active === 'tickethistory',
                  },
                )}
              >
                <NavLink
                  className='flex items-center h-full w-full'
                  to='/tickethistory'
                >
                  <span className='text-2xl ltr:mr-2 rtl:ml-2'>
                    <IconRequest />
                  </span>
                  {!collapse && <span>Request</span>}
                </NavLink>
              </div>
              <div
                className={cx(
                  'menu-item',
                  'menu-item-transparent',
                  'menu-item-hoverable',
                  'mb-2',
                  {
                    ['menu-item-active']: active === 'faq',
                  },
                )}
              >
                <NavLink className='flex items-center h-full w-full' to='/faq'>
                  <span className='text-2xl ltr:mr-2 rtl:ml-2'>
                    <IconHelp />
                  </span>
                  {!collapse && <span>FAQ</span>}
                </NavLink>
              </div>
              <div
                className={cx(
                  'menu-item',
                  'menu-item-transparent',
                  'menu-item-hoverable',
                  'mb-2',
                  {
                    ['menu-item-active']: active === 'how',
                  },
                )}
              >
                <NavLink className='flex items-center h-full w-full' to='/how'>
                  <span className='text-2xl ltr:mr-2 rtl:ml-2'>
                    <IconHow />
                  </span>
                  {!collapse && <span>How to use Insights</span>}
                </NavLink>
              </div>
              <div
                className={cx(
                  'menu-item',
                  'menu-item-transparent',
                  'menu-item-hoverable',
                  'mb-2',
                  {
                    ['menu-item-active']: active === 'help',
                  },
                )}
              >
                <NavLink className='flex items-center h-full w-full' to='/help'>
                  <span className='text-2xl ltr:mr-2 rtl:ml-2'>
                    <IconFaq />
                  </span>
                  {!collapse && <span>Help</span>}
                </NavLink>
              </div>
            </ul>
          </div>
        </nav>
        <nav className='menu px-4 mt-auto'>
          {user && (user.role === 'SuperAdmin' || user.role === 'Admin') && (
            <div className='menu-group mb-8 admin-group'>
              <ul>
                <div className={cx('menu-item', 'menu-item-transparent')}>
                  <div className='flex items-center h-full w-full'>
                    <span>Administration</span>
                  </div>
                </div>
                <div
                  className={cx(
                    'menu-item',
                    'menu-item-transparent',
                    'menu-item-hoverable',
                    'mb-2',
                    {
                      ['menu-item-active']: active === 'listaccount',
                    },
                  )}
                >
                  <NavLink
                    className='flex items-center h-full w-full'
                    to='/admin/listaccount'
                  >
                    <span className='text-2xl ltr:mr-2 rtl:ml-2'>
                      <IconCustomerList />
                    </span>
                    <span>Customers</span>
                  </NavLink>
                </div>
                <div
                  className={cx(
                    'menu-item',
                    'menu-item-transparent',
                    'menu-item-hoverable',
                    'mb-2',
                    {
                      ['menu-item-active']: active === 'listorders',
                    },
                  )}
                >
                  <NavLink
                    className='flex items-center h-full w-full'
                    to='/admin/listorders'
                  >
                    <span className='text-2xl ltr:mr-2 rtl:ml-2'>
                      <IconOrders />
                    </span>
                    <span>Orders</span>
                  </NavLink>
                </div>
                <div
                  className={cx(
                    'menu-item',
                    'menu-item-transparent',
                    'menu-item-hoverable',
                    'mb-2',
                    {
                      ['menu-item-active']: active === 'tickethistory',
                    },
                  )}
                >
                  <NavLink
                    className='flex items-center h-full w-full'
                    to='/tickethistory'
                  >
                    <span className='text-2xl ltr:mr-2 rtl:ml-2'>
                      <IconRequest />
                    </span>
                    {!collapse && <span>Request</span>}
                  </NavLink>
                </div>
                {user.role === 'SuperAdmin' && (
                  <div
                    className={cx(
                      'menu-item',
                      'menu-item-transparent',
                      'menu-item-hoverable',
                      'mb-2',
                      {
                        ['menu-item-active']: active === 'listgrants',
                      },
                    )}
                  >
                    <NavLink
                      className='flex items-center h-full w-full'
                      to='/admin/listgrants'
                    >
                      <span className='text-2xl ltr:mr-2 rtl:ml-2'>
                        <IconAccount />
                      </span>
                      <span>Grants</span>
                    </NavLink>
                  </div>
                )}
                {user.role === 'SuperAdmin' && (
                  <div
                    className={cx(
                      'menu-item',
                      'menu-item-transparent',
                      'menu-item-hoverable',
                      'mb-2',
                      {
                        ['menu-item-active']: active === 'price',
                      },
                    )}
                  >
                    <NavLink
                      className='flex items-center h-full w-full'
                      to='/admin/price'
                    >
                      <span className='text-2xl ltr:mr-2 rtl:ml-2'>
                        <CurrencyDollarIcon width={24} />
                      </span>
                      <span>Price</span>
                    </NavLink>
                  </div>
                )}
              </ul>
            </div>
          )}
          <div className='menu-group'>
            <ul>
              <div
                className={cx(
                  'menu-item',
                  'menu-item-transparent',
                  'menu-item-hoverable',
                  'mb-2',
                  {
                    ['menu-item-active']: active === 'account',
                  },
                )}
              >
                <NavLink
                  className='flex items-center h-full w-full'
                  to='/account'
                >
                  <span className='text-2xl ltr:mr-2 rtl:ml-2'>
                    <IconAccount />
                  </span>
                  {!collapse && <span>Account</span>}
                </NavLink>
              </div>
              <div
                className={cx(
                  'menu-item',
                  'menu-item-transparent',
                  'menu-item-hoverable',
                  'mb-2',
                  {
                    ['menu-item-active']: active === 'logout',
                  },
                )}
              >
                <NavLink
                  className='flex items-center h-full w-full'
                  to='/signout'
                >
                  <span className='text-2xl ltr:mr-2 rtl:ml-2'>
                    <IconLogout />
                  </span>
                  {!collapse && <span>Sign out</span>}
                </NavLink>
              </div>
            </ul>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default SideNav;
