import { functions } from '../ultils/firebase';
import { httpsCallable } from 'firebase/functions';
import { encode } from 'gpt-3-encoder-browser';
import { IFileData, Chunks } from '../model/Types';
import { IResponse } from '@/model/Responses';

const apiUrl = 'yourApiUrlHere';
const tokenPerMin = 180000;
const requestPerMin = 3500;
const defaultPromptToken = 500;
const limitToken = 16384;
const limitTokenPerMin = 180000;
const serverDelayFactor = 100;

const useGptService = () => {
  const testApi = async () => {
    const method = httpsCallable(functions, 'helloWorld');
    const res = await method();
    return res;
  };

  const getLimitToken = () => {
    return Math.floor(limitToken * 0.5);
  };

  const getTotalTokens = (prompt: string) => {
    const token = encode(prompt);
    return token.length;
  };

  const getCommandTokens = (command?: string) => {
    const token = command !== undefined ? encode(command).length : 0;
    return token + defaultPromptToken;
  };

  const getLimitPerFile = () => {
    return tokenPerMin * 3;
  };

  const getServerDelayFactor = () => {
    return serverDelayFactor;
  };

  const getLimitTokenPerMin = () => {
    return limitTokenPerMin;
  };

  const sendChats = async (
    jobId: string,
    data: IFileData[],
    command: string,
  ): Promise<IResponse<string[]>> => {
    let chunks: Chunks[] = [];
    data.forEach((file) => {
      chunks.push(...file.chunks);
    });
    const method = httpsCallable<
      { jobId: string; file: IFileData[]; command: string },
      IResponse<string[]>
    >(functions, 'excelProcess');
    const res = await method({
      jobId: jobId,
      file: data,
      command: command,
    });
    return res.data;
  };

  return {
    testApi,
    getLimitToken,
    getTotalTokens,
    getCommandTokens,
    getLimitPerFile,
    getServerDelayFactor,
    getLimitTokenPerMin,
    sendChats,
  };
};

export default useGptService;
