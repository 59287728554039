import React from 'react';
import { Outlet  } from 'react-router-dom';
import { useAuthContext } from '../authcheck/AuthCheck';
import NoAccess from './NoAccess';

type GuardAdminProps = {
  children?: React.ReactNode;
};

const GuardAdmin: React.FC<GuardAdminProps> = () => {
  const { user } = useAuthContext();
  if (user && (user.role === 'SuperAdmin' || user.role === 'Admin')) {
    return <Outlet />;
  } else {
    return <NoAccess />;
  }
};

export default GuardAdmin;
