// store.ts
import { createStore, combineReducers, Store, AnyAction } from 'redux';
import loadingReducer from './LoadingReducer';

export const rootReducer = combineReducers({
  loading: loadingReducer,
});

export type AppState = ReturnType<typeof rootReducer>;

const store: Store<AppState, AnyAction> = createStore(rootReducer);

export default store;
