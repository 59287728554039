import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/solid';
import cx from 'classnames';

interface PaginationProps {
  page: number;
  size: number;
  total: number;
  handleChangePage: (direction: 'prev' | 'next') => void;
}

const Pagination = ({
  total,
  page,
  size,
  handleChangePage,
}: PaginationProps) => {
  const hasPrev = size * (page - 1) > 0;
  const hasNext = size * (page - 1) + size < total;
  console.log('total', total);
  return (
    <>
      {total !== 0 && (
        <div className='flex justify-center mx-8 gap-4'>
          <button
            className={cx(
              'p-2 flex justify-center items-center gap-1 border rounded-xl text-xs min-w-[80px]',
              {
                'bg-[#ccd6c8]': !hasPrev,
              },
            )}
            disabled={!hasPrev}
            onClick={() => handleChangePage('prev')}
          >
            <ChevronLeftIcon width={16} />
            <span>Previous</span>
          </button>
          <button
            className={cx(
              'p-2 flex justify-center items-center gap-1 border rounded-xl text-xs min-w-[80px]',
              {
                'bg-[#ccd6c8]': !hasNext,
              },
            )}
            disabled={!hasNext}
            onClick={() => handleChangePage('next')}
          >
            <span>Next</span>
            <ChevronRightIcon width={16} />
          </button>
        </div>
      )}
    </>
  );
};

export default Pagination;
