// public/src/CheckoutForm.tsx
import React, { useContext, useEffect, useState } from 'react';
import {
  useStripe,
  useElements,
  CardElement,
  PaymentElement,
} from '@stripe/react-stripe-js';
import useCheckout from '@/hook/useCheckout';
import useLoading from '@/hook/useLoading';
import { ICard, ICheckout } from '@/model/Models';
import { AuthContext } from '@/component/authcheck/AuthCheck';
import useOrderService from '@/hook/useOrderService';
import { useNavigate } from 'react-router-dom';

interface CheckoutFormProps {
  order: ICheckout | undefined;
  clientSecret: string;
  paymentMethodId: string;
}

const CheckoutForm = ({
  order,
  clientSecret,
  paymentMethodId,
}: CheckoutFormProps) => {
  const stripe = useStripe();
  const elements = useElements();
  const nav = useNavigate();
  const { user } = useContext(AuthContext);
  const { startLoading, stopLoading } = useLoading();
  const { updateOrderTransaction, excuteOrder } = useOrderService();
  const { getPaymentMethod } = useCheckout();
  const [card, setCard] = useState<ICard | null>();
  const [status, setStatus] = useState<boolean>(false);

  const loadCard = async () => {
    startLoading();
    const res = await getPaymentMethod(paymentMethodId);
    stopLoading();
    if (res.status) {
      setCard(res.data.card);
    }
  };

  useEffect(() => {
    loadCard();
  }, []);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      return;
    }
    startLoading('Order Processing…..');
    const res = await stripe.confirmCardPayment(clientSecret);

    if (res.error) {
      stopLoading();
      console.error(res.error.message);
    } else if (res.paymentIntent && res.paymentIntent.status === 'succeeded') {
      await updateOrderTransaction(order?.id as string, res.paymentIntent.id);
      stopLoading();
      const msg = order?.excuteType
        ? 'Payment successfull. Your order added to queue, We will notify through email when completed.'
        : `The order has been placed, and the result is being generated.`;
      alert(msg);
      startLoading('Analyzing');
      const resOrder = await excuteOrder(order?.id as string);
      stopLoading();
      if (order?.excuteType) {
        setStatus(true);
      } else {
        nav(`/orderdetails/${order?.id}`);
      }
    }
  };

  return (
    <>
      {card && !status && (
        <form onSubmit={handleSubmit} className='w-96'>
          <div className='p-4 my-4 border rounded-lg'>
            <div className='flex gap-4 justify-between'>
              <span className='uppercase font-bold'>{card.brand}</span>
              <span>
                Exp: {card.exp_month} / {card.exp_year}
              </span>
              <span>xxxx xxxx xxxx {card.last4}</span>
            </div>
          </div>
          <div className='flex'>
            <button
              className='btn submit mt-4 ml-auto'
              type='submit'
              disabled={!stripe || !elements}
            >
              Place your order
            </button>
          </div>
        </form>
      )}
      {card && status && (
        <div>
          <p className='text-lg font-bold mt-4'>Thank you, your order is placed.</p>
          <div className='flex'>
            <button
              className='btn submit mt-4 ml-auto'
              onClick={() => nav('/orders')}
            >
              Return to order list
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default CheckoutForm;
