import React, { useState, useContext } from 'react';

type NavContextType = {
  collapse: boolean;
  setCollapse: (value: boolean) => void;
};

export const NavContext = React.createContext<NavContextType>({
  collapse: false,
  setCollapse: (value: boolean) => {},
});

export const useNav = (): NavContextType => {
  return useContext(NavContext);
};

export const NavProvider = ({ children }) => {
  const [collapse, setCollapse] = useState(false);

  return (
    <NavContext.Provider value={{ collapse, setCollapse }}>
      {children}
    </NavContext.Provider>
  );
};
