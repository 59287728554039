import Button from '@/atom/controls/Button';
import useOrderService from '@/hook/useOrderService';
import { SupportTypeList } from '@/model/Consts';
import { IBase, ITicket } from '@/model/Models';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAuthContext } from '../authcheck/AuthCheck';
import Select from '@/atom/controls/Select';
import useValidate from '@/hook/useValidate';
import Error from '@/atom/controls/Error';
import useLoading from '@/hook/useLoading';
import useTicketService from '@/hook/useTicketService';

const topics = [...SupportTypeList];

type IFormFields = Partial<ITicket>;

const defaultFormFields: IFormFields = {};

const SubmitTicket = () => {
  const { user } = useAuthContext();
  const { orderSquenceId } = useParams();
  const { getOrdersCompact } = useOrderService();
  const { validateRequired } = useValidate();
  const { startLoading, stopLoading } = useLoading();
  const { createTicket } = useTicketService();
  const [formState, setFormState] = useState<IFormFields>({
    ...defaultFormFields,
  });
  const [errors, setErrors] = useState<IFormFields>({ ...defaultFormFields });
  const [orders, setOrders] = useState<IBase[]>();

  const handleInputChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLSelectElement>,
  ) => {
    const { name, value } = event.target;
    setFormState({
      ...formState,
      [name]: value,
    });
  };

  const fetchOrders = async (userId: string) => {
    startLoading();
    const res = await getOrdersCompact(userId);
    console.log('fetchOrders', res);
    stopLoading();
    if (res.status) {
      setOrders(res.data.sort((a, b) => a.squenceId - b.squenceId));
    }
  };

  useEffect(() => {
    if (user?.id) {
      fetchOrders(user.id);
      if (orderSquenceId) {
        setFormState({
          ...formState,
          orderSquenceId: parseInt(orderSquenceId),
        });
      }
    }
  }, [orderSquenceId, user?.id]);

  const validateForm = () => {
    const orderSquenceId = validateRequired(formState.orderSquenceId);
    const topic = validateRequired(formState.topic);
    const comment = validateRequired(formState.comment);
    setErrors({
      orderSquenceId: orderSquenceId,
      topic: topic,
      comment: comment,
    });
    return !orderSquenceId && !topic && !comment;
  };

  const submit = async (event) => {
    event.preventDefault();
    const valid = validateForm();
    if (valid) {
      startLoading('Processing...');
      const res = await createTicket({
        orderSquenceId: formState.orderSquenceId as number,
        topic: formState.topic as string,
        comment: formState.comment as string,
        userSquenceId: user!.squenceId,
      });
      stopLoading();
      if (res.status) {
        alert(
          `Thank you for reaching out. Your message has been received, and we will respond to you within two business days, your reference number is ${res.data.squenceId}`,
        );
        setFormState(defaultFormFields);
      } else {
        alert(res.error);
      }
    }
  };

  return (
    <div className='container'>
      <p className='text-lg flex font-bold'>Contact Us</p>
      <p className='py-2'>
        Please choose the order number that you have a question about
      </p>
      <div className='my-2 w-[700px]'>
        <div className='mb-4 flex items-start'>
          <div className='font-bold w-20'>Order</div>
          <div className='flex-1'>
            <Select
              className='flex-1'
              name='orderSquenceId'
              value={formState?.orderSquenceId || ''}
              onChange={handleInputChange}
            >
              <option value={''} disabled hidden>
                Select a order
              </option>
              {orders &&
                orders.map((item) => (
                  <option key={item.id} value={item.squenceId}>
                    {item.squenceId}
                  </option>
                ))}
            </Select>
            {errors.orderSquenceId && (
              <Error message={errors.orderSquenceId.toString()} />
            )}
          </div>
        </div>

        <div className='mb-4 flex items-start'>
          <div className='font-bold w-20'>Topic</div>
          <div className='flex-1'>
            <Select
              className='flex-1'
              name='topic'
              value={formState?.topic || ''}
              onChange={handleInputChange}
            >
              <option value={''} disabled hidden>
                Select a topic
              </option>
              {topics &&
                topics.map((item) => (
                  <option key={item.value} value={item.value}>
                    {item.name}
                  </option>
                ))}
            </Select>
            {errors.topic && <Error message={errors.topic} />}
          </div>
        </div>
        <div className='mb-4 flex items-start'>
          <div className='font-bold w-20'>Reason</div>
          <div className='flex-1'>
            <p className='mb-2'>
              Please use the text area below to ask your questions or send your
              comments
            </p>
            <textarea
              placeholder=''
              className='w-full h-[300px] p-4 border'
              name='comment'
              value={formState?.comment || ''}
              onChange={handleInputChange}
            ></textarea>
            {errors.comment && <Error message={errors.comment} />}
          </div>
        </div>
        <div className='mb-4 flex items-start'>
          <div className='w-20'></div>
          <div>
            <Button className='btn submit' onClick={submit}>
              Submit
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubmitTicket;
