import react, { useEffect, useState } from 'react';
import Input from '@/atom/controls/Input';
import Label from '@/atom/controls/Label';
import logoVisa from '@/assets/logo_visa.png';
import cards from '@/assets/cards.png';
import {
  PencilIcon,
  CreditCardIcon,
  ChevronRightIcon,
} from '@heroicons/react/24/solid';
import IconAvatar from '@/assets/icon-avatar.svg';
import { useAuthContext } from '../authcheck/AuthCheck';
import Button from '@/atom/controls/Button';
import Error from '@/atom/controls/Error';
import { IAccountType, IAccountRole } from '@/model/Types';
import cx from 'classnames';
import Select from '@/atom/controls/Select';
import useValidate from '@/hook/useValidate';
import useLoading from '@/hook/useLoading';
import useUser from '@/hook/useUser';
import { Link, useNavigate } from 'react-router-dom';
import { IUser } from '@/model/Models';
import Payment from '../payment/Payment';

interface IFormFields {
  firstName: string | null;
  lastName: string | null;
  email?: string | null;
  type?: IAccountType | null;
  role?: IAccountRole | null;
  companyName?: string | null;
}
const defaultFormFields: IFormFields = {
  firstName: '',
  lastName: '',
  email: '',
  type: 'Personal',
  role: 'Owner',
  companyName: '',
};

const Account = () => {
  const nav = useNavigate();
  const { user, setUser } = useAuthContext();
  const [formState, setFormState] = useState<IFormFields>({
    ...defaultFormFields,
  });
  const [errors, setErrors] = useState<IFormFields>({
    ...defaultFormFields,
    type: null,
  });
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const { validateRequired, validateEmail } = useValidate();
  const { startLoading, stopLoading } = useLoading();
  const { updateUser } = useUser();
  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    const { name, value } = event.target;
    setFormState({
      ...formState,
      [name]: value,
    });
  };

  useEffect(() => {
    if (!user) return;
    formState.firstName = user.firstName;
    formState.lastName = user.lastName;
    formState.email = user.email;
    formState.companyName = user.companyName;
    formState.role = user.role;
    formState.type = user.type;
    setFormState((prev) => {
      return { ...formState };
    });
    console.log('user', errors);
  }, [user]);

  const toogleEdit = () => {
    setIsEdit((prev) => !prev);
  };

  const validateForm = () => {
    const errFirstName = validateRequired(formState.firstName);
    const errLastName = validateRequired(formState.lastName);
    const errCompanyName =
      formState.type === 'Company'
        ? validateRequired(formState.companyName)
        : null;
    const errEmail = validateEmail(formState.email);
    setErrors({
      firstName: errFirstName,
      lastName: errLastName,
      companyName: errCompanyName,
      email: errEmail,
    });
    return !errFirstName && !errLastName && !errCompanyName && !errEmail;
  };

  const updateClick = async (event) => {
    event.preventDefault();
    const valid = validateForm();
    if (valid) {
      startLoading('Saving');
      const params = {
        ...user,
        firstName: formState.firstName,
        lastName: formState.lastName,
        companyName: formState.companyName,
        type: formState.type as IAccountType,
        email: formState.email,
      } as IUser;
      const hasUpdateEmail = user?.email !== formState.email;
      const res = await updateUser(params, hasUpdateEmail);
      stopLoading();
      if (res.status) {
        setUser({
          ...res.data,
        });
      } else {
        alert(res.message);
      }
    }
  };

  return (
    <div className='container'>
      <p className='text-xl font-bold'>Manage Your Profile</p>
      <div className='flex mt-4'>
        <div className='flex items-center justify-center p-4 mt-1 border-2 rounded-full'>
          <img
            src={user && user.photoUrl ? user.photoUrl : IconAvatar}
            alt='avatar'
            className='w-20 h-20'
          />
        </div>
      </div>
      <div className='flex mt-4'>
        <div className=''>
          <div>
            <div className='flex justify-between'>
              <span className='text-lg font-bold'>Contact Information</span>
              <div
                className={cx(
                  'flex items-center border rounded px-2 py-1 cursor-pointer',
                  isEdit ? 'bg-[#e1e2e2]' : '',
                )}
                onClick={toogleEdit}
              >
                <PencilIcon width={14} className='cursor-pointer' />
                <span className='ml-1'>Edit</span>
              </div>
            </div>
            <form className='mt-3'>
              <div className='mb-3'>
                <Label>First name</Label>
                <Input
                  name='firstName'
                  placeholder={'First name'}
                  value={formState.firstName || ''}
                  required={true}
                  disabled={!isEdit}
                  onChange={handleInputChange}
                ></Input>
                {isEdit && errors.firstName && (
                  <Error message={errors.firstName} />
                )}
              </div>
              <div className='mb-3'>
                <Label>Last name</Label>
                <Input
                  name='lastName'
                  placeholder={'Last name'}
                  value={formState.lastName || ''}
                  required={true}
                  disabled={!isEdit}
                  onChange={handleInputChange}
                ></Input>
                {isEdit && errors.lastName && (
                  <Error message={errors.lastName} />
                )}
              </div>
              <div className='mb-3'>
                <Label>Email</Label>
                <Input
                  name='email'
                  placeholder={'Email'}
                  value={formState.email || ''}
                  required={true}
                  disabled={!isEdit}
                  onChange={handleInputChange}
                ></Input>
                {isEdit && errors.email && <Error message={errors.email} />}
              </div>
              <div className='mb-3'>
                <Label>Company name</Label>
                <Input
                  name='companyName'
                  placeholder={'Company name'}
                  value={formState.companyName || ''}
                  required={true}
                  disabled={formState.type !== 'Company' || !isEdit}
                  onChange={handleInputChange}
                ></Input>
                {isEdit &&
                  user &&
                  formState.type === 'Company' &&
                  errors.companyName && <Error message={errors.companyName} />}
              </div>
              <div className='mb-3'>
                <Label>Role</Label>
                <Select
                  name='role'
                  value={formState.role || ''}
                  onChange={handleInputChange}
                  disabled={true}
                  className='!bg-[#e1e2e2]'
                >
                  <option value='Owner'>Owner</option>
                  <option value='Manager'>Manager</option>
                  <option value='Analyst'>Analyst</option>
                </Select>
              </div>
              <div className='mb-3'>
                <Label>Account Type</Label>
                <Select
                  name='type'
                  value={formState.type || ''}
                  onChange={handleInputChange}
                  disabled={(user && user.type !== 'Personal') || !isEdit}
                >
                  <option value='Company'>Company</option>
                  <option value='Personal'>Personal</option>
                </Select>
                {isEdit && user && user.type === 'Personal' && errors.type && (
                  <Error message={errors.type} />
                )}
              </div>

              {isEdit && (
                <div className='mb-3 text-right'>
                  <Button className='submit' onClick={updateClick}>
                    Update
                  </Button>
                </div>
              )}
            </form>
          </div>
        </div>
        <div className='px-8'>
          {/* <div className='card-item flex items-center rounded p-4 mt-3 bg-[#e1e2e2]'>
            <div className='mr-4'>
              <img src={logoVisa} alt='visa' className='h-10 w-auto' />
            </div>
            <div>
              <p className='font-bold'>Citizens Bank Visa Debit</p>
              <p>
                <span>Debit card ending in ****</span>
                <span>7759</span>
              </p>
            </div>
          </div> */}
          {(user?.type === 'Personal' ||
            (user?.type === 'Company' && user.role === 'Owner')) && <Payment />}
          <div>
            <p className='text-lg font-bold mb-4'>Details Preference</p>
            <Link
              className='text-lg flex justify-between p-2 border rounded'
              to='/orders'
            >
              <span>Manage your orders</span>
              <ChevronRightIcon width={24} />
            </Link>
          </div>
          <div className='mt-4'>
            <Link
              className='text-lg flex justify-between p-2 border rounded'
              to='/privacy'
              target='_blank'
            >
              <span>Privacy Policy</span>
              <ChevronRightIcon width={24} />
            </Link>
          </div>
          {user &&
            user.type === 'Company' &&
            (user.role === 'Owner' || user.role === 'Manager') && (
              <div className='mt-4'>
                <Link
                  className='text-lg flex justify-between p-2 border rounded'
                  to='/organization'
                >
                  <span>Manage your members</span>
                  <ChevronRightIcon width={24} />
                </Link>
              </div>
            )}
          <div className='mt-4'>
            <Link
              className='text-lg flex justify-between p-2 border rounded'
              to='/closeaccount'
            >
              <span>Close your account</span>
              <ChevronRightIcon width={24} />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Account;
