import { httpsCallable } from 'firebase/functions';
import { doc, onSnapshot } from 'firebase/firestore';
import { firestoreDB, functions } from '../ultils/firebase';
import useGptService from './useGptService';
import { IFileData, IPagationResult, ISearchType } from '../model/Types';
import { useContext } from 'react';
import { AuthContext } from '@/component/authcheck/AuthCheck';
import { IResponse } from '@/model/Responses';
import { IJob, INotification, IOrder, IOrderAdmin } from '@/model/Models';

const useStoreService = () => {
  const { user } = useContext(AuthContext);
  const createJob = async (
    data: IFileData[],
    excuteType: boolean,
    orderType: string,
  ): Promise<IResponse<{ jobId: string; orderId: string }>> => {
    const method = httpsCallable<
      {
        data: IFileData[];
        userId: string;
        userName: string;
        companyId: string;
        excuteType: boolean;
        orderType: string;
        userSquenceId: number;
      },
      IResponse<{ jobId: string; orderId: string }>
    >(functions, 'Job_createJob');
    const res = await method({
      data: data,
      userId: user!.id,
      userName: `${user?.firstName || ''} ${user?.lastName || ''}`,
      companyId: user!.companyId || '',
      excuteType: excuteType,
      orderType: orderType,
      userSquenceId: user!.squenceId,
    });
    return res.data;
  };

  const getJob = async (jobId: string): Promise<IResponse<IJob>> => {
    const method = httpsCallable<{ id: string }, IResponse<IJob>>(
      functions,
      'Job_getJob',
    );
    const res = await method({ id: jobId });
    return res.data;
  };

  const getOrder = async (orderId: string): Promise<IResponse<IOrder>> => {
    const method = httpsCallable<{ id: string }, IResponse<IOrder>>(
      functions,
      'Order_getOrder',
    );
    const res = await method({ id: orderId });
    return res.data;
  };

  const getOrderBySquenceId = async (orderSquenceId: number): Promise<IResponse<IOrder>> => {
    const method = httpsCallable<{ id: number }, IResponse<IOrder>>(
      functions,
      'Order_getOrderBySquenceId',
    );
    const res = await method({ id: orderSquenceId });
    return res.data;
  };


  const getOrdersTotalRecord = async (
    userId: string,
  ): Promise<IResponse<number>> => {
    const method = httpsCallable<{ userId: string }, IResponse<number>>(
      functions,
      'Order_getOrdersTotalRecord',
    );
    const res = await method({ userId: userId });
    return res.data;
  };

  const getOrders = async ({
    userId,
    pageSize,
    currentPage,
  }: {
    userId: string;
    pageSize: number;
    currentPage: number;
  }): Promise<IPagationResult<IOrder>> => {
    const method = httpsCallable<
      { userId: string; pageSize: number; currentPage: number },
      IPagationResult<IOrder>
    >(functions, 'Order_getOrders');
    const res = await method({
      userId: userId,
      pageSize: pageSize,
      currentPage: currentPage,
    });
    return res.data;
  };

  const getOrdersByAdmin = async ({
    search,
    type,
    pageSize,
    currentPage,
  }: {
    search: string | null;
    type: ISearchType;
    pageSize: number;
    currentPage: number;
  }): Promise<IPagationResult<IOrderAdmin>> => {
    const method = httpsCallable<
      {
        search: string | null;
        type: ISearchType;
        pageSize: number;
        currentPage: number;
      },
      IPagationResult<IOrderAdmin>
    >(functions, 'Order_getOrdersByAdmin');
    const res = await method({
      search: search,
      type: type,
      pageSize: pageSize,
      currentPage: currentPage,
    });
    return res.data;
  };

  

  const getNotifications = async ({
    userId,
    pageSize,
    currentPage,
  }: {
    userId: string;
    pageSize: number;
    currentPage: number;
  }): Promise<INotification[]> => {
    const method = httpsCallable<
      { userId: string; pageSize: number; currentPage: number },
      INotification[]
    >(functions, 'Notification_getNotifications');
    const res = await method({
      userId: userId,
      pageSize: pageSize,
      currentPage: currentPage,
    });
    return res.data;
  };

  const updateNotificationsStatus = async ({
    notificationIds,
    status,
  }: {
    notificationIds: string[];
    status: string;
  }): Promise<IResponse<string>> => {
    const method = httpsCallable<
      { notificationIds: string[]; status: string },
      IResponse<string>
    >(functions, 'Notification_updateNotificationsStatus');
    const res = await method({
      notificationIds: notificationIds,
      status: status,
    });
    return res.data;
  };

  return {
    createJob,
    getJob,
    getOrdersTotalRecord,
    getOrder,
    getOrderBySquenceId,
    getOrders,
    getOrdersByAdmin,
    getNotifications,
    updateNotificationsStatus,
  };
};

export default useStoreService;
