import React, { FC, useEffect, useState } from 'react';
import cx from 'classnames';
import './Header.scss';
import { ReactComponent as IconBurger } from '@/assets/icon-burger.svg';

import { ReactComponent as IconGear } from '@/assets/icon-gear.svg';

import { useNav } from '@/context/NavContext';
import ToogleTheme from '@/atom/toogletheme/ToogleTheme';
import Account from '@/atom/account/Account';
import Notification from './Notification';
import { useAuthContext } from '../authcheck/AuthCheck';

const Content: React.FC = () => {
  const { collapse, setCollapse } = useNav();
  const { user } = useAuthContext();
  const toogleNav = () => {
    setCollapse(!collapse);
  };

  return (
    <header className='header border-b border-gray-200 dark:border-gray-700'>
      <div className={cx('header-wrapper', 'h-16')}>
        <div className={cx('header-action')}>
          <div className='text-2xl cursor-pointer' onClick={toogleNav}>
            <IconBurger />
          </div>
        </div>
        <div className={cx('header-action')}>
          <Notification />
          <div
            className={cx(
              'text-2xl',
              'header-action-item',
              'header-action-item-hoverable',
            )}
          >
            <IconGear />
          </div>
          <div>
            <ToogleTheme />
          </div>
          {user && (
            <div className='font-bold ml-4'>
              Account Id: {user.squenceId}
            </div>
          )}
          <div>
            <Account />
          </div>
        </div>
      </div>
    </header>
  );
};

export default Content;
