import { useEffect, useState } from 'react';
import PriceForm from './PriceForm';
import { IModel } from '@/model/Models';
import usePrice from '@/hook/usePriceService';
import useLoading from '@/hook/useLoading';
import PriceTable from './PriceTable';

const Price = () => {
  const [models, setModels] = useState<IModel[]>([]);
  const { getModels } = usePrice();
  const { startLoading, stopLoading } = useLoading();
  const [forceReload, setForceReload] = useState<number>();
  const fetchData = async () => {
    startLoading();
    const res = await getModels();
    if (res.status) {
      setModels(res.data);
    }
    stopLoading();
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className='p-4'>
      <h1 className='text-lg font-bold mb-2'>Price</h1>
      <div className='border-t pt-4'>
        <PriceForm models={models} setForceReload={setForceReload} />
      </div>
      <div className='border-t pt-4 mt-8'>
        <PriceTable forceReload={forceReload} />
      </div>
    </div>
  );
};

export default Price;
