import React, { useState, useRef, useEffect } from 'react';
import { ReactComponent as LogoGoogle } from '@/assets/logo_google.svg';
import { ReactComponent as LogoFacebook } from '@/assets/logo_facebook.svg';
import { ReactComponent as LogoMicrosoft } from '@/assets/logo_microsoft.svg';
import { ReactComponent as LogoApple } from '@/assets/logo_apple.svg';
import useUser from '@/hook/useUser';

const SocialSignIn = () => {
  const {
    signInWithGoogle,
    signInWithFacebook,
    signInWithMicrosoft,
    signInWithApple,
  } = useUser();

  const googleClick = (evt) => {
    evt.preventDefault();
    signInWithGoogle().then((res) => {
      if (!res.status) {
        alert(res.message);
      }
    });
  };

  const facebookClick = (evt) => {
    evt.preventDefault();
    signInWithFacebook().then((res) => {
      if (!res.status) {
        alert(res.message);
      }
    });
  };

  const microsoftClick = (evt) => {
    evt.preventDefault();
    signInWithMicrosoft().then((res) => {
      if (!res.status) {
        alert(res.message);
      }
    });
  };

  const appleClick = (evt) => {
    evt.preventDefault();
    signInWithApple().then((res) => {
      if (!res.status) {
        alert(res.message);
      }
    });
  };

  return (
    <>
      <button className='btnIcon my-3 bg-white text-black' onClick={googleClick}>
        <LogoGoogle className='w-6 h-6 icon' />
        <span>Sign in with Google</span>
      </button>
      <button className='btnIcon my-3 bg-white text-black' onClick={facebookClick}>
        <LogoFacebook className='w-6 h-6 icon' />
        <span>Sign in with Facebook</span>
      </button>
      <button className='btnIcon my-3 !hidden' onClick={appleClick}>
        <LogoApple className='w-6 h-6 icon' />
        <span>Sign in with Apple</span>
      </button>
      <button className='btnIcon bg-white text-black' onClick={microsoftClick}>
        <LogoMicrosoft className='w-6 h-6 icon' />
        <span>Sign in with Microsoft</span>
      </button>
    </>
  );
};

export default SocialSignIn;
