import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
// Set up the worker URL for PDF.js
pdfjs.GlobalWorkerOptions.workerSrc = `/assets/pdf.worker.mjs`;

interface PDFReaderProps {
  filePath: string;
}

const PDFReader: React.FC<PDFReaderProps> = ({ filePath }) => {
  const [numPages, setNumPages] = useState<number | null>(null);
  const [error, setError] = useState<string | null>(null);

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
    setError(null); // Clear any previous errors
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      {error && <p>{error}</p>}
      {filePath && (
        <Document
          file={filePath}
          onLoadSuccess={onDocumentLoadSuccess}
          onLoadError={(error) => setError('Error loading PDF: ' + error.message)}
        >
          {Array.from(new Array(numPages), (el, index) => (
            <Page
              key={`page_${index + 1}`}
              pageNumber={index + 1}
              renderTextLayer={false}
              renderAnnotationLayer={false}
              scale={1.3}
            />
          ))}
        </Document>
      )}
    </div>
  );
};

export default PDFReader;
