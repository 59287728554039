import React from 'react';
import cx from 'classnames';

type SelectProps = React.SelectHTMLAttributes<HTMLSelectElement>;

const Select: React.FC<SelectProps> = ({ className, children, ...props }) => {
  return (
    <select
      {...props}
      className={cx('w-full p-2 border rounded bg-white', className)}
    >
      {children}
    </select>
  );
};

export default Select;
