import react, { useEffect } from 'react';
import useUser from '@/hook/useUser';
import { useNavigate } from 'react-router-dom';

const SignOut = () => {
  const { logout } = useUser();
  const nav = useNavigate();
  const signOut = () => {
    logout().then((res) => {
      nav('/login');
    });
  };
  useEffect(() => {
    signOut();
  });
  return <></>;
};
export default SignOut;
