import React, { useEffect, useState, useContext, useRef } from 'react';
import useLoading from '@/hook/useLoading';
import useUser from '@/hook/useUser';
import moment from 'moment';
import Pagination from '../order/Pagination';
import Select from '@/atom/controls/Select';
import { ISearchType } from '@/model/Types';
import Input from '@/atom/controls/Input';
import Label from '@/atom/controls/Label';
import Button from '@/atom/controls/Button';
import { useAuthContext } from '../authcheck/AuthCheck';
import GrantAccess from './GrantAccess';
import { TrashIcon } from '@heroicons/react/24/solid';
import { IUser } from '@/model/Models';

interface IFormFields {
  searchText: string | null;
  searchType: ISearchType;
}
const defaultFormFields: IFormFields = {
  searchText: '',
  searchType: '',
};

const ListGrant = () => {
  const { user } = useAuthContext();
  const { getUsers, removeAccessUser } = useUser();
  const { startLoading, stopLoading } = useLoading();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState<number>(-1);
  const [pageSize, setPageSize] = useState<number>(20);
  const [totalRecord, setTotalRecord] = useState<number>();
  const [list, setList] = useState<IUser[]>([]);
  const preventReload = useRef(false);
  const [formState, setFormState] = useState<IFormFields>({
    ...defaultFormFields,
  });

  const fetchAccount = async () => {
    startLoading();
    console.log(user?.role);
    const dt = await getUsers({
      search: formState.searchText,
      type: formState.searchType,
      currentPage: currentPage,
      pageSize: pageSize,
      role: user?.role,
    });
    stopLoading();
    setTotalRecord(dt.totalRecord);
    setList(dt.result);
  };

  useEffect(() => {
    if (!preventReload.current) {
      preventReload.current = true;
      setCurrentPage(1);
    }
  }, []);

  useEffect(() => {
    if (currentPage === -1) return;
    fetchAccount();
  }, [currentPage]);

  const handleChangePage = (type: string) => {
    const p = type === 'prev' ? currentPage - 1 : currentPage + 1;
    setCurrentPage(p);
  };

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    const { name, value } = event.target;
    setFormState({
      ...formState,
      [name]: value,
    });
  };

  const updateMemberList = (updatedMember: IUser) => {
    setList((prev) => [...prev, updatedMember]);
  };

  const grantAccess = () => {
    setTimeout(() => {
      setIsModalOpen(true);
    }, 100);
  };

  const revokeAccess = async (user: IUser) => {
    if (window.confirm('Are you sure you want to revoke access this user?')) {
      startLoading();
      const res = await removeAccessUser(user);
      stopLoading();
      setList((prev) => [...prev.filter((item) => item.id !== user.id)]);
    }
  };

  return (
    <div className='container'>
      <p className='text-lg flex font-bold'>Admin Users</p>
      <div className='flex gap-2 my-3 justify-end'>
        <Button className='submit' onClick={grantAccess}>
          Grant access
        </Button>
      </div>
      <table className='min-w-full mt-2'>
        <thead>
          <tr className='border-b-2'>
            <th className='text-left pr-4 py-3'>First name</th>
            <th className='text-center px-4 py-3'>Last name</th>
            <th className='text-center px-4 py-3'>Email</th>
            <th className='text-center px-4 py-3'>Role</th>
            <th className='text-center px-4 py-3'>Type</th>
            <th className='text-center px-4 py-3'>Company name</th>
            <th className='text-center px-4 py-3'>Created date</th>
            <th className='text-center px-4 py-3'></th>
          </tr>
        </thead>
        <tbody>
          {list &&
            list.map((item: any, index) => {
              return (
                <tr className='border-b' key={index}>
                  <td className='text-left pr-4 py-3'>{item.firstName}</td>
                  <td className='text-left px-4 py-3'>{item.lastName}</td>
                  <td className='text-left px-4 py-3'>{item.email}</td>
                  <td className='text-left px-4 py-3'>{item.role}</td>
                  <td className='text-left px-4 py-3'>{item.type}</td>
                  <td className='text-left px-4 py-3'>{item.companyName}</td>
                  <td className='text-center px-4 py-3'>
                    {moment(item.createdAt).format('DD-MMM-YYYY')}
                  </td>
                  <td className='text-center px-4 py-3'>
                    {item.role === 'Admin' && (
                      <button
                        className='flex gap-1'
                        onClick={() => {
                          revokeAccess(item);
                        }}
                      >
                        <TrashIcon width={16} /> <span>revoke</span>
                      </button>
                    )}
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
      <div className='mt-4'>
        {totalRecord === 0 && <p className='text-center'>No order exists!</p>}
        {totalRecord !== undefined &&
          totalRecord !== null &&
          totalRecord !== 0 && (
            <Pagination
              page={currentPage}
              size={pageSize}
              total={totalRecord}
              handleChangePage={handleChangePage}
            />
          )}
      </div>
      {isModalOpen && (
        <GrantAccess
          isOpen={isModalOpen}
          setIsOpen={setIsModalOpen}
          updateMemberList={updateMemberList}
        />
      )}
    </div>
  );
};

export default ListGrant;
