import React from 'react';
import cx from 'classnames';

type InputProps = React.InputHTMLAttributes<HTMLInputElement>;
const Input: React.FC<InputProps> = ({ className, ...props }) => {
  return (
    <input
      {...props}
      className={cx(
        'input input-md h-11 w-full focus:ring-indigo-600 focus:border-indigo-600',
        className,
      )}
    />
  );
};

export default Input;
