import React, { FC } from 'react';
import { useDarkMode } from '@/context/DarkModeContext';
import cx from 'classnames';

const ToogleTheme: React.FC = () => {
    const { darkMode, setDarkMode } = useDarkMode();

    const toogle = () => {
        console.log('darkmode', darkMode);
        setDarkMode(!darkMode);
    }

    return (
        <label
            className={cx('switcher', {
                ['switcher-checked']: darkMode,
            })}
        >
            <input
                type="checkbox"
                value="true"
                checked={darkMode}
                onChange={toogle}
            />
            <div className="switcher-toggle"></div>
            <span className="switcher-content"></span>
        </label>
    );
}
export default ToogleTheme;