import React from 'react';
import cx from 'classnames';

type ErrorProps = {
  message: string | null;
  classNames?: string;
};

const Error = ({ message, classNames }: ErrorProps) => {
  if (message) {
    return (
      <p className={cx('text-red-500 text-xs mt-[5px]', classNames)}>
        {message}
      </p>
    );
  } else {
    return <></>;
  }
};

export default Error;
