import React, { FC } from 'react';
import { NavProvider } from '@/context/NavContext';
import AuthCheck from '../authcheck/AuthCheck';
import SideNav from '../sidenav/SideNav';
import Content from '../content/Content';

const Authenticated: React.FC = () => {
  return (
    <AuthCheck>
      <NavProvider>
        <div className='app flex flex-auto flex-col'>
          <div className='flex flex-auto min-w-0'>
            <SideNav />
            <Content />
          </div>
        </div>
      </NavProvider>
    </AuthCheck>
  );
};

export default Authenticated;
