import Button from '@/atom/controls/Button';
import Select from '@/atom/controls/Select';
import useExport from '@/hook/useExport';
import Excel from 'exceljs';
import { useState } from 'react';

type ExportType = 'excel' | 'sheet' | 'csv';

const OrderDownload = ({ segments, fileNames }) => {
  const { transformToArray } = useExport();
  const segs = [...segments];
  const [type, setType] = useState<ExportType>('excel');
  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    const { name, value } = event.target;
    setType(value as ExportType);
  };

  const getFileName = (names: string[], extension: string) => {
    const baseNames = names.map((name) => name.split('.')[0]);
    const date = new Date();
    const formattedDate = [
      ('0' + date.getDate()).slice(-2),
      ('0' + (date.getMonth() + 1)).slice(-2),
      date.getFullYear(),
    ].join('');
    const newName = `${baseNames.join('_')}_${formattedDate}.${extension}`;

    return newName;
  };

  const exportExcel = async () => {
    const cols = segs.map((seg) => {
      return transformToArray(seg);
    });
    const workbook = new Excel.Workbook();
    const worksheet = workbook.addWorksheet('My Sheet');
    let maxRows = 0;
    // builder header row
    let excelRowIndex = 1;
    let excelColIndex = 1;
    cols.forEach((col) => {
      const cell = worksheet.getCell(excelRowIndex, excelColIndex);
      cell.font = { bold: true, color: { argb: '000000' } };
      cell.alignment = { horizontal: 'center', vertical: 'middle' };
      cell.border = {
        top: { style: 'thin', color: { argb: '000000' } },
        left: { style: 'thin', color: { argb: '000000' } },
        bottom: { style: 'thin', color: { argb: '000000' } },
        right: { style: 'thin', color: { argb: '000000' } },
      };
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'd9d9d9' },
      };
      worksheet.mergeCells(
        0,
        excelColIndex,
        0,
        excelColIndex + col[0].length - 1,
      );
      cell.value = col[0][0];
      excelColIndex += col[0].length;
      maxRows = Math.max(maxRows, col.length);
    });
    // builder data row
    for (let i = 2; i <= maxRows; i++) {
      let excelColIndex = 1;
      cols.forEach((col: any[]) => {
        let row: any[] = [];
        // index excell begin from 1, index array index from 0 so should subtract 1
        if (col[i - 1] !== undefined) {
          row = [...col[i - 1]];
        } else {
          row = new Array(col[0].length).fill(null);
        }
        row.forEach((cell: any, index: number) => {
          const c = worksheet.getCell(i, excelColIndex + index);
          c.value = cell;
          if (index === 0 && cell && row.length > 2) {
            c.font = { bold: true, color: { argb: '000000' } };
          }
        });
        excelColIndex += row.length;
      });
    }

    // Use writeBuffer to generate a buffer
    const buffer = await workbook.xlsx.writeBuffer();

    // Create a Blob and a download link
    const blob = new Blob([buffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = getFileName(fileNames, 'xlsx');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const exportCSV = async () => {
    const cols = segs.map((seg) => {
      return transformToArray(seg);
    });
    const workbook = new Excel.Workbook();
    const worksheet = workbook.addWorksheet('My Sheet');
    let maxRows = 0;
    // builder header row
    let excelRowIndex = 1;
    let excelColIndex = 1;
    cols.forEach((col) => {
      col[0].forEach((cell, index) => {
        const c = worksheet.getCell(excelRowIndex, excelColIndex + index);
        if (cell) {
          c.value = cell;
        } else {
          c.value = '';
        }
      });
      excelColIndex += col[0].length;
      maxRows = Math.max(maxRows, col.length);
    });
    // builder data row
    for (let i = 2; i <= maxRows; i++) {
      let excelColIndex = 1;
      cols.forEach((col: any[]) => {
        let row: any[] = [];
        // index excell begin from 1, index array index from 0 so should subtract 1
        if (col[i - 1] !== undefined) {
          row = [...col[i - 1]];
        } else {
          row = new Array(col[0].length).fill(null);
        }
        row.forEach((cell: any, index: number) => {
          const c = worksheet.getCell(i, excelColIndex + index);
          c.value = cell;
          if (index === 0 && cell && row.length > 2) {
            c.font = { bold: true, color: { argb: '000000' } };
          }
        });
        excelColIndex += row.length;
      });
    }

    // Export to CSV with the specified delimiter
    const buffer = await workbook.csv.writeBuffer({
      formatterOptions: { delimiter: ';' },
    });
    const blob = new Blob([buffer], { type: 'text/csv' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = getFileName(fileNames, 'csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const exportData = () => {
    if (type === 'excel') {
      exportExcel();
    } else if (type === 'csv') {
      exportCSV();
    } else if (type === 'sheet') {
      exportExcel();
    }
  };
  return (
    <div className='flex items-center gap-4 mt-4'>
      <span className='font-bold'>Select file format</span>
      <Select className='!w-60 !h-11' value={type} onChange={handleInputChange}>
        <option value='excel'>Microsoft Excel</option>
        <option value='csv'>CSV semicolon delimiter</option>
      </Select>
      <Button className='btn submit' onClick={exportData}>
        Download
      </Button>
    </div>
  );
};

export default OrderDownload;
