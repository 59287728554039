import { useState } from 'react';
import Button from '@/atom/controls/Button';
import Input from '@/atom/controls/Input';
import Label from '@/atom/controls/Label';
import Select from '@/atom/controls/Select';
import { IModel, IPriceModel } from '@/model/Models';
import usePrice from '@/hook/usePriceService';
import { setLoading } from '@/redux/Actions';
import useLoading from '@/hook/useLoading';

interface IPriceForm {
  models: IModel[];
  setForceReload: React.Dispatch<React.SetStateAction<number | undefined>>;
}

interface IPriceError {
  priceToken?: string;
  appFee?: string;
  modelName?: string;
  tax?:string;
}

const PriceForm = ({ models, setForceReload }: IPriceForm) => {
  const { createAndActive } = usePrice();
  const { startLoading, stopLoading } = useLoading();
  const [price, setPrice] = useState<IPriceModel>({
    id: '',
    squenceId: 0,
    modelName: '',
    priceToken: 0,
    appFee: 0,
    tax: 0,
    isActive: true,
  });
  const [error, setError] = useState<IPriceError>({});

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    const { name, value } = event.target;
    setPrice((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const validateField = (field: keyof IPriceModel, errorMsg: string) => {
    if (!price[field]) {
      setError((prev) => ({ ...prev, [field]: errorMsg }));
      return false;
    }
    setError((prev) => ({ ...prev, [field]: undefined }));
    return true;
  };

  const submit = async () => {
    const isPriceTokenValid = validateField(
      'priceToken',
      'Please fill token price',
    );
    const isAppFeeValid = validateField('appFee', 'Please fill app fee price');
    const isModelNameValid = validateField(
      'modelName',
      'Please select model name',
    );
    const isTaxtValid = validateField(
      'tax',
      'Please fill tax',
    );

    if (isPriceTokenValid && isAppFeeValid && isModelNameValid && isTaxtValid) {
      startLoading();
      price.appFee = parseFloat(price.appFee.toString());
      price.priceToken = parseFloat(price.priceToken.toString());
      price.tax =  parseFloat(price.tax.toString());
      const res = await createAndActive(price);
      stopLoading();
      console.log('res', res);
      if (res.status) {
        setForceReload((prev) => (prev ?? 0) + 1);
        setPrice((prev) => {
          return {
            ...prev,
            modelName: '',
            appFee: 0,
            priceToken: 0,
            tax: 0
          };
        });
      }
    }
  };

  return (
    <div className='flex gap-8'>
      <div className='max-w-sm'>
        <div className='flex items-start gap-4 mt-2'>
          <Label className='w-20'>Model</Label>
          <div className='flex-1'>
            <Select
              name='modelName'
              className='h-11'
              value={price.modelName || ''}
              onChange={handleInputChange}
            >
              <option value='' hidden>
                Select a model
              </option>
              {models.map((item) => (
                <option key={item.name} value={item.name}>
                  {item.name}
                </option>
              ))}
            </Select>
            {error.modelName && (
              <p className='my-2 text-red-500'>{error.modelName}</p>
            )}
          </div>
        </div>
        <div className='flex items-start gap-4 mt-2'>
          <Label className='w-20'>Price</Label>
          <div className='flex-1'>
            <Input
              type='text'
              name='priceToken'
              placeholder='$'
              className='text-right'
              value={price.priceToken || ''}
              onChange={handleInputChange}
            />
            {error.priceToken && (
              <p className='my-2 text-red-500'>{error.priceToken}</p>
            )}
          </div>
        </div>
        <div className='flex items-start gap-4 mt-2'>
          <Label className='w-20'>App fee</Label>
          <div className='flex-1'>
            <Input
              type='text'
              name='appFee'
              placeholder='$'
              className='text-right'
              value={price.appFee || ''}
              onChange={handleInputChange}
            />
            {error.appFee && (
              <p className='my-2 text-red-500'>{error.appFee}</p>
            )}
          </div>
        </div>
        <div className='flex items-start gap-4 mt-2'>
          <Label className='w-20'>Tax</Label>
          <div className='flex-1'>
            <Input
              type='text'
              name='tax'
              placeholder='%'
              className='text-right'
              value={price.tax || ''}
              onChange={handleInputChange}
            />
            {error.tax && (
              <p className='my-2 text-red-500'>{error.tax}</p>
            )}
          </div>
        </div>
        <div className='flex items-center justify-end mt-4'>
          <Button className='btn submit' onClick={submit}>
            Submit and Activate
          </Button>
        </div>
      </div>
      <div className='p-8 border rounded-lg mt-2'>
        {price.modelName &&
          models
            .filter((item) => item.name === price.modelName)
            .map((item) => (
              <div key={item.name}>
                <p>Open AI model name: {item.name}</p>
                <p>Open AI price per 1000 tokens: ${item.priceToken1000}</p>
              </div>
            ))}
        <p>
          Price formula: price * (number of tokens/1000) + (app fee) +
          priceBeforeVat * Vat
        </p>
        <p>If you don't use an app fee then Set App fee to 0.</p>
      </div>
    </div>
  );
};

export default PriceForm;
