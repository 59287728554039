import React, { FC, useState, useEffect, useRef } from 'react';
import Logo from '@/assets/logo.png';
import { Link, useNavigate } from 'react-router-dom';
import useLoading from '@/hook/useLoading';
import useUser from '@/hook/useUser';
import useValidate from '@/hook/useValidate';
interface IFormFields {
  email: string | null;
}
const defaultFormFields: IFormFields = {
  email: '',
};

const ForgotPassword: React.FC = () => {
  const { startLoading, stopLoading } = useLoading();
  const { resetPassword } = useUser();
  const { validateEmail } = useValidate();
  const nav = useNavigate();
  const [formState, setFormState] = useState<IFormFields>(defaultFormFields);
  const [errors, setErrors] = useState<IFormFields>(defaultFormFields);
  const clickedSubmit = useRef(false);
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormState({
      ...formState,
      [name]: value,
    });
  };

  useEffect(() => {
    if (clickedSubmit.current) {
      validateForm();
    }
  }, [formState]);

  const validateForm = () => {
    const errEmail = validateEmail(formState.email);
    setErrors({
      email: errEmail,
    });
    return !errEmail;
  };

  const resetPasswordClick = (evt) => {
    evt.preventDefault();
    clickedSubmit.current = true;
    const valid = validateForm();
    console.log('valid', valid);
    if (valid) {
      startLoading();
      resetPassword(formState.email).then((res) => {
        stopLoading();
        console.log('res', res);
        if (!res.status) {
          alert(res.message);
        } else {
          nav('/login');
        }
      });
    }
  };

  return (
    <div className='flex items-center justify-center h-screen bg-[#83afe2] text-[#444]'>
      <div className='p-8 shadow rounded-md w-full sm:w-[450px] bg-[#003059] text-white'>
        <div className='logo mb-4'>
          <img src={Logo} alt='logo' className='h-28 m-auto' />
        </div>
        <p className='font-bold text-xl my-1'>Reset your Password</p>
        <p className='text-sm'>
          Enter your email address and we'll send you a link to reset your
          password.
        </p>
        <div className='my-3'>
          <div className='flex flex-col mb-4'>
            <label className='mb-2'>Email</label>
            <input
              placeholder='example@me.com'
              className='input'
              id='email'
              name='email'
              type='text'
              required={true}
              value={formState.email || ''}
              onChange={handleInputChange}
            ></input>
            {errors.email && (
              <p className='text-red-500 text-xs mt-[5px]'>{errors.email}</p>
            )}
          </div>
          <div className='flex'>
            <button className='w-full btn submit' onClick={resetPasswordClick}>
              Continue
            </button>
          </div>
          <div className='text-center mt-3'>
            <Link to='/login' className='underline'>
              Back
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ForgotPassword;
