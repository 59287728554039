import React from 'react';
import cx from 'classnames';

type LabelProps = React.LabelHTMLAttributes<HTMLLabelElement>;
const Label: React.FC<LabelProps> = ({ children, className, ...props }) => {
  return (
    <label {...props} className={cx('mb-1 inline-block', className)}>
      {children}
    </label>
  );
};

export default Label;
