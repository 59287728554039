import usePrice from '@/hook/usePriceService';
import { useEffect, useState } from 'react';
import { ReactComponent as IconLoading } from '@/assets/icon-loading.svg';
import { IPriceModel } from '@/model/Models';
import moment from 'moment';
import useLoading from '@/hook/useLoading';
import cx from 'classnames';

const PriceTable = ({ forceReload }: { forceReload: number | undefined }) => {
  const { getPrices, activePriceById } = usePrice();
  const { startLoading, stopLoading } = useLoading();
  const [prices, setPrices] = useState<IPriceModel[]>();
  const fetchData = async () => {
    startLoading();
    const res = await getPrices(1, 20);
    stopLoading();
    res.sort((a, b) => {
      if (a.isActive === b.isActive) {
        return b.squenceId - a.squenceId;
      } else {
        if (a.isActive) {
          return -1;
        } else {
          return 1;
        }
      }
    });
    setPrices(res);
    console.log('res', res);
  };

  useEffect(() => {
    fetchData();
  }, [forceReload]);

  const activePrice = async (id: string) => {
    startLoading();
    const res = await activePriceById(id);
    stopLoading();
    if (res.status) {
      fetchData();
    }
  };

  return (
    <div className='relative'>
      {prices && (
        <table className='min-w-full md:min-w-[700px] mt-2'>
          <thead>
            <tr className='border-b-2'>
              <th className='text-left px-4 py-3'>Id</th>
              <th className='text-left pr-4 py-3'>Model name</th>
              <th className='text-left pr-4 py-3'>Price</th>
              <th className='text-left pr-4 py-3'>App fee</th>
              <th className='text-left pr-4 py-3'>Tax</th>
              <th className='text-left pr-4 py-3'>Active</th>
              <th className='text-left pr-4 py-3'>Date Active</th>
              <th className='text-center px-4 py-3'>Date Deactive</th>
              <th className='text-center px-4 py-3'></th>
            </tr>
          </thead>
          <tbody>
            {prices &&
              prices.map((item: IPriceModel, index) => {
                return (
                  <tr className={cx('border-b', {'bg-[#f3f3f3]': item.isActive})} key={index}>
                    <td className='text-left px-4 py-3'>{item.squenceId}</td>
                    <td className='text-left pr-4 py-3'>{item.modelName}</td>
                    <td className='text-right pr-4 py-3'>{item.priceToken}</td>
                    <td className='text-right pr-4 py-3'>{item.appFee}</td>
                    <td className='text-right pr-4 py-3'>{item.tax}%</td>
                    <td className='text-center pr-4 py-3'>
                      {item.isActive ? 'Yes' : 'No'}
                    </td>
                    <td className='text-center pr-4 py-3'>
                      {item.dateActivate &&
                        moment(
                          new Date(
                            (item.dateActivate as any)._seconds * 1000 +
                              (item.dateActivate as any)._nanoseconds / 1000000,
                          ),
                        ).format('DD-MMM-YYYY')}
                    </td>
                    <td className='text-center px-4 py-3'>
                      {item.dateDeactivate &&
                        moment(
                          new Date(
                            (item.dateDeactivate as any)._seconds * 1000 +
                              (item.dateDeactivate as any)._nanoseconds /
                                1000000,
                          ),
                        ).format('DD-MMM-YYYY')}
                    </td>
                    <td className='text-center px-4 py-3'>
                      {!item.isActive && (
                        <span
                          className='underline cursor-pointer'
                          onClick={() => activePrice(item.id)}
                        >
                          Active Price
                        </span>
                      )}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default PriceTable;
