import {
  IFileData,
  IData,
  Chunks,
  ISegment,
  ISegmentResponse,
} from '../model/Types';
import useGptService from './useGptService';
import { IResponse } from '@/model/Responses';

const useDataProcess = () => {
  const {
    getTotalTokens,
    getLimitToken,
    getCommandTokens,
    getLimitTokenPerMin,
    getServerDelayFactor,
  } = useGptService();

  const splitChunks = (data: IData[], command?: string): Chunks[] => {
    let newChunks: Chunks[] = [];
    let chunk: IData[] = [];
    let totalTokens = 0;

    const cmdToken = getCommandTokens(command);
    const limitTokens = getLimitToken();
    let texts = {};
    data.forEach((item, index) => {
      const text = item.text.trim().toLowerCase();
      if (texts.hasOwnProperty(text)) {
        return;
      } else {
        texts[text] = '';
      }
      let token = getTotalTokens(item.text);
      if (totalTokens + token + cmdToken > limitTokens) {
        newChunks.push({ data: chunk, totalTokens: totalTokens + cmdToken });
        chunk = [item];
        totalTokens = token;
      } else {
        chunk.push(item);
        totalTokens += token;
      }
    });

    if (chunk.length > 0) {
      newChunks.push({ data: chunk, totalTokens: totalTokens + cmdToken });
    }
    return newChunks;
  };

  const calculateTime = (files: IFileData[]) => {
    let _totalRows = 0;
    let _totalTokens = 0;
    let _totalMinutes = 0;
    let _totalSeconds = 0;
    files.forEach((it) => {
      if (it.isValid) {
        _totalRows += it.totalRows;
        _totalTokens += it.totalTokens;
      }
    });
    let seconds = Math.round(
      (_totalTokens / getLimitTokenPerMin()) * 60 * getServerDelayFactor(),
    );
    _totalMinutes = Math.floor(seconds / 60);
    _totalSeconds = seconds % 60;
    return {
      totalRows: _totalRows,
      totalTokens: _totalTokens,
      totalMinutes: _totalMinutes,
      totalSeconds: _totalSeconds,
    };
  };

  

  const analysis = (data: string[]): IResponse<ISegmentResponse> => {
    let result = {} as ISegmentResponse;
    let rawArr: string[] = [];
    let jsonArr: any[] = [];
    let errArr: { err: string; text: string }[] = [];
    data.forEach((val) => {
      try {
        rawArr.push(val);
        const jsonVal = JSON.parse(val);
        jsonArr.push(jsonVal);
      } catch (err) {
        errArr.push({ err: err as string, text: val });
      }
    });
    console.log('jsonArr', jsonArr);
    let obj = {};
    jsonArr.forEach((val) => {
      obj = deepMerge(obj, val);
    });
    result.raw = rawArr.join('\n');
    result.val = obj;
    console.log(data[0]);
    let groups = [
      {
        name: 'sentiment',
        displayName: 'Sensitivity',
        hasFilter: false,
      },
      {
        name: 'categories',
        displayName: 'Sensitivity by Topics',
        hasFilter: true,
      },
      { name: 'topics', displayName: 'Feedback by Topics', hasFilter: true },
      {
        name: 'customer appreciate',
        displayName: 'Appreciate',
        hasFilter: true,
      },
      {
        name: 'customer behaviors',
        displayName: ' Behaviors',
        hasFilter: true,
      },
      {
        name: 'customer dissatisfaction',
        displayName: 'Dissatisfaction',
        hasFilter: true,
      },
      {
        name: 'customer expectations',
        displayName: 'Expectations',
        hasFilter: false,
      },
      {
        name: 'customer improvements',
        displayName: 'Improvements',
        hasFilter: true,
      },
    ];
    result.segments = [];
    for (const value of groups) {
      result.segments.push({
        ...value,
        value: obj[value.name],
        deep: getDepth(obj[value.name]),
      });
    }
    console.log('result.jsonArr', result.segments);
    result.error = errArr;
    return {
      status: true,
      data: result,
    };
  };

  const getDepth = (obj) => {
    if (typeof obj !== 'object' || obj === null) {
      return 0;
    }
    let maxDepth = 0;
    for (let key in obj) {
      maxDepth = Math.max(maxDepth, getDepth(obj[key]));
    }
    return maxDepth + 1;
  };

  const deepMerge = (obj1, obj2) => {
    if (typeof obj1 === 'number' && typeof obj2 === 'number') {
      return obj1 + obj2;
    }
    if (typeof obj1 !== 'object' || typeof obj2 !== 'object') {
      return obj2;
    }
    const merged = { ...obj1 };
    for (const key in obj2) {
      if (obj1.hasOwnProperty(key)) {
        merged[key] = deepMerge(obj1[key], obj2[key]);
      } else {
        merged[key] = obj2[key];
      }
    }

    return merged;
  };

  const jsonToHtml = (obj, indent = 0) => {
    let html = '';
    let paddingLeft = 20 * indent;

    for (const [key, value] of Object.entries(obj)) {
      if (Array.isArray(value)) {
        html += `<div style="margin-left: ${paddingLeft}px;font-size: 12px;text-transform: uppercase;font-weight: 700;">${key}:</div>`;
        let valsText = value.map((v) => `<div>- ${v}</div>`).join('');
        html += `<div style="margin-left: ${
          paddingLeft + 20
        }px">${valsText}</div>`;
      } else if (typeof value === 'object') {
        html += `<div style="margin-left: ${paddingLeft}px;margin-top: 0px;font-size: 12px;text-transform: uppercase;font-weight: 700;">${key}:</div>`;
        html += jsonToHtml(value, indent + 1);
      } else {
        html += `<div style="margin-left: ${paddingLeft}px">- ${key}: ${value}</div>`;
      }
    }

    return html;
  };

  const sumCategories = (data) => {
    let dt = {};
    const dataValue = data.value;
    Object.keys(dataValue).forEach((key) => {
      let total = 0;
      Object.keys(dataValue[key]).forEach((k) => {
        total += dataValue[key][k];
      });
      dt[key] = total;
    });
    return {
      ...data,
      value: dt,
    };
  };

  const getSegment = (segs: ISegment[] | undefined, name: string) => {
    const f = (segs && segs.filter((v) => v.name === name)) || {};
    return f[0];
  };

  const ObjectToFlatArray2Level = (obj: {
    [key: string]: { [key: string]: number };
  }): {
    name: string;
    value: number;
    parent?: string;
  }[] => {
    let root = { name: 'root', value: 0 };
    let index = -1;
    let flatData = Object.entries(obj).map(([key, valueObj]) => {
      const sum = Object.values(valueObj).reduce((acc, cur) => acc + cur, 0);
      index++;
      return {
        name: key,
        value: sum,
        parent: 'root',
      };
    });
    return [root, ...flatData];
  };

  const ObjectToFlatArray1Level = (obj: {
    [key: string]: { [key: string]: number };
  }): {
    name: string;
    value: number;
    parent?: string;
  }[] => {
    let root = { name: 'root', value: 0 };
    let index = -1;
    let flatData = Object.entries(obj)
      .map(([key, valueObj]) => {
        const sum: any = valueObj;
        return {
          name: key,
          value: sum,
          parent: 'root',
        };
      })
      .filter((item) => item.value !== 0);

    return [root, ...flatData];
  };

  return {
    splitChunks,
    calculateTime,
    analysis,
    sumCategories,
    getSegment,
    getDepth,
    ObjectToFlatArray2Level,
    ObjectToFlatArray1Level,
  };
};

export default useDataProcess;
