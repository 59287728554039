import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import TreeMapLibChart from '@/library/chart/treemap/TreeMapLibChart';
import FilterButton from './FilterButton';
import cx from 'classnames';
import ss from './PieChart.module.css';
import useDataProcess from '@/hook/useDataProcess';

type TreeMapChartProps = {
  data: any;
  title: string;
  colors?: string[];
  width?: string;
  height?: string;
  showFilters?: Record<string, { isShow: boolean; variable: string }>;
  setShowFilters?: React.Dispatch<
    React.SetStateAction<Record<string, { isShow: boolean; variable: string }>>
  >;
};

const TreeMapChart = ({
  data,
  title,
  colors,
  width,
  height,
  showFilters,
  setShowFilters,
}: TreeMapChartProps) => {
  const { ObjectToFlatArray2Level } = useDataProcess();

  return (
    <div style={{ paddingBottom: '50px' }}>
      <div
      // style={{
      //   width: width ? width : '600px',
      //   height: height ? height : '600px',
      // }}
      >
        <div className={ss['chartTitleWrapper']}>
          <p className={ss['chartTitle']}>{title}</p>
          {showFilters && (
            <FilterButton
              data={data}
              showFilters={showFilters}
              setShowFilters={setShowFilters}
            />
          )}
        </div>
        {data && <TreeMapLibChart data={ObjectToFlatArray2Level(data.value)} />}
      </div>
    </div>
  );
};

export default TreeMapChart;
