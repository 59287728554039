import { loadStripe } from '@stripe/stripe-js';
import PaymentMethodForm from './PaymentMethodForm';
import { Elements } from '@stripe/react-stripe-js';
import { AuthContext } from '@/component/authcheck/AuthCheck';
import { useContext, useEffect, useState } from 'react';
import useLoading from '@/hook/useLoading';
import useCheckout from '@/hook/useCheckout';
import { useParams } from 'react-router-dom';

const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLIC_KEY as string,
);

const PaymentMethod = () => {
  const { id } = useParams();
  const { user, setUser } = useContext(AuthContext);
  const { startLoading, stopLoading } = useLoading();
  const { createStripeCustomer, createCustomerIntent } = useCheckout();
  const [clientSecrect, setClientSecret] = useState<string>();

  const fetchIntent = async () => {
    startLoading();
    if (user) {
      let customerId: string | null = null;
      if (!user.stripeCustomerId) {
        const resCustomer = await createStripeCustomer(user.id, user.email);
        if (resCustomer.status) {
          customerId = resCustomer.data;
          user.stripeCustomerId = customerId;
          setUser(user);
        }
      } else {
        customerId = user.stripeCustomerId;
      }
      if (customerId) {
        const resIntent = await createCustomerIntent(customerId);
        stopLoading();
        if (resIntent.status) {
          setClientSecret(resIntent.data);
        }
      } else {
        stopLoading();
      }
    } else {
      stopLoading();
    }
  };

  useEffect(() => {
    fetchIntent();
  }, []);

  return (
    <div className='p-4'>
      <p className='text-lg font-bold'>Add payment method</p>
      <div className='pt-4 mt-2 border-t'>
        {clientSecrect && (
          <Elements
            stripe={stripePromise}
            options={{ clientSecret: clientSecrect }}
          >
            <PaymentMethodForm orderId={id} />
          </Elements>
        )}
      </div>
    </div>
  );
};

export default PaymentMethod;
