import React, { useEffect, useRef } from "react";
import { TreeMapLib } from "./TreeMapLib";

interface TreeMapLibChartProps {
  data: any;
  width?: number;
  height?: number;
};

const TreeMapLibChart = ({ data, width, height  }: TreeMapLibChartProps) => {
  const svgRef = useRef<SVGSVGElement | null>(null);
  useEffect(() => {
    if (svgRef.current) {
      while (svgRef.current.firstChild) {
        svgRef.current.removeChild(svgRef.current.firstChild);
      }
      TreeMapLib(svgRef.current, data, {
        id: (d: any) => d.name,
        parentId: (d: any) => d.parent || null,
        value: (d: any) => d.value,
        label: (d: any) => `${d.name.split(" ").join('\n')}\n(${d.value})`,
        group: (d: any) => d.name,
        title: (d: any) => `${d.name}\n${d.value}`,
        sort: (a: any, b: any) => b.value - a.value,
        width: width,
        height: height
      });
    }
  }, [data]);
  return (
    <svg ref={svgRef}></svg> 
  );
};

export default TreeMapLibChart;
