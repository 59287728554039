import React, { useEffect, useState, useContext, useRef } from 'react';
import useLoading from '@/hook/useLoading';
import useUser from '@/hook/useUser';
import moment from 'moment';
import Pagination from '../order/Pagination';
import Select from '@/atom/controls/Select';
import { ISearchType } from '@/model/Types';
import Input from '@/atom/controls/Input';
import Label from '@/atom/controls/Label';
import Button from '@/atom/controls/Button';

interface IFormFields {
  searchText: string | null;
  searchType: ISearchType;
}
const defaultFormFields: IFormFields = {
  searchText: '',
  searchType: '',
};

const ListAccount = () => {
  const { getUsers } = useUser();
  const { startLoading, stopLoading } = useLoading();
  const [currentPage, setCurrentPage] = useState<number>(-1);
  const [pageSize, setPageSize] = useState<number>(20);
  const [totalRecord, setTotalRecord] = useState<number>();
  const [list, setList] = useState<any[]>([]);
  const preventReload = useRef(false);
  const [formState, setFormState] = useState<IFormFields>({
    ...defaultFormFields,
  });

  const fetchAccount = async () => {
    startLoading();
    const dt = await getUsers({
      search: formState.searchText,
      type: formState.searchType,
      currentPage: currentPage,
      pageSize: pageSize,
    });
    stopLoading();
    setTotalRecord(dt.totalRecord);
    setList(dt.result);
  };

  useEffect(() => {
    if (!preventReload.current) {
      preventReload.current = true;
      setCurrentPage(1);
    }
  }, []);

  useEffect(() => {
    if (currentPage === -1) return;
    fetchAccount();
  }, [currentPage]);

  const handleChangePage = (type: string) => {
    const p = type === 'prev' ? currentPage - 1 : currentPage + 1;
    setCurrentPage(p);
  };

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    const { name, value } = event.target;
    setFormState({
      ...formState,
      [name]: value,
    });
  };



  return (
    <div className='container'>
      <p className='text-lg flex font-bold'>All customers</p>
      <div className='flex gap-2 my-3 !max-w-3xl'>
        <Select
          name='searchType'
          value={formState.searchType}
          onChange={handleInputChange}
        >
          <option value='' hidden>Select search type</option>
          <option value='userId'>account id</option>
          <option value='firstName'>first name</option>
          <option value='lastName'>last name</option>
          <option value='email'>email</option>
          <option value='company'>company</option>
        </Select>
        <Input
          name='searchText'
          placeholder={'Search'}
          value={formState.searchText || ''}
          required={true}
          onChange={handleInputChange}
        ></Input>
        <Button className='submit' onClick={fetchAccount}>Search</Button>
      </div>
      <table className='min-w-full mt-2'>
        <thead>
          <tr className='border-b-2'>
            <th className='text-left pr-4 py-3'>Account Id</th>
            <th className='text-left pr-4 py-3'>First name</th>
            <th className='text-center px-4 py-3'>Last name</th>
            <th className='text-center px-4 py-3'>Email</th>
            <th className='text-center px-4 py-3'>Role</th>
            <th className='text-center px-4 py-3'>Type</th>
            <th className='text-center px-4 py-3'>Company name</th>
            <th className='text-center px-4 py-3'>Created date</th>
          </tr>
        </thead>
        <tbody>
          {list &&
            list.map((item: any, index) => {
              return (
                <tr className='border-b' key={index}>
                  <td className='text-left pr-4 py-3'>
                    {item.squenceId ? item.squenceId.toString().padStart(6, '0') : ''}
                  </td>
                  <td className='text-left pr-4 py-3'>
                    {item.firstName}
                  </td>
                  <td className='text-left px-4 py-3'>
                    {item.lastName}
                  </td>
                  <td className='text-left px-4 py-3'>
                    {item.email}
                  </td>
                  <td className='text-left px-4 py-3'>
                    {item.role}
                  </td>
                  <td className='text-left px-4 py-3'>
                    {item.type}
                  </td>
                  <td className='text-left px-4 py-3'>
                    {item.companyName}
                  </td>
                  <td className='text-center px-4 py-3'>
                    {moment(new Date(item.createdAt._seconds * 1000 + item.createdAt._nanoseconds / 1000000)).format('DD-MMM-YYYY')}
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
      <div className='mt-4'>
        {totalRecord === 0 && <p className='text-center'>No order exists!</p>}
        {totalRecord !== undefined &&
          totalRecord !== null &&
          totalRecord !== 0 && (
            <Pagination
              page={currentPage}
              size={pageSize}
              total={totalRecord}
              handleChangePage={handleChangePage}
            />
          )}
      </div>
    </div>
  );
};

export default ListAccount;
