import useLoading from '@/hook/useLoading';
import useTicketService from '@/hook/useTicketService';
import { SupportDateList, SupportTypeList, TItem } from '@/model/Consts';
import { ITicket } from '@/model/Models';
import { useEffect, useRef, useState } from 'react';
import { useAuthContext } from '../authcheck/AuthCheck';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { ChartBarIcon } from '@heroicons/react/24/solid';
import Select from '@/atom/controls/Select';
import Button from '@/atom/controls/Button';
import Input from '@/atom/controls/Input';
import Pagination from '../order/Pagination';

const periods = [...SupportDateList];
const topics = [...SupportTypeList];

const TicketHistory = () => {
  const { user } = useAuthContext();
  const [list, setList] = useState<ITicket[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(30);
  const [totalRecord, setTotalRecord] = useState<number>(0);
  const [period, setPeriod] = useState<string>();
  const [search, setSearch] = useState<string>();
  const { getTickets } = useTicketService();
  const { startLoading, stopLoading } = useLoading();
  const ref = useRef<Boolean>(false);
  const fetchTickets = async () => {
    startLoading();
    const searchNumber = search
      ? isNaN(parseInt(search))
        ? null
        : parseInt(search)
      : null;
    const res = await getTickets({
      currentPage: currentPage,
      pageSize: pageSize,
      dateRange: period || 'all',
      userSquenceId:
        user?.role === 'Admin' || user?.role === 'SuperAdmin'
          ? searchNumber
          : user?.squenceId || null,
    });
    console.log('res', res);
    setList(res.result);
    setTotalRecord(res.totalRecord);
    stopLoading();
  };
  useEffect(() => {
    if (!ref.current) {
      ref.current = true;
      fetchTickets();
    }
  }, [ref.current]);

  useEffect(() => {
    if (currentPage === -1) return;
    fetchTickets();
  }, [currentPage]);

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = event.target;
    setPeriod(value);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setSearch(value);
  };

  const handleChangePage = (type: string) => {
    const p = type === 'prev' ? currentPage - 1 : currentPage + 1;
    setCurrentPage(p);
  };

  return (
    <div className='container'>
      <p className='text-lg flex font-bold'>All requests</p>
      <div className='flex gap-2 my-3 !max-w-3xl'>
        <Select
          className='!w-[250px]'
          name='searchType'
          value={period || 'all'}
          onChange={handleSelectChange}
        >
          {periods &&
            periods.map((item) => (
              <option key={item.value} value={item.value}>
                {item.name}
              </option>
            ))}
        </Select>
        {(user?.role === 'Admin' || user?.role === 'SuperAdmin') && (
          <div className='flex flex-1 justify-center items-center w-[250px]'>
          <span className='mr-2'>Account Id</span>
          <Input
            className='flex-1'
            name='searchText'
            placeholder={'Account Id'}
            value={search || ''}
            required={true}
            onChange={handleInputChange}
          ></Input>
          </div>
        )}

        <Button className='submit' onClick={fetchTickets}>
          Search
        </Button>
      </div>
      <div className='flex flex-col'>
        <table className='min-w-full md:min-w-[700px] mt-2'>
          <thead>
            <tr className='border-b-2'>
              <th className='text-left pr-4 py-3'>Account Id</th>
              <th className='text-left pr-4 py-3'>Request ID</th>
              <th className='text-center px-4 py-3'>Topic</th>
              <th className='text-left pr-4 py-3'>Status</th>
              <th className='text-center px-4 py-3'>Created Date</th>
              <th className='text-center px-4 py-3'>Created By</th>
              <th className='text-center px-4 py-3'>Contact Email</th>
              <th className='pl-4 py-3'></th>
            </tr>
          </thead>
          <tbody>
            {list &&
              list
                .sort((a, b) => b.squenceId - a.squenceId)
                .map((item) => {
                  return (
                    <tr className='border-b' key={item.id}>
                      <td className='text-left pr-4 py-3'>
                        {item.userSquenceId
                          ? item.userSquenceId.toString().padStart(6, '0')
                          : ''}
                      </td>
                      <td className='text-left pr-4 py-3'>
                        {item.squenceId.toString().padStart(6, '0')}
                      </td>
                      <td className='x-4 py-3'>
                        {topics.filter((it) => it.value === item.topic)[0].name}
                      </td>
                      <td className='text-center pr-4 py-3'>{item.status}</td>
                      <td className='text-center px-4 py-3'>
                        {item.createdAt &&
                          moment(
                            new Date(
                              (item.createdAt as any)._seconds * 1000 +
                                (item.createdAt as any)._nanoseconds / 1000000,
                            ),
                          ).format('DD-MMM-YYYY')}
                      </td>
                      <td className='text-center px-4 py-3'>
                        {item.userFirstName}
                      </td>
                      <td className='text-center px-4 py-3'>
                        {item.userEmail}
                      </td>
                      <td className='text-right pl-4 py-3'>
                        <Link
                          to={`/ticketdetail/${item.id}`}
                          className='flex gap-1'
                        >
                          <ChartBarIcon width={16} /> <span>Detail</span>
                        </Link>
                      </td>
                    </tr>
                  );
                })}
          </tbody>
        </table>
        <div className='min-w-full md:min-w-[700px] mt-2'>
          {totalRecord === 0 && <p className='text-center'>No Request exists!</p>}
          {totalRecord !== undefined &&
            totalRecord !== null &&
            totalRecord !== 0 && (
              <Pagination
                page={currentPage}
                size={pageSize}
                total={totalRecord}
                handleChangePage={handleChangePage}
              />
            )}
        </div>
      </div>
    </div>
  );
};

export default TicketHistory;
