const useValidate = () => {
  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email) ? null : 'Invalid email address';
  };

  const validatePassword = (password) => {
    return password.length >= 6
      ? null
      : 'Password must be at least 6 characters long';
  };

  const validateRequired = (value, msg?) => {
    return value ? null : msg ? msg : 'Please enter the value';
  };

  const validateConfirmPassword = (p1, p2) => {
    return p1 === p2 ? null : 'Password confirm does not same password';
  };

  return {
    validateEmail,
    validatePassword,
    validateRequired,
    validateConfirmPassword,
  };
};
export default useValidate;
