import React, { useState } from 'react';
import cx from 'classnames';
import ss from '../upload/Upload.module.scss';
import ColumnChart from '@/atom/ColumnChart';
import PieChart from '@/atom/PieChart';
import ColumnChartNegative from '@/atom/ColumnChartNegative';
import { ISegment } from '@/model/Types';
import useDataProcess from '@/hook/useDataProcess';
import BarChart from '@/atom/BarChart';
import TreeMapChart from '@/atom/TreeMapChart';
import BubbleChart from '@/atom/BubbleChart';

const OrderDashboard = ({ segments, showFilters, setShowFilters }) => {
  const { sumCategories, getSegment } = useDataProcess();

  return (
    <div style={{ marginTop: '20px' }}>
      <div style={{ display: 'flex', justifyContent: 'space-around', gap: '10px' }}>
        <BarChart
          data={getSegment(segments, 'sentiment')}
          title={'Sensitivity'}
          maxWidth={'450px'}
        />
        <TreeMapChart
          data={getSegment(segments, 'categories')}
          title={'Sensitivity by Topics'}
          showFilters={showFilters}
          setShowFilters={setShowFilters}
        />
        <BarChart
          data={getSegment(segments, 'topics')}
          title={'Feedback by Topics'}
          showFilters={showFilters}
          setShowFilters={setShowFilters}
          maxWidth={'450px'}
        />
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-around', gap: '10px', marginTop: '30px' }}>
        <BubbleChart
          data={getSegment(segments, 'customer appreciate')}
          title={'Appreciation'}
          showFilters={showFilters}
          setShowFilters={setShowFilters}
        />
        <BubbleChart
          data={getSegment(segments, 'customer dissatisfaction')}
          title={'Dissatisfaction'}
          showFilters={showFilters}
          setShowFilters={setShowFilters}
        />
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-around', gap: '20px' , marginTop: '30px' }}>
        <BarChart
          data={getSegment(segments, 'customer behaviors')}
          title={'Behavior'}
          showFilters={showFilters}
          setShowFilters={setShowFilters}
          maxWidth={'450px'}
        />
        <BarChart
          data={getSegment(segments, 'customer expectations')}
          title={'Expectations'}
          showFilters={showFilters}
          setShowFilters={setShowFilters}
          maxWidth={'450px'}
        />
        <BarChart
          data={getSegment(segments, 'customer improvements')}
          title={'Improvements'}
          showFilters={showFilters}
          setShowFilters={setShowFilters}
          maxWidth={'450px'}
        />

      </div>
      <div style={{ display: 'flex', justifyContent: 'start', gap: '20px' , marginTop: '30px'  }}>
        <ColumnChartNegative
          data={getSegment(segments, 'categories')}
          title={'Sentiments By Topics'}
          showFilters={showFilters}
          setShowFilters={setShowFilters}
          maxWidth={'600px'}
        />
      </div>
    </div>
  );
};

export default OrderDashboard;
