import React from 'react';
import cx from 'classnames';

type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement>;
const Button: React.FC<ButtonProps> = ({ children, className, ...props }) => {
  return (
    <button {...props} className={cx('btn', className)}>
      {children}
    </button>
  );
};

export default Button;
