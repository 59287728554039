import {
  ReactNode,
  createContext,
  useState,
  useContext,
  useEffect,
} from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import useLoading from '@/hook/useLoading';
import useUser from '@/hook/useUser';
import { IUser } from '@/model/Models';

interface AuthCheckProps {
  children: ReactNode;
}

type AuthType = 'authenticated' | 'not-authenticated' | null;

interface IAuthContext {
  authState: AuthType;
  setAuthState: React.Dispatch<React.SetStateAction<AuthType>>;
  user: IUser | null;
  setUser: (user: IUser | null) => void;
}

export const AuthContext = createContext<IAuthContext>({
  authState: null,
  setAuthState: () => {},
  user: null,
  setUser: () => {},
});

export const AuthProvider = ({ children }) => {
  const [authState, setAuthState] = useState<AuthType>(null);
  const [user, setUser] = useState<IUser | null>(null);
  const auth = getAuth();
  const { getOrCreate } = useUser();
  const { startLoading, stopLoading } = useLoading();
  console.log('AuthProvider rendered');
  useEffect(() => {
    console.log('auth');
    // ... rest of the code
  }, []);

  useEffect(() => {
    console.log('auth');
    const unsubscribeAuth = onAuthStateChanged(auth, (val) => {
      console.log('val', val);
      if (val) {
        const userObj: IUser = {
          id: val.uid,
          squenceId: 0,
          email: val.email || '',
          firstName: val.displayName || '',
          lastName: '',
          photoUrl: val.photoURL || '',
        };
        let emailVerified = false;
        const provider = val.providerData.filter(
          (v) => v.providerId !== 'password',
        );
        if (provider.length !== 0) {
          emailVerified = true;
        } else {
          emailVerified = val.emailVerified;
        }
        console.log('emailVerified', emailVerified);
        const fetchUser = async () => {
          startLoading();
          const userData = await getOrCreate(userObj);
          if (userData.status) {
            const verifyUser = userData.data;
            verifyUser.emailVerified = emailVerified;
            setUser(verifyUser);
          }
          stopLoading();
        };
        fetchUser();
      } else {
        setUser(null);
        setAuthState('not-authenticated');
      }
    });
    return () => unsubscribeAuth();
  }, [auth]);

  useEffect(() => {
    console.log('user', user);
    if (user && user.emailVerified) {
      setAuthState('authenticated');
    }
  }, [user]);

  useEffect(() => {
    console.log('isAuthenticated', authState);
  }, [authState]);

  return (
    <AuthContext.Provider value={{ authState, setAuthState, user, setUser }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => {
  return useContext(AuthContext);
};

const AuthCheck: React.FC<AuthCheckProps> = ({ children }) => {
  const location = useLocation();
  const { authState } = useContext(AuthContext);

  if (authState === null) {
    return <>Loading...</>;
  } else if (authState === 'not-authenticated') {
    return <Navigate to='/login' state={{ from: location }} />;
  } else {
    return <>{children}</>;
  }
};

export default AuthCheck;
