import react from 'react';

const DeletedRules = () => {
  return (
    <div className='p-10'>
      <div>
        <h3 className='font-bold text-center text-lg'>Customer Radar</h3>
      </div>
      <div>
        <p>Data Deletion</p>

        <p>Last updated: 23-10-2023</p>

        <p>Customer radar operates the AI App (the "Service").</p>

        <p>Data Deletion and Account Termination</p>

        <p>
          1. If you wish to delete your account and all associated data, you can
          do so by contacting us directly at customerradar.app@gmail.com. Please
          include the email address associated with your account in your
          request.
        </p>

        <p>
          2. Upon receiving a data deletion request, we will verify the identity
          of the requester to ensure the security of user data. Once your
          identity has been verified, we will initiate the data deletion
          process.
        </p>

        <p>
          3. Please note that certain data may be retained for a legally
          required period or for the period necessary to fulfill our legitimate
          business purposes.
        </p>

        <p>
          4. We are committed to maintaining the confidentiality, integrity, and
          security of your personal information. We take reasonable steps to
          securely delete or de-identify your personal information so that it
          cannot be recreated or read.
        </p>
      </div>
    </div>
  );
};

export default DeletedRules;
