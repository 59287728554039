import { httpsCallable } from 'firebase/functions';
import { functions } from '../ultils/firebase';
import { ICheckout, IUser } from '@/model/Models';
import { IResponse } from '@/model/Responses';

const useCheckout = () => {
  const getCheckoutOrder = async (
    id: string,
  ): Promise<IResponse<ICheckout>> => {
    const method = httpsCallable<{ id: string }, IResponse<ICheckout>>(
      functions,
      'Checkout_getCheckoutOrder',
    );
    const res = await method({ id: id });
    return res.data;
  };

  const createPaymentIntent = async (
    paymentMethodId: string,
    customerId: string,
    amount: number,
    currency: string,
  ): Promise<IResponse<{ clientSecret: string }>> => {
    const method = httpsCallable<
      {
        paymentMethodId: string;
        customerId: string;
        amount: number;
        currency: string;
      },
      IResponse<{ clientSecret: string }>
    >(functions, 'Checkout_createPaymentIntent');
    const res = await method({
      paymentMethodId: paymentMethodId,
      customerId: customerId,
      amount: amount,
      currency: currency,
    });
    return res.data;
  };

  const getCost = async (tokens: number): Promise<number> => {
    const method = httpsCallable<{ tokens: number }, number>(
      functions,
      'Checkout_getCost',
    );
    const res = await method({ tokens: tokens });
    return res.data;
  };

  const createStripeCustomer = async (
    userId: string,
    userEmail: string,
  ): Promise<IResponse<string>> => {
    const method = httpsCallable<
      { userId: string; userEmail: string },
      IResponse<string>
    >(functions, 'Checkout_createStripeCustomer');
    const res = await method({ userId: userId, userEmail: userEmail });
    return res.data;
  };

  const createCustomerIntent = async (
    customerId: string,
  ): Promise<IResponse<string>> => {
    const method = httpsCallable<{ customerId: string }, IResponse<string>>(
      functions,
      'Checkout_createCustomerIntent',
    );
    const res = await method({ customerId: customerId });
    return res.data;
  };

  const updatePaymentMethod = async (
    userId: string,
    paymentMethodId: string,
  ): Promise<IResponse<IUser>> => {
    const method = httpsCallable<
      { userId: string; paymentMethodId: string },
      IResponse<IUser>
    >(functions, 'Checkout_updatePaymentMethod');
    const res = await method({
      userId: userId,
      paymentMethodId: paymentMethodId,
    });
    return res.data;
  };

  const removePaymentMethod = async (
    userId: string,
    paymentMethodId: string,
  ): Promise<IResponse<IUser>> => {
    const method = httpsCallable<
      { userId: string; paymentMethodId: string },
      IResponse<IUser>
    >(functions, 'Checkout_removePaymentMethod');
    const res = await method({
      userId: userId,
      paymentMethodId: paymentMethodId,
    });
    return res.data;
  };

  const getPaymentMethod = async (
    paymentMethodId: string,
  ): Promise<IResponse<any>> => {
    const method = httpsCallable<{ paymentMethodId: string }, IResponse<any>>(
      functions,
      'Checkout_getPaymentMethod',
    );
    const res = await method({ paymentMethodId: paymentMethodId });
    return res.data;
  };

  return {
    getCheckoutOrder,
    createPaymentIntent,
    getCost,
    createStripeCustomer,
    createCustomerIntent,
    updatePaymentMethod,
    removePaymentMethod,
    getPaymentMethod,
  };
};

export default useCheckout;
