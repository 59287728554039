// loadingReducer.ts
import { SET_LOADING, SET_LOADING_TEXT } from './ActionTypes';
import { SetLoadingAction, SetLoadingTextAction } from './Actions';

interface LoadingState {
  isLoading: boolean;
  loadingText: string;
}

const initialState: LoadingState = {
  isLoading: false,
  loadingText: '',
};

type LoadingActions = SetLoadingAction | SetLoadingTextAction;
const loadingReducer = (
  state = initialState,
  action: LoadingActions,
): LoadingState => {
  switch (action.type) {
    case SET_LOADING:
      return {
        ...state,
        isLoading: action.payload as boolean,
      };
    case SET_LOADING_TEXT:
      return {
        ...state,
        loadingText: action.payload as string,
      };
    default:
      return state;
  }
};

export default loadingReducer;
