import { CreditCardIcon, TrashIcon } from '@heroicons/react/24/solid';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../authcheck/AuthCheck';
import useCheckout from '@/hook/useCheckout';
import { useEffect, useState } from 'react';
import { ICard } from '@/model/Models';
import useLoading from '@/hook/useLoading';
import { setLoading } from '@/redux/Actions';

const Payment = () => {
  const nav = useNavigate();
  const { user, setUser } = useAuthContext();
  const { getPaymentMethod, removePaymentMethod } = useCheckout();
  const { startLoading, stopLoading } = useLoading();
  const [card, setCard] = useState<ICard | null>();

  const fetchPaymentMethod = async () => {
    if (user && user.paymentMethodId) {
      startLoading();
      const res = await getPaymentMethod(user.paymentMethodId);
      stopLoading();
      console.log(res);
      if (res.status) {
        setCard(res.data.card);
      }
    }
  };

  useEffect(() => {
    fetchPaymentMethod();
  }, []);

  const remove = async () => {
    if (user && user.paymentMethodId) {
      startLoading();
      const res = await removePaymentMethod(user.id, user.paymentMethodId);
      stopLoading();
      if (res.status) {
        user.paymentMethodId = null;
        setUser(user);
        setCard(null);
      }
    }
  };

  return (
    <div className='mb-8'>
      <p className='text-lg font-bold'>Payment Preference</p>
      {card && (
        <div className='p-4 my-4 border rounded-lg'>
          <div className='flex gap-4 justify-between'>
            <span className='uppercase font-bold'>{card.brand}</span>
            <span>
              Exp: {card.exp_month} / {card.exp_year}
            </span>
            <span>xxxx xxxx xxxx {card.last4}</span>
          </div>
          <div className='flex mt-2 justify-end'>
            <div className='flex gap-2 cursor-pointer' onClick={remove}>
              <TrashIcon width={16} />
              <span>Remove</span>
            </div>
          </div>
        </div>
      )}
      {user && !user.paymentMethodId && (
        <div
          className='flex items-center mt-4 cursor-pointer'
          onClick={() => nav('/payment')}
        >
          <div className='border-2 border-dashed text-[#9b9b9b] py-3 px-6 mr-3 rounded-lg'>
            <CreditCardIcon width={32} />
          </div>
          <div>
            <span className='font-bold'>Add a payment method</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default Payment;
